// Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import storage from 'framework/src/StorageProvider.web';

// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeButton: string;
  selectedTab: number | string;
  services: any;
  projectTrackingData: any
  projectTrackingErr: string | undefined
  authToken: string
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectTrackingControllerClient extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  projectTrackingId: string = ""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeButton: "offers",
      selectedTab: 0,
      services: [
        "Provide legal advice on intellectual property matters",
        "Assist in filing and managing trademarks, copyrights, and patents",
        "Draft, review, and negotiate IP-related agreements and contracts",
        "Conduct IP audits and provide recommendations for IP protection strategies",
        "Handle IP infringement cases and disputes"
      ],    
      projectTrackingData: [],
      projectTrackingErr: "",
      authToken: ""
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken'),
    })
    this.getProjectTracking()
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      // return;
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleProjectTrackingRes(webResponseJson, webApiRequestCallId)
    }
    // Customizable Area End
  }
  // Customizable Area End
    
  // Customizable Area Start
  handleProjectTrackingRes = (
    webResponseJson: { data?: any[]; error?: string } | undefined, 
    webApiRequestCallId: string | undefined
  ) => {    
    if (webResponseJson && Array.isArray(webResponseJson)) {      
        if (webApiRequestCallId === this.projectTrackingId) {       
          const data = webResponseJson.map((item: any) => item.data?.attributes);      
            this.setState({ projectTrackingData: data , projectTrackingErr:""})
      } 
    } 
  };

  
  getStatusColor = (status: string) => {
    switch (status) {
      case "ongoing":
        return "#364BA0";
      case "completed":
        return "#348306";
      case "pending":
        return "#F8B225"
      case "accept":
        return "#364BA0";
      case "rejected":
        return "red";
      case "approved":
        return "#348306";
      default:
        return "#515354";
    }
  };

  getProjectTracking = () => {
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectTrackingId = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/milestones/client_get_milestones_details`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
// Customizable Area End


