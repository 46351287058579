import React from 'react';

// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  Box,
  FormControl,
  Button,
  Container,
  Divider,
  OutlinedInput,
  InputAdornment,
  Select,
  TextField,
  Radio,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  RadioGroup,
  TextareaAutosize,
  IconButton,
  Paper,
  styled
} from '@material-ui/core';
import EditSubmitProposalController, {
  Props
} from './EditSubmitProposalController.web';
export const configJSON = require('./config');
import { image_Success, uploadDoc } from '../../email-account-registration/src/assets';
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from "@material-ui/icons/Close";
import { experienceicon, pdfImage, priceLatestTag } from './Components/assets';

// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class EditSubmitProposal extends EditSubmitProposalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  
  renderSuccessModal = () =>{
    return(
      <>
         <CustomDialogBox
      open={this.state.isDialogOpen}
      onClose={this.handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
       
      <DialogContent style={{
         textAlign: 'center',padding:"24px" }}>
          <img src={image_Success} alt="Success" style={{ width: "35%",
           borderRadius: "50%",marginTop:"20px" ,marginBottom:"10px"}} />
          <Typography gutterBottom style={webStyle.congratsText}>
              Congratulations!  </Typography>
          <Typography style={webStyle.profileCreatedText}>
          Your proposal has been submitted successfully!</Typography>
      </DialogContent>
  </CustomDialogBox>
      </>
    )
  }

  renderDescriptionContent = () => {
    const { classes } = this.props;
    const { jobDetailsData, showFullContent } = this.state;
    const description = jobDetailsData?.description || '';
  
    if (!description) {
      return <div>No job description available</div>;
    }
  
    const words = description.split(' ');
    const isLongContent = words.length > 80;
  
    let displayedContent;
    if (showFullContent) {
      displayedContent = description;
    } else {
      displayedContent = words.slice(0, 80).join(' ') + (isLongContent ? '...' : '');
    }
  
    return (
      <div>
        <Typography className={classes.projectDesc}>
          {displayedContent}
          {isLongContent && (
            <span
              className={classes.moreToggle}
              onClick={this.toggleContent}
            >
              {' '}
              {showFullContent ? 'show less' : '...more'}
            </span>
          )}
        </Typography>
  
        {isLongContent && (
          <Typography
            className={classes.viewJobPost}
            onClick={this.handleJobPost}
          >
            View job posting
          </Typography>
        )}
      </div>
    );
  };

 
  // Customizable Area End
  render() {
    // Customizable Area Start
    const { classes } = this.props;

    const getAttributes = (source: any, path: string): any => {
      return source?.proposal?.data?.attributes?.[path]?.data?.attributes || {};
    };
    
    const jobDetailsDatas = this.state.jobDetailsData?.post?.data?.attributes || {};
    const archivedProposals = this.state.archivedList?.proposal?.data?.attributes || {};
    
    const activePostData = getAttributes(this.state.activeProposalData, 'post_attributes');
    const archivePostData = getAttributes(this.state.archivedList, 'post_attributes');
    
    // Determine mappingData with priority
    const mappingData = this.state.archivedList?.proposal
      ? archivePostData
      : this.state.activeProposalData?.proposal
      ? activePostData
      : jobDetailsDatas;
    
    // Customizable Area End
    return (
      // Customizable Area Start

      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Container style={{ marginTop: '2rem' }}>
        <Box>
          <Typography
                style={{
                  marginTop: '72px',
                  fontSize: '36px',
                  fontWeight: 700,
                  fontFamily: 'Rubik',
                  color:'#4A4A4A',
                  marginBottom: '33px',
                }}
              >Submit a Proposal</Typography>
          </Box>
          <Box component={'div'} className={classes.boxStyle}>
            <Box 
            className={classes.mainCenterBox}> 
            <Box 
            className={classes.boxRight}>
            <Typography 
            style={{fontSize:'24px',
            color: '#303030',  
            fontFamily: 'Rubik', 
            marginTop: '0px'
            }} 
            className={classes.headerBold}>
              Job Details
              </Typography>
            <Typography 
            className={classes.text}
            >
            {mappingData?.name}
           </Typography>
              <Box 
              style={{display: 'flex', 
              gap:'12px'
              }}>
                <Typography 
                style={{
                  backgroundColor: '#E6EBEF',
                 fontSize: '16px',
                }} 
                className={classes.php}>
                    {mappingData?.job_title}</Typography>
                <Typography 
                className={classes.postTime}
                >
                {mappingData?.created_at} </Typography>
                  </Box> {this.renderDescriptionContent()}
            </Box>

            <Box 
            className={classes.boxLeft}><Box className={classes.experienceBox}>
                    <Box 
                    display={'flex'} 
                    style={{gap:'10px'}}>
                  <img 
                  src={experienceicon} 
                  alt="img" 
                  width={25} 
                  height={25}
                  /> 
                  <Typography 
                  style={{marginTop:'0px',
                  color:'#364BA0'
                  }} 
                  className={classes.text}>{mappingData?.experience_level}</Typography>
                </Box><Typography className={classes.text2}>Experience Level</Typography>
                  </Box><Box className={classes.experienceBox}>
                    <Box 
                    display={'flex'} 
                    style={{gap:'10px'}}>
                  <img 
                  src={priceLatestTag} 
                  alt="img" 
                  width={25} 
                  height={25}/> 
                  <Typography 
                  style={{marginTop:'0px',
                  color:'#364BA0'
                  }} 
                  className={classes.text}>
                  ${mappingData?.per_day_charge}</Typography>
                  </Box><Typography 
                  className={classes.text2} 
                  style={{marginTop: '0px',
                  marginLeft: '35px'
                  }}>
                   Project Budget </Typography></Box></Box></Box>
          <Divider 
          className={classes.dividerStyle} 
          style={{margin: '32px 0px 32px'}}
          />
          <Typography 
          style={{fontSize:'24px'}} 
          className={classes.text}>Skills and expertise:</Typography>
          <Box 
          style={{ display: 'flex' }}>
          {mappingData?.skills?.map((skill: any,index:number)=>{
          return (<Typography style={{
            marginTop: '18px'
            }} 
            className={classes.php}
            key={index}>
            {skill?.name}
            </Typography>
            )})}</Box>
          </Box>

          {this.state.activeProposalData?.proposal 
          &&
           <Box component={'div'} 
           style={{padding:'0px'}} 
           className={classes.boxStyle}>
            <Typography 
            style={{fontSize:'24px',margin:'32px 32px 0px 32px'}}
             className={classes.text}>
              Terms
              </Typography>
            <Divider style={{color: '#858585',margin: '24.5px 0px 32.5px'}} 
            className={classes.dividerStyle} />
       
        <Box 
        style={{padding:'0px 32px 32px'}}
        >
            <Typography className={classes.text}> How do you want to be paid?  </Typography>
            <Grid container spacing={2} className={classes.gridPosition}>
             <Grid item md={8} xs={12}>
              <Typography className={classes.headerBold}>
              {archivedProposals?.payment_type}
               </Typography>
               <p style={{fontSize: '16px'}} 
               className={classes.subHeading}
               >
               Get your entire payment at the end, when all work has been delivered.</p> 
               </Grid>
               </Grid>
          <Box style={{marginTop:'24px'}}><Grid 
          container 
          spacing={2} 
          className={classes.gridPosition}>
              <Grid item 
              md={8} 
              xs={12}>
                <Typography 
                className={classes.headerBold}>
                Bid:
                <span 
                style={{ color: "red" }}>*</span>
                </Typography>
                <p 
                className={classes.subHeading}
                >
                Total amount the client will see on your proposal
                </p>
              </Grid><Grid item md={4} xs={12}>
                <OutlinedInput fullWidth
                  data-testid="project-btn"
                  value={this.state.projectBudget}
                  onChange={this.handleProjectBudget}
                  name="total_price_of_project"
                  inputProps = {{
                    style :{
                      fontWeight: 700,
                      color: '#363636',
                      fontSize: '16px',
                      textAlign: 'end',
                      fontFamily: 'Rubik',
                    }}}
                  startAdornment={
                    <InputAdornment
                      position="start">
                      $</InputAdornment>}
                  endAdornment={<InputAdornment
                      position = "end"
                      className = {classes.endtext}>
                      /project
                    </InputAdornment>
                  }/>{this.state.errors.bid && (
                    <Typography
                    style={{ color: 
                    'red', 
                    fontSize: '14px' }
                    }>
                      {this.state.errors.bid} </Typography>)}
              </Grid></Grid> <Grid container 
              spacing={2} 
              className={classes.gridPosition}>
              <Grid item 
              md={8} xs={12}>
                <Typography 
                className={classes.headerBold}
                >
                Inventohub Service Fee :
                </Typography>
                <p 
                className={classes.subHeading}>Inventohub will charge a small service fee from the total amount
                </p>
              </Grid><Grid item md={4} xs={12}>
                <OutlinedInput
                  value={activePostData.freelancer_service_fee}
                  name="savioServiceFee"
                  disabled
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  onChange={this.handleChange}
                  fullWidth
                  inputProps={{
                    style: {
                      color:'#767373',
                      fontFamily: 'Rubik',
                      fontWeight: 700,
                      textAlign: 'end',
                    }}}/>
              </Grid> </Grid><Grid
               container 
               spacing={2} 
               className={classes.gridPosition}>
              <Grid item md={8} xs={12}>
                <Typography className={classes.headerBold}> You'll Receive:</Typography>
                <p className={classes.subHeading}
                >
                The estimated amount you'll receive after service fees</p>
                <p 
                style={{fontSize:'12px',marginBottom:'6px'}} 
                className={classes.subHeading}
                >*Payment gateway and other fees will be deducted from your earnings</p>
              </Grid><Grid item md={4} xs={12}>
                <OutlinedInput
                  value={this.state.youReceive}
                  name="youReceive"
                  disabled
                  onChange={this.handleChange}
                  fullWidth
                  inputProps={{
                    style: {
                      color: '#363636',
                      fontSize: '16px',
                      textAlign: 'end',
                      fontFamily: 'Rubik',
                      fontWeight: 600
                    },placeholder:'0.00'
                  }} startAdornment={<InputAdornment position="start">$</InputAdornment>}
                /></Grid></Grid> </Box>
          <Typography 
          className={classes.flagStyle}>
            We do not deduct any tax. You need to pay taxes according to your country.
            </Typography>
          </Box></Box>}
          {this.state.archivedList?.proposal 
          && <
            Box 
            component={'div'} 
            style={{padding:'0px'}} className={classes.boxStyle}> <Typography 
            style={{
              fontSize:'24px',
              margin:'32px 32px 0px 32px'
            }}
             className={classes.text}>
              Terms
              </Typography>
            <Divider 
            style={{color: '#858585',margin: '24.5px 0px 32.5px'}}
             className={classes.dividerStyle} />
       
        <Box 
        style={{padding:'0px 32px 32px'}}>
            <Typography 
            className={classes.text}>
            How do you want to be paid?</Typography><Box style={{marginTop:'24px'}}>
            <Grid 
            container 
            spacing={2}
            className={classes.gridPosition}>
              <Grid 
              item 
              md={8} xs={12}>
                <Typography 
                className={classes.headerBold}> Bid:<span style={{ color: "red" }}>*</span>
                </Typography><p className={classes.subHeading}>
                Total amount the client will see on your proposal </p>
              </Grid>
              <Grid item md={4} xs={12}>
                <OutlinedInput  fullWidth
                  value={this.state.projectBudget}
                  data-testid="project-btn"
                  name="total_price_of_project"
                  inputProps={{
                    style: {
                      color: '#363636',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                      textAlign: 'end',
                      fontWeight: 700
                    }
                  }}
                  onChange={this.handleProjectBudget}
                  startAdornment={
                    <InputAdornment
                      position="start"
                   
                    >
                      $
                    </InputAdornment>
                  }endAdornment={<InputAdornment
                      position="end"
                      className={classes.endtext}
                    >/project
                    </InputAdornment>
                  }/> {this.state.errors.bid && (<Typography style={{ color: 'red', fontSize: '14px' }}>
                      {this.state.errors.bid}
                    </Typography>)}
              </Grid></Grid><Grid 
              container 
              spacing={2}
              className={classes.gridPosition}>
              <Grid item md={8} xs={12}>
                <Typography className={classes.headerBold}> Inventohub Service Fee:
                </Typography>
                <p className={classes.subHeading}> Inventohub will charge a small service fee from the total amount
                </p>
              </Grid><Grid item md={4} xs={12}>  <OutlinedInput
                  fullWidth
                  disabled
                  onChange={this.handleChange}
                  value={archivedProposals?.proposal_attributes?.freelancer_service_fee}
                  name="savioServiceFee"
                  startAdornment={
                    <InputAdornment position="start">%</InputAdornment>
                  }
                  inputProps={{
                    style: {
                      textAlign: 'end',
                      color:'#767373',
                      fontFamily: 'Rubik',
                      fontWeight: 700
                    }
                  }}
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.gridPosition}>
              <Grid item md={8} xs={12}>
                <Typography className={classes.headerBold}> You'll Receive:</Typography>
                <p className={classes.subHeading}>
                The estimated amount you'll receive after service fees
                </p>
                <p style={{fontSize:'12px',marginBottom:'6px'}} className={classes.subHeading}>
                *Payment gateway and other fees will be deducted from your earnings
                </p>
              </Grid>
              <Grid item md={4} xs={12}>
                <OutlinedInput
                  fullWidth
                  value={this.state.youReceive}
                  disabled
                  name="youReceive"
                  onChange={this.handleChange}
                  inputProps={{
                    style: {
                      textAlign: 'end',
                      color: '#363636',
                      fontSize: '16px',
                      fontFamily: 'Rubik',
                      fontWeight: 600
                    },
                    placeholder:'0.00'
                  }}
                  startAdornment={
                    <InputAdornment position="start">$</InputAdornment>
                  }
                />
              </Grid>
            </Grid>
          </Box>
          <Typography className={classes.flagStyle}>We do not deduct any tax. You need to pay taxes according to your country.</Typography>
          </Box>
          </Box>
          }
          <Box component={'div'} className={classes.boxStyle}>
            <Typography style={{marginTop:'0px'}} className={classes.headerBold}>
              Project Duration
            </Typography>
            <FormControl variant="outlined" className={classes.customFormControl}>
              <Select
              className={classes.customSelect}
                native
                value={this.state.months}
                id="selectmenu"
                onChange={e => this.handleMonths(e)}
              >
                <option value="" disabled>
                  Select a duration
                </option>
                <option 
                value={'1 to 3 months'}
                >&lt;1 month
                </option>
                <option 
                value={'1 to 3 months'}
                >1-3 months
                </option>
                <option 
                value={'3 to 6 months'}>
                  3-6 months</option>
                <option 
                value={'6 to 12 months'}>
                  6-12 months</option>
                <option 
                value={'12 months'}>
                  12+ months
                  </option>
              </Select>
            </FormControl>
          </Box>
          {this.state.archivedList?.proposal &&
          <Box 
          component={'div'}
           style={{
            padding:'0px'}} 
            className={classes.boxStyle}
            >
          <Typography 
          style={{
            fontSize:'24px',
            margin:'32px 32px 0px 32px'
            }} className={classes.text}>
              Describe your recent work 
              </Typography>
          <Divider 
          style={{
            color: '#858585',
          margin: '23.5px 0px 32.5px',
          }} className={classes.dividerStyle}
           />
          <Box 
          style={{
            padding:'0px 32px 32px'
            }}>
          <Typography 
          style={{
            fontSize:'19px',
            marginBottom:'24px'
            }} 
            className={classes.text}
            >
          Cover Letter
          <span style={{ color: "red" }}>
            *
            </span> </Typography><Box sx={{
              maxWidth: 'auto',
          position: "relative",
          width: "100%",
        }} >
        <TextField multiline rows={10}
          fullWidth
          value={this.state.text}
          variant="outlined"
          className={classes.covertextfield}
          onChange={this.handleTextChange}
          inputProps={{ maxLength: this.maxLength }}/>
        <Typography variant="body2" style={{ position: "absolute",
            color: 'black',
            bottom: 8,
            right: 16,}}>
          {`${this.state.text.length}/${this.maxLength}`}</Typography>
      </Box> {this.state.errors.coverLetter  &&  (
  <Typography 
  style={{
    fontSize:'14px',
    color: 'red',
    marginBottom: '24px',
}}
>{this.state.errors.coverLetter}</Typography>
)}<Typography style={{color: '#303030',marginBottom: '25px'}} 
className={classes.headerBold}>
              Attachments
              <span
              style={{ color: "red" }}>*</span> </Typography>
            <Box display={'flex'} style={{
              alignItems: 'center',
                gap:'20px',
            }}>
                      {this.state.files?.resume_documents.map((file: any, index: number) => {
              return (
                <>
                <Box
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  border: "1px solid #F5EFEF",
                  borderRadius: "16px",
                  padding: "8px 16px",
                  marginBottom: "8px",
                  backgroundColor: "#F9F9F9",
                  width:'185px',
                  height:'170px',
                  textAlign: 'center'
                }}
              >
                <Box style={{margin: 'auto'}}>
                   <img
              alt="pdf"
              style={{
                width: "29px", 
                height: "29px",
                display:'flex',
                margin:'auto',
                left:23
              }}
              src={pdfImage}
            />
                <Typography variant="body2" 
                style={{width: '130px',
                  color: '#535353',
                  fontSize: '14px',
                  fontFamily: 'Rubik',
                  wordBreak: 'break-word',
                }}>
                  {file?.name}
                  </Typography>
                </Box>
              </Box>
        </>)
  })}
                <Box
        style={{
          padding: "4px",
          textAlign: "center",
          width: '40px',
          border: "1px solid #364BA0",
          height: '40px',
          backgroundColor: "#F7F9FA",
          borderRadius: "50%",}}
      ><div className = "imgCenter"
          style = {{
            cursor: "pointer", 
            justifyContent: "center",
            display : "flex",
            alignItems: "center",}}>
          <img src={uploadDoc} alt="upload"  onClick={this.handleImageClick} 
            style={{ width: "29px", height: "29px",
            }}/>
          <input id="file-upload-input" type="file"
            onChange={this.handleFileChange}
            accept=".doc,.docx,.pdf"
            multiple
            hidden /> </div></Box></Box>
            </Box></Box>}
        {this.state.activeProposalData?.proposal
         &&
          <Box component={'div'} style={{padding:'0px'}} className={classes.boxStyle}>
          <Typography style={{fontSize:'24px',margin:'32px 32px 0px 32px'}} className={classes.text}>Describe your recent work </Typography>
          <Divider style={{color: '#858585',margin: '23.5px 0px 32.5px'}} className={classes.dividerStyle} />
          <Box style={{padding:'0px 32px 32px'}}>
          <Typography style={{fontSize:'19px',marginBottom:'24px'}} className={classes.text}>
          Cover Letter<span style={{ color: "red" }}>*</span>
           </Typography>
           <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: 'auto',
        }}
      >
        <TextField
          multiline
          rows={10}
          variant="outlined"
          fullWidth
          value={this.state.text}
          onChange={this.handleTextChange}
          inputProps={{ maxLength: this.maxLength }}
          className={classes.covertextfield}
        />
        <Typography
          variant="body2"
          style={{
            position: "absolute",
            bottom: 8,
            right: 16,
            color: 'black'
          }}
        >
          {`${this.state.text.length}/${this.maxLength}`}
        </Typography>
      </Box>
      {this.state.errors.coverLetter && (
  <Typography style={{ color: 'red',
    marginBottom: '24px',
    fontSize:'14px'
  }}>
    {this.state.errors.coverLetter}
  </Typography>
)}
      <Typography style={{color: '#303030',marginBottom: '25px'}} className={classes.headerBold}>
              Attachments<span style={{ color: "red" }}>*</span>
            </Typography>
            <Box display={'flex'} style={{
                gap:'20px',
                alignItems: 'center',
                flexWrap: 'wrap'
            }}>
                {this.state.files?.length>0 &&
                this.state.files.map((itm: { name: string }, index: number)=>{
                  return(
                    <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      border: "1px solid #F5EFEF",
                      borderRadius: "4px",
                      padding: "8px 16px",
                      marginBottom: "8px",
                      backgroundColor: "#F9F9F9",
                      width:'min-content',
                      height:'170px',
                      textAlign: 'center'
                    }}
                  >
                    <Box style={{padding:'24px'}}>
                       <img
                  src={pdfImage}
                  alt="pdf"
                  style={{
                    width: "29px", 
                    height: "29px",
                    display:'flex',
                    margin:'auto',
                    left:23
                  }}
                />
                    <Typography variant="body2">{itm?.name}</Typography>
                    </Box>
                    <IconButton
                      size="small"
                      style={{
                      top:'-55%',
                      background: 'red',
                      color: 'white',
                      fontWeight: 600,
                      height: '25px',
                      width: '25px',
                      left: '25px'
                      }}
                      onClick={() => this.handleRemoveFile(index)}
                    >
                      <CloseIcon />
                    </IconButton>
                  </Box>
                  )
                })
                }
             <Box
        style={{
          border: "1px solid #364BA0",
          borderRadius: "50%",
          padding: "4px",
          textAlign: "center",
          width: '40px',
          height: '40px',
          backgroundColor: "#F7F9FA",
        }}
      >
        <div
          className="imgCenter"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer", 
          }}
        >
          <img
            src={uploadDoc}
            alt="upload"
            onClick={this.handleImageClick} 
            style={{
              width: "29px", 
              height: "29px",
            }}
          />
          <input
            id="file-upload-input"
            type="file"
            multiple
            accept=".doc,.docx,.pdf"
            hidden
            onChange={this.handleFileChange}
          />
        </div>
      </Box>
        </Box>
            </Box>
          </Box>
  }
          <Box component={'div'} sx={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
             
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  data-testid="proposal-cancel-btn"
                  className={classes.btnStyle2}
                  onClick={() => this.handleClickOpen()}
                >
                  CANCEL
                </Button>
                <Dialog
                  aria-labelledby="customized-dialog-title"
                  open={this.state.open}
                  PaperComponent={props => (
                    <Paper
                      {...props}
                      style={{
                        borderRadius: '15px',
                        maxWidth: '650px',
                        maxHeight: '650px'
                      }}
                    />
                  )}
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    style={{
                      paddingLeft: '16px',
                      paddingTop: '20px',
                      paddingRight: '10px'
                    }}
                  >
                    <Typography style={webStyle.dialogtext}>
                      Withdraw Proposal
                    </Typography>

                    <IconButton
                      onClick={() => this.handleClose()}
                      data-testid="dailog-close-btn"
                    >
                      <CancelIcon
                        style={{ fill: '#2378D1', fontSize: '1.9rem' }}
                      />
                    </IconButton>
                  </Box>

                  <DialogContent style={{overflowX:"hidden"}}>
                    <Typography gutterBottom>
                      We will politely notify the client that you are not
                      interested. The client will be able to view the reason
                      you've withdrawn your proposal.
                    </Typography>
                    <Typography
                      gutterBottom
                      style={{
                        ...webStyle.dialogtext,
                        fontSize: '22px',
                        fontWeight: 600
                      }}
                    >
                      Reason
                    </Typography>
                    <RadioGroup
                      style={webStyle.RadioGroupBox}
                      aria-label="reason"
                      name="reason"
                      value={this.state.reason}
                      onChange={this.handleChange} data-testid="reason">
                      <FormControlLabel value="Applied by mistake" control={<Radio style={{ color: '#2378D1' }} />} label="Applied by mistake"
                      />
                      <FormControlLabel value="Rate too low"
                        control={<Radio style={{ color: '#2378D1' }} />} label="Rate too low"
                      />
                      <FormControlLabel value="Scheduling confilct with client" control={<Radio style={{ color: '#2378D1' }} />}
                        label="Scheduling confilct with client"
                      />
                      <FormControlLabel value="Unresponsive client" control={<Radio style={{ color: '#2378D1' }} />}
                        label="Unresponsive client"
                      /><FormControlLabel value="Inappropriate client behavior" control={<Radio style={{ color: '#2378D1' }} />}
                        label ="Inappropriate client behavior"/>
                      <FormControlLabel value="Other" control={<Radio style={{ color: '#2378D1' }} />}
                        label ="Other"/>
                    </RadioGroup>
                    <Typography style={{...webStyle.dialogtext, fontSize: '22px',
                        fontWeight: 600
                      }}>
                      Message </Typography>
                    <Typography>
                      Add an optional message to share with the client when we notify them that this proposal has been withdrawn.
                    </Typography> <TextareaAutosize
                      minRows={10} name="message"
                      style={{
                        width: '550px',
                        fontFamily: 'Inter',
                        paddingLeft: '20px',
                        paddingTop: '20px',
                        borderRadius: '10px',
                        height: '130px',
                        borderColor: '#979797',
                      }}
                    /> <Box 
                    display={'flex'} 
                    alignItems={'center'}
                    ><Checkbox color="primary" inputProps={{ 'aria-label': 'secondary checkbox' }}
                      />
                      <Typography>
                        Block future invitations from this client
                      </Typography>
                    </Box>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      autoFocus
                      data-testid="dailog-cancel-btn"
                      onClick={() => this.handleClose()}
                      style={{
                        backgroundColor: '#E9F2F9',
                        width: '140px',
                        marginRight: '20px',
                        color: '#206FC4'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#206FC4' }}
                      color="primary"
                      autoFocus
                      data-testid="dailog-withdraw-proposal-btn"
                      onClick={this.handleWithdrawProposals}
                    >
                      withdraw proposals
                    </Button>
                  </DialogActions>
                </Dialog>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  data-testid="proposalSubmit-btn"
                  color="primary"
                  className={classes.btnStyle}
                  onClick={() => {
                    this.handleSubmit();
                    
                  }}
                >
                  SUBMIT PROPOSAL
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <FooterWeb />
        {this.state.isDialogOpen && this.renderSuccessModal()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  placeholderText: {
    '&::placeholder': {
      color: '#000000',
    }
  },
  congratsText:
  {
    marginTop:"10px",
    fontWeight: 600,
    fontFamily: "Rubik",
    fontSize: "22px",
  color: "#059669",
  },
  profileCreatedText:{
   marginBottom: '20px',
   marginTop: "18px",
   fontFamily: "Rubik",
fontSize: "14px",
fontWeight: 500,
},
profileCreatedText_remind : {
  marginTop: "18px",
  fontSize: "14px",
  fontFamily: "Rubik",
  marginBottom: '-7px',
  fontWeight: 500,
} ,
    textField :{
      borderRadius:"12px", width: "375px", marginTop:'12px',
      "&.MuiFormLabel-root.Mui-focused " : {
        color: "#6F6E6E !important" ,
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline " : {
        borderColor : "#6F6E6E !important" ,
        borderRadius : "12px !important"
      },
      "& .MuiOutlinedInput-root" : {
        borderRadius: "12px !important"
      }},
  boxStyle:{
    padding : '32px',
    marginBottom : '32px',
    border : '1px solid #CDCDCD' ,
    borderRadius : '16px' ,
  },
  flagStyle: {
    background: "#FEF3C7",
    fontFamily: "Rubik",
    borderLeft: "4px solid #D97706",
    color: "#D97706",
    fontWeight: 500,
    padding: "10px",
    width: "443px",
    marginTop: "24px",
    borderRadius: "4px",
    fontSize: "12px",
    "@media(max-width:500px)" : {
      width: "auto"
    } ,
  } ,
  box1Style : {
    border: '1px solid #e6e6e6' ,
    marginTop: '20px' ,
    borderRadius: '6px 6px 0 0' ,
    padding:'10px'
  } ,
  text : {
    fontFamily: 'Rubik',
    fontWeight: 600 ,
    marginTop: '25px',
    color: '#303030',
    fontSize: '20px' ,
  } ,
  headerBold : {
    fontSize: '18px',
    marginTop: '24px' ,
    fontFamily: 'Rubik',
    fontWeight: 600,
  } ,
  customFormControl : {
    fontFamily : 'Rubik'
  } ,
  customSelect :  {
    fontFamily: 'Rubik' ,
    fontSize:'14px' ,
    color:'#4F4F4F' ,
    borderRadius: '8px' ,
    padding: '8px' ,
    height: '49px' ,
    width: '232px' ,
    marginTop: '16px' ,
  } ,
  subHeading : {
    color: '#595959' ,
    fontFamily: 'Rubik' ,
    fontSize: '14px' ,
    marginTop :'5px'
  } ,
  radioLabel : {
    color : 'black' ,
    fontFamily : 'Rubik' ,
    fontSize : '18px' ,
    "& .MuiTypography-root" : {
      fontWeight : 700,
      fontFamily : 'Rubik',
      fontSize : '18px' ,
    }},
  gridPosition :{
    display: 'flex' ,
    justifyContent : 'space-between' ,
    alignItems : 'center'
  } ,
  milestoneMainBox :  {
    display : 'flex', 
    gap: '24px', 
    width: '100%',
    marginTop:'40px',
    "@media(max-width:700px)" :{
      display: 'block'
    },},
  milestoneBox : {
   width:'55%' ,
   "@media(max-width:700px)" : {
     marginTop: '10px',
     width: '100%',
  }
  } ,
  dateBox : {
    width : '25%',
    "@media(max-width:700px)" : {
      marginTop: '10px',
      width: '100%',
    },},  
  amountBox : {
        width:'20%' ,
        "@media(max-width:700px)" :{
          marginTop: '10px',
          width: '100%' ,
        } ,
          } ,
  dividerStyle : {
    color : 'black',
  } ,
  uploadBtn : {
    padding: '30px',
    marginTop: '12px',
    borderRadius: '16px',
    border: '1px dashed',
    height: '150px',
  } ,
  imgCenter :{
    display :'flex'  ,
    marginBottom:'16px',
    justifyContent : 'center' ,
  } ,
  uploadTxt :{
    textTransform :'capitalize' as 'capitalize',
  } ,
  mainCenterBox:{
    display : "flex" , 
    width : '100%' ,
    "@media(max-width:700px)" : {
      display: 'block',
    },},
  boxRight : {
   width : '70%' ,
   "@media(max-width:1100px)" :{
    width :"65%"
  } ,
  "@media(max-width:1000px)" : {
    width: "60%"
  },
  "@media(max-width:900px)" : {
    width: "55%"
  }  ,
  "@media(max-width:800px)" : {
      width: "50%"
    } ,
    "@media(max-width:700px)" : {
      width: "100%"
    },},
  boxLeft : {
    width : '30%',
    "@media(max-width:1100px)" :{
      width: "35%"
    } ,
    "@media(max-width:1000px)" :{
      width: "40%" ,
    },
    "@media(max-width:900px)": {
      width: "45%",
    },
    "@media(max-width:800px)": {
      width: "50%",
    },
    "@media(max-width:700px)": {
      width: "100%" ,
    },},
   experienceBox : {
     margin: '20px',
    background: '#E6EBEF' ,
    padding: '20 20px 20px 16px' ,
    borderRadius: '16px' ,
   } ,
   text2 : {
     borderRadius: '16px',
     marginLeft: '35px',color:'#0A0A0A',
     fontFamily:"Rubik",
     marginTop: '0px' ,
   } ,
  lastBoxStyle :{
    padding : '10px',
    border :'1px solid #e6e6e6',
    borderRadius :'0 0 6px 6px',
  } ,
  btnStyle :{
    padding : '10px 30px' ,
    fontWeight : 600 ,
    border : '1px solid #364BA0',
    borderRadius : '8px' ,
    backgroundColor : '#364BA0',
    fontSize : '18px',
    fontFamily :"Rubik",
    '&:hover' :{
      backgroundColor :'#364BA0',
    },
  } ,
  btnStyle2 :{
    borderRadius: '8px',
    fontFamily:"Rubik",
    border: '1px solid #364BA0',
    color: '#364BA0',
    fontWeight: 600,
    fontSize: '18px',
    padding: '10px 80px 10px 80px',
    '&:hover' :{
      border: '1px solid #364BA0',
    },
  } ,
  dialogtext : {
    fontWeight: 700,
    color: '#2B2B2B' ,
    fontFamily: 'Inter'  ,
    fontSize: '36px' ,
  } ,
  dialogBox : {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'space-between',
  } ,
  modal : {
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
  } ,
  textprg : {
    fontFamily : 'Inter' ,
    marginTop : '15px' ,
    fontSize : '20px' ,
    fontWeight : 400 ,
    color : '#2B2B2B' ,
  }  ,
  reason : {
    marginTop: '10px',
    fontFamily: 'Inter' ,
    fontSize: '22px' ,
    color: '#2B2B2B' ,
    fontWeight: 600 ,
  } ,
  textprg2: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    marginTop: '10px'
  },
  favouriteBtn : {
    borderRadius: '8px',
    border: '1px solid #2676D8',
    padding: '12px 50px 12px 50px',
    color: '#2676D8',
    fontWeight: 600,
    '&:hover': {
      border :'1px solid #2676D8',
    }},
  applyBtn :{
    marginLeft: '10px',
    borderRadius: '8px',
    backgroundColor: '#2676D8',
    padding: '12px',
    '&:hover': {
      backgroundColor: '#2676D8',
    }, 
  } ,
  btngroup: {
    justifyContent: 'flex-end',
    display: 'flex',
    alignItems: 'center',
  },

  php: {
    background: ' #E4F5FF',
    borderRadius: '30px',
    width: 'fit-content',
    padding: '5px 25px 5px 25px',
    color: '#364BA0',
    fontWeight: 600,
    fontFamily: 'Rubik',
    marginTop: '12px',
    marginRight: '5px',
    fontSize: '14px'
  },
  postTime: {
    color: '706F6F',
    fontSize: '14px',
    fontFamily: 'Rubik',
    marginTop: '12px',
    alignContent: 'center'
  },
  covertextfield: {
    ".MuiOutlinedInput-root": {
      borderRadius: '10px'
    }
  },
  addStyle: {
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 500,
    color: "#364BA0"
  },
  blueButton:{
    backgroundColor: '#364BA0',
    height:"40px",
    borderRadius:"10px",
    borderColor: '#364BA0',
    padding:"22px",
    color: '#fff',
    fontSize:"18px",
    fontFamily:"Rubik",
    marginBottom: '10px',
    fontWeight:500,
  },
  transButton: {
    fontFamily:"Rubik",
  borderColor: '#4A4A4A',
  padding:"22px",
  fontSize:"18px",
  height:"40px",
  fontWeight:500,
  color: '#4A4A4A',
  borderRadius:"10px" 
  },
  endpercent:{
    color: 'rgb(54, 54, 54)',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 700,
   '& .MuiTypography-colorTextSecondary' :{
    color: 'rgb(54, 54, 54)',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 700,
    },
    '& .MuiTypography-root': {
      margin: 0,
      color: 'rgb(54, 54, 54)'

    }
  },
  endtext:{
    color: 'rgb(54, 54, 54)',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 700,
   '& .MuiTypography-colorTextSecondary' :{
    color: 'rgb(54, 54, 54)',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 700,
    },
    '& .MuiTypography-root': {
      margin: 0,
      color: 'rgb(54, 54, 54)'

    }},
  RadioGroupBox: {
    marginLeft: '10px',
    display: 'grid',
    gridColumnGap: '30px',
    width: '100%',
  },
  projectDesc: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Regular,Rubik',
    marginTop: '12px',
    marginBottom: '22px',
    color: '#0A0A0A',
    marginRight: '25px'
  },
  radiobtn:{
    color: "#979797", 
    "&.Mui-checked": {
      color: "#364BA0", 
    },
  }
};
const CustomDialogBox = styled(Dialog)({
  '& .MuiPaper-rounded':{
    width:"480px",
    borderRadius: "18px",
  }
})
const InputField = styled(FormControl)({
  "&  .MuiOutlinedInput-input" :{
    color:'#484242 !important',
    fontFamily: 'Rubik'
  },
  "@media(max-width:500px)": {
    "&  .MuiOutlinedInput-input" :{
        padding: "11.5px 12px",
      }
    },

    "& .MuiListItem-button": {
      "& :hover":{
        textDecoration: "none",
        backgroundolor: "red",

      }
    }
});

export default withStyles(webStyle)(EditSubmitProposal);
// Customizable Area End
