// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
import TopNav from '../../components/src/TopNav';

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import CustomisableUserProfiles from '../../blocks/CustomisableUserProfiles/src/CustomisableUserProfiles';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import SplitPayments from '../../blocks/SplitPayments/src/SplitPayments';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import StripeIntegration from '../../blocks/StripeIntegration/src/StripeIntegration';
import ReviewPrompt from '../../blocks/ReviewPrompt/src/ReviewPrompt';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import PrivacySettings from '../../blocks/PrivacySettings/src/PrivacySettings';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import Payments from '../../blocks/Payments/src/Payments';
import Reviews from '../../blocks/reviews/src/Reviews';
import AddReview from '../../blocks/reviews/src/AddReview';
import CustomForm from '../../blocks/customform/src/CustomForm';
import NavigationMenu from '../../blocks/navigationmenu/src/NavigationMenu';
import SavedCards from '../../blocks/SavedCards/src/SavedCards';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword.web';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword.web';
import Analytics from '../../blocks/analytics/src/Analytics';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import RefundManagement from '../../blocks/RefundManagement/src/RefundManagement';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import Settings5 from '../../blocks/Settings5/src/Settings5';
import UserGroups2 from '../../blocks/UserGroups2/src/UserGroups2';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import UploadMedia2 from '../../blocks/UploadMedia2/src/UploadMedia2';
import TermsAndConditions from '../../blocks/TermsAndConditions/src/TermsAndConditions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Sorting from '../../blocks/sorting/src/Sorting';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock';
import Search from '../../blocks/search/src/Search';
import TermsAndConditions3 from '../../blocks/TermsAndConditions3/src/TermsAndConditions3';
import AccountGroups from '../../blocks/accountgroups/src/AccountGroups';
import RolesPermissions from '../../blocks/RolesPermissions/src/RolesPermissions';
import AdminConsole from '../../blocks/AdminConsole/src/AdminConsole';
import Customisableuserprofiles2 from '../../blocks/customisableuserprofiles2/src/Customisableuserprofiles2';
import Customform3 from '../../blocks/customform3/src/Customform3';
import Adminconsole2 from '../../blocks/adminconsole2/src/Adminconsole2';
import Splitpayments2 from '../../blocks/splitpayments2/src/Splitpayments2';
import Reviewprompt2 from '../../blocks/reviewprompt2/src/Reviewprompt2';
import Settings2 from '../../blocks/settings2/src/Settings2';
import Rolesandpermissions from '../../blocks/rolesandpermissions/src/Rolesandpermissions';
import TermsConditions from '../../blocks/termsconditions/src/TermsConditions';
import TermsConditionsDetail from '../../blocks/termsconditions/src/TermsConditionsDetail';
import TermsConditionsUsers from '../../blocks/termsconditions/src/TermsConditionsUsers';
import Uploadmedia3 from '../../blocks/uploadmedia3/src/Uploadmedia3';
import Interactivecalculator2 from '../../blocks/interactivecalculator2/src/Interactivecalculator2';
import Download from '../../blocks/download/src/Download';
import Paymentadmin2 from '../../blocks/paymentadmin2/src/Paymentadmin2';
import Sendamessage from '../../blocks/sendamessage/src/Sendamessage.web';
import LandingPage from '../../blocks/landingpage/src/LandingPage';
import Cfzoomintegration25 from '../../blocks/cfzoomintegration25/src/Cfzoomintegration25';
import Customform4 from '../../blocks/customform4/src/Customform4';
import Refundmanagement2 from '../../blocks/refundmanagement2/src/Refundmanagement2';
import Emailnotifications2 from '../../blocks/emailnotifications2/src/Emailnotifications2';
import Dashboard from '../../blocks/dashboard/src/Dashboard';
import InvoiceBilling from '../../blocks/invoicebilling/src/InvoiceBilling';
import CreatePost from '../../blocks/landingpage/src/CreatePost';
import BudgetRange from '../../blocks/landingpage/src/BudgetRange';
import Hire from '../../blocks/dashboard/src/Hire';
import JobPosting from '../../blocks/dashboard/src/JobPosting';
import EnterUserDetails from '../../blocks/dashboard/src/EnterUserDetails';
import JobPostDescription from '../../blocks/dashboard/src/JobPostDescription';
import NavBar from '../../blocks/dashboard/src/Components/NavBar.web';
import PostJob from '../../blocks/dashboard/src/Components/NewPostJob.web';
import Title from '../../blocks/dashboard/src/Components/Title.web';
import MainSkill from '../../blocks/dashboard/src/Components/MainSkill.web';
import WorkScope from '../../blocks/dashboard/src/Components/WorkScope.web';
import UserTypeSelection from '../../blocks/email-account-login/src/UserTypeSelection.web';
import ChooseCategory from '../../blocks/dashboard/src/Components/ChooseCategory.web';
import StartConversation from '../../blocks/dashboard/src/Components/StartConversation.web';
import Profile from '../../blocks/CustomisableUserProfiles/src/Profile.web';
import ClientNavBar from '../../blocks/dashboard/src/Components/ClientNavBar.web';
import TransactionHistory from '../../blocks/dashboard/src/TransactionHistory.web';
import Settings from '../../blocks/CustomisableUserProfiles/src/Settings.web';
import FreelancerProfile from '../../blocks/CustomisableUserProfiles/src/FreelancerProfile.web';
import PaymentSuccessful from '../../blocks/dashboard/src/PaymentSuccessful.web';
import PaymentFailed from '../../blocks/dashboard/src/PaymentFailed.web';
import CustomizedSteppers from '../../blocks/landingpage/src/CustomizedSteppers.web';
import ViewJob from '../../blocks/dashboard/src/ViewJob.web';
import Talent from '../../blocks/dashboard/src/Talent.web';
import SearchTalent from '../../blocks/CustomisableUserProfiles/src/SearchTalent.web';
import InviteToJob from '../../blocks/dashboard/src/InviteToJob.web';
import BlankChatBox from '../../blocks/sendamessage/src/BlankChatBox.web';
import ContactBox from '../../blocks/sendamessage/src/ContactBox.web';
import FreelancerRaiseDisputes from '../../blocks/dashboard/src/FreelancerRaiseDisputes.web'
import FreelancerRaiseDisputesnative from '../../blocks/dashboard/src/FreelancerRaiseDisputes'
import FreelancerAllDisputes from '../../blocks/dashboard/src/FreelancerAllDisputes.web'
import FreelancerAllDisputesnative from '../../blocks/dashboard/src/FreelancerAllDisputes'
import ChattingBox from '../../blocks/sendamessage/src/ChattingBox.web';
import BillsAndPayment from '../../blocks/dashboard/src/BillsAndPayment.web';
import FeedBackForm from '../../blocks/dashboard/src/FeedBackForm.web';
import ClientHomePage from '../../blocks/dashboard/src/ClientHomePage.web';
import InventorForm from '../../blocks/email-account-registration/src/InventorForm.web';
import AllMessages from '../../blocks/sendamessage/src/AllMessages.web';
import ProjectCompletion from '../../blocks/dashboard/src/ProjectCompletion.web';
import ViewFreelancerProfile from '../../blocks/dashboard/src/ViewFreelancerProfile.web';
import PaymentStepper from '../../blocks/dashboard/src/PaymentStepper.web';
import ProfessionalAccountRegistration from '../../blocks/email-account-registration/src/ProfessionalAccountRegistration.web';
import FreelancerIpEnforcement from '../../components/src/FreelancerIpEnforcement.web';
import FreelancerLawyer from '../../components/src/FreelancerLawyer.web';
import FreelancerIpIllustration from '../../components/src/FreelancerIpIllustration.web';
import FreelancerIpValuation from '../../components/src/FreelancerIpValuation.web';
import ContentManagement from '../../blocks/contentmanagement/src/ContentManagement.web';
import SearchIp from '../../blocks/landingpage/src/SearchIp.web';
import PatentLisceningExpert from '../../components/src/PatentLisceningExpert.web';
import PatentAgent from '../../components/src/PatentAgent.web';
import FreelancerSetting from '../../blocks/CustomisableUserProfiles/src/FreelancerSettings.web';
import PrototypeDeveloper from '../../components/src/PrototypeDeveloper.web'
import PatentHolder from '../../components/src/PatentHolder.web';
import SendProposals from '../../blocks/dashboard/src/SendProposals.web';
import SubmitProposals from '../../blocks/dashboard/src/SubmitProposals.web';
import FreelancerNavbar from '../../blocks/dashboard/src/FreelancerNavBar.web';
import FreelancerHomePage from '../../blocks/dashboard/src/FreelancerHomePage.web';
import SearchJob from '../../blocks/dashboard/src/SearchJob.web';
import FreelancerProfileDetailsSettings from '../../blocks/CustomisableUserProfiles/src/FreelancerProfileDetailsSettings.web';
import FreelancerSavedJob from '../../blocks/dashboard/src/FreelancerSavedJob.web';
import FreelancerStripeAccount from '../../blocks/dashboard/src/FreelancerStripeAccount.web';
import FreelancerOffers from '../../blocks/dashboard/src/FreelancerOffers.web';
import FreelancerTransactionHistory from '../../blocks/dashboard/src/FreelancerTransactionHistory.web';
import FreelancerOverview from '../../blocks/dashboard/src/FreelancerOverview.web';
import ActiveContractProjects from '../../blocks/dashboard/src/ActiveContractProjects.web';
import ResolveDispute from '../../blocks/dashboard/src/ResolveDispute.web';
import AllContracts from '../../blocks/dashboard/src/AllContracts.web';
import FreelancerAllContracts from '../../blocks/dashboard/src/FreelancerAllContracts.web';
import FreelancerActiveContract from '../../blocks/dashboard/src/FreelancerActiveContract.web';
import MyJobActiveContract from '../../blocks/dashboard/src/MyJobActiveContract.web';
import GiveRefund from '../../blocks/dashboard/src/GiveRefund';
import GlobalSearchTalent from '../../../packages/components/src/GlobalSearchTalent.web';
import GlobalSearchJob from '../../../packages/components/src/GlobalSearchJobs.web';
import FullWidthTab from '../../../packages/components/src/FullWidthTab.web';
import GlobalJobProfile from '../../../packages/components/src/GlobalJobProfile.web'
import GlobalClientProfile from '../../../packages/components/src/GlobalClientProfile.web'
import EditClientProfile from '../../blocks/CustomisableUserProfiles/src/EditClientProfile.web';
import EditFreelancerProfile from '../../blocks/CustomisableUserProfiles/src/EditFreelancerProfile.web';
import About  from '../../blocks/contentmanagement/src/About.web';
import FAQ from '../../blocks/contentmanagement/src/FAQ.web';
import ContactUs from '../../blocks/contentmanagement/src/ContactUs.web';
import TermsAndConditonsContentManagement from '../../blocks/contentmanagement/src/TermsAndConditonsContentManagement.web';
import PrivacyAndPolicyContentManagement from '../../blocks/contentmanagement/src/PrivacyAndPolicyContentManagement.web';
import HowToFindWork from '../../blocks/contentmanagement/src/HowToFindWork.web';
import FullWidthJobsTab from '../../blocks/dashboard/src/FullWidthJobsTab.web'
import JobReviewPage from '../../blocks/dashboard/src/JobReviewPage.web'
import HowToHire from '../../blocks/contentmanagement/src/HowToHire.web';
import InvitationToInterview from '../../blocks/dashboard/src/InvitationToInterview.web'
import AcceptInterview from '../../blocks/dashboard/src/AcceptInterview.web'
import AddPaymentDetails from '../../blocks/dashboard/src/AddPaymentDetails.web';
import ExpertiseAndFeesForm from '../../blocks/customform/src/ExpertiseAndFeesForm.web'
import CustomFormExperience from '../../blocks/customform/src/CustomFormExperience.web'
import PatentsForm from '../../blocks/customform/src/PatentsForm.web'
import AchievementsCustomForm from '../../blocks/customform/src/AchievementsCustomForm.web'
import RemindMeDialogbox from '../../blocks/customform/src/RemindMeDialogbox.web'
import SuccessDialogbox from '../../blocks/customform/src/SuccessDialogbox.web'
import FreelancerCompleteProfile from '../../blocks/CustomisableUserProfiles/src/FreelancerCompleteProfile.web'
import FullWidthTabProposals from '../../blocks/dashboard/src/FullWidthTabProposals.web'
import FreelancerArchiveProposals from '../../blocks/dashboard/src/FreelancerArchiveProposals.web'
import OTPVerification from '../../blocks/email-account-registration/src/OTPVerification.web'
import ClientBasicDetailsForm from '../../blocks/customform/src/ClientBasicDetailsForm.web'
import OtpValidation from '../../blocks/email-account-registration/src/OtpValidation.web'
import ActiveContracts from '../../blocks/dashboard/src/ActiveContracts.web';
import ViewProposals from '../../blocks/dashboard/src/ViewProposals.web';
import ViewSubmitProposal from '../../blocks/dashboard/src/ViewSubmitProposal.web';
import EditSubmitProposal from '../../blocks/dashboard/src/EditSubmitProposal.web';
import AllContractClient from '../../blocks/dashboard/src/AllContractClient.web'; 
import ActiveContractDetailsClient from "../../blocks/dashboard/src/ActiveContractDetailsClient.web";
import Location from '../../blocks/dashboard/src/Location.web';

const routeMap = {
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2'
  },
  FreelancerStripeAccount: {
    component: FreelancerStripeAccount,
    path: '/freelancerstripeaccount'
  },
  NavBar: {
    component: NavBar,
    path: '/NavBar'
  },

  PostJob: {
    component: PostJob,
    path: '/PostJob'
  },
  Title: {
    component: Title,
    path: '/Title'
  },
  MainSkill: {
    component: MainSkill,
    path: '/mainSkill'
  },
  WorkScope: {
    component: WorkScope,
    path: '/workScope'
  },
  ChooseCategory: {
    component: ChooseCategory,
    path: '/chooseCategory'
  },
  TransactionHistory: {
    component: TransactionHistory,
    path: '/transactionHistory'
  },
  Dashboard: {
    component: Dashboard,
    path: '/Dashboard'
  },
  InvoiceBilling: {
    component: InvoiceBilling,
    path: '/InvoiceBilling'
  },

  Customform4: {
    component: Customform4,
    path: '/Customform4'
  },
  Refundmanagement2: {
    component: Refundmanagement2,
    path: '/Refundmanagement2'
  },

  Interactivecalculator2: {
    component: Interactivecalculator2,
    path: '/Interactivecalculator2'
  },
  Download: {
    component: Download,
    path: '/Download'
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: '/Paymentadmin2'
  },
  Sendamessage: {
    component: Sendamessage,
    path: '/Sendamessage'
  },
  LandingPage: {
    component: LandingPage,
    path: '/landingpage'
  },
  Cfzoomintegration25: {
    component: Cfzoomintegration25,
    path: '/Cfzoomintegration25'
  },

  Customisableuserprofiles2: {
    component: Customisableuserprofiles2,
    path: '/Customisableuserprofiles2'
  },
  Customform3: {
    component: Customform3,
    path: '/Customform3'
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2'
  },
  Splitpayments2: {
    component: Splitpayments2,
    path: '/Splitpayments2'
  },
  Reviewprompt2: {
    component: Reviewprompt2,
    path: '/Reviewprompt2'
  },
  Settings2: {
    component: Settings2,
    path: '/Settings2'
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions'
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions'
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail'
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers'
  },
  Uploadmedia3: {
    component: Uploadmedia3,
    path: '/Uploadmedia3'
  },

  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  TermsAndConditions3: {
    component: TermsAndConditions3,
    path: '/TermsAndConditions3'
  },
  AccountGroups: {
    component: AccountGroups,
    path: '/AccountGroups'
  },
  RolesPermissions: {
    component: RolesPermissions,
    path: '/RolesPermissions'
  },
  AdminConsole: {
    component: AdminConsole,
    path: '/AdminConsole'
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  CustomisableUserProfiles: {
    component: CustomisableUserProfiles,
    path: '/CustomisableUserProfiles'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  SplitPayments: {
    component: SplitPayments,
    path: '/SplitPayments'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  StripeIntegration: {
    component: StripeIntegration,
    path: '/StripeIntegration'
  },
  ReviewPrompt: {
    component: ReviewPrompt,
    path: '/ReviewPrompt'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: '/PrivacySettings'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  Payments: {
    component: Payments,
    path: '/Payments'
  },
  Reviews: {
    component: Reviews,
    path: '/Reviews'
  },
  AddReview: {
    component: AddReview,
    path: '/AddReview'
  },
  CustomForm: {
    component: CustomForm,
    path: '/CustomForm'
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu'
  },
  SavedCards: {
    component: SavedCards,
    path: '/SavedCards'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Analytics: {
    component: Analytics,
    path: '/Analytics'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  RefundManagement: {
    component: RefundManagement,
    path: '/RefundManagement'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  Settings5: {
    component: Settings5,
    path: '/Settings5'
  },
  UserGroups2: {
    component: UserGroups2,
    path: '/UserGroups2'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  UploadMedia2: {
    component: UploadMedia2,
    path: '/UploadMedia2'
  },
  TermsAndConditions: {
    component: TermsAndConditions,
    path: '/TermsAndConditions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  Search: {
    component: Search,
    path: '/Search'
  },
  Home: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },
  UserTypeSelection: {
    component: UserTypeSelection,
    path: '/usertypeselection'
  },
  CreatePost: {
    component: CreatePost,
    path: '/createpost'
  },
  BudgetRange: {
    component: BudgetRange,
    path: '/budgetrange'
  },
  Hire: {
    component: Hire,
    path: '/hire'
  },
  JobPosting: {
    component: JobPosting,
    path: '/jobposting'
  },
  EnterUserDetails: {
    component: EnterUserDetails,
    path: '/enterdetails'
  },

  FullWidthJobsTab:{
    component: FullWidthJobsTab,
    path: '/myjobs'
  },
  JobPostDescription: {
    component: JobPostDescription,
    path: '/jobpostdescription'
  },
  StartConversation: {
    component: StartConversation,
    path: '/startconversation'
  },
  ClientNavBar: {
    component: ClientNavBar,
    path: '/ClientNavBar'
  },
  Profile: {
    component: Profile,
    path: '/profile'
  },
  FreelancerProfile: {
    component: FreelancerProfile,
    path: '/freelancerprofile'
  },
  Settings: {
    component: Settings,
    path: '/settings'
  },
  PaymentSuccessful: {
    component: PaymentSuccessful,
    path: '/paymentsuccessful'
  },
  PaymentFailed: {
    component: PaymentFailed,
    path: '/paymentfailed'
  },
  CustomizedSteppers: {
    component: CustomizedSteppers,
    path: '/customizedsteppers'
  },
  ViewJob: {
    component: ViewJob,
    path: '/ViewJob'
  },
  Talent: {
    component: Talent,
    path: '/Talent'
  },
  SavedTalent: {
    component: Talent,
    path: '/SavedTalent'
  },
  YourHires: {
    component: Talent,
    path: '/YourHires'
  },
  InviteToJob: {
    component: InviteToJob,
    path: '/invitetojob'
  },
  BlankChatBox: {
    component: BlankChatBox,
    path: '/blankchat'
  },
  ChattingBox: {
    component: ChattingBox,
    path: '/chattingbox'
  },
  ContactBox: {
    component: ContactBox,
    path: '/chat'
  },
  ContactBox2: {
    component: ContactBox,
    path: '/chatInbox/:id'
  },
  FreelancerRaiseDisputes:{
    component: FreelancerRaiseDisputes,
    path: '/FreelancerRaiseDisputes/:id'
  },
  FreelancerAllDisputes:{
    component: FreelancerAllDisputes,
    path: '/FreelancerAllDisputes/:id'
  },
  AllMessages: {
    component: AllMessages,
    path: '/AllMessages'
  },
  BillsAndPayment: {
    component: BillsAndPayment,
    path: '/billsandpayment'
  },
  ProjectCompletion: {
    component: ProjectCompletion,
    path: '/projectcompletion'
  },
  FeedBackForm: {
    component: FeedBackForm,
    path: '/feedbackform'
  },
  ClientHomePage: {
    component: ClientHomePage,
    path: '/clienthomepage'
  },
  SearchTalent: {
    component: SearchTalent,
    path: '/searchtalent'
  },
  ViewFreelancerProfile: {
    component: ViewFreelancerProfile,
    path: '/viewfreelancerprofile'
  },
  PaymentStepper: {
    component: PaymentStepper,
    path: '/payment'
  },
  ProfessionalAccountRegistration: {
    component: ProfessionalAccountRegistration,
    path: '/professional-account-registration'
  },
  FreelancerIpEnforcement: {
    component: FreelancerIpEnforcement,
    path: '/freelanceripenforcement'
  },
  FreelancerLawyer: {
    component: FreelancerLawyer,
    path: '/freelancerlawyer'
  },
  InventorForm: {
    component: InventorForm,
    path: '/inventor-form'
  },
  FreelancerIpIllustration: {
    component: FreelancerIpIllustration,
    path: '/freelanceripillustration'
  },
  FreelancerIpValuation: {
    component: FreelancerIpValuation,
    path: '/freelanceripvaluation'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/contentmanagement'
  },
  SearchIp: {
    component: SearchIp,
    path: '/searchip'
  },
  PatentLisceningExpert: {
    component: PatentLisceningExpert,
    path: '/patentlisceningexpert'
  },
  PatentAgent: {
    component: PatentAgent,
    path: '/patentagent'
  },
  PrototypeDeveloper: {
    component: PrototypeDeveloper,
    path: '/prototypedeveloper'
  },
  FreelancerSetting: {
    component: FreelancerSetting,
    path: '/freelancersetting'
  },
  PatentHolder: {
    component: PatentHolder,
    path: '/patentholder'
  },
  SendProposals: {
    component: SendProposals,
    path: '/Send-Proposals'
  },
  SubmitProposals: {
    component: SubmitProposals,
    path: '/Submit-Proposals'
  },
  FreelancerNavbar: {
    component: FreelancerNavbar,
    path: '/freelancernavbar'
  },
  FreelancerHomePage: {
    component: FreelancerHomePage,
    path: '/freelancerhomepage'
  },
  SearchJob: {
    component: SearchJob,
    path: '/searchJob'
  },
  FreelancerProfileDetailsSettings: {
    component: FreelancerProfileDetailsSettings,
    path: '/freelancerprofileDetailssettings'
  },
  FreelancerSavedJob: {
    component: FreelancerSavedJob,
    path: '/freelancerSavedJob'
  },
  FreelancerOffers: {
    component: FreelancerOffers,
    path: '/offers'
  },
  FreelancerTransactionHistory: {
    component: FreelancerTransactionHistory,
    path: '/freelancertransactionHistory'
  },
  FreelancerOverview: {
    component: FreelancerOverview,
    path: '/freelancerOverview'
  },
  ActiveContractProjects: {
    component: ActiveContractProjects,
    path: '/activecontractprojects'
  },
  ResolveDispute: {
    component: ResolveDispute,
    path: '/resolveDispute'
  },
  AllContracts: {
    component: AllContracts,
    path: '/allContracts'
  },
  FreelancerAllContracts: {
    component: FreelancerAllContracts,
    path: '/FreelancerAllContracts'
  },
  FreelancerActiveContract: {
    component: FreelancerActiveContract,
    path: '/FreelancerActiveContract/:id'
  },
  MyJobActiveContract: {
    component: MyJobActiveContract,
    path: '/myJobActiveContract'
  },
  GiveRefund: {
    component: GiveRefund,
    path: '/giveRefund'
  },
  GlobalSearchTalent: {
    component: GlobalSearchTalent,
    path: '/globalsearchtalent'
  },
  GlobalSearchJob: {
    component: GlobalSearchJob,
    path: '/globalsearchjob'
  },
  FullWidthTab: {
    component: FullWidthTab,
    path: '/globalSearch'
  },
  GlobalJobProfile: {
    component: GlobalJobProfile,
    path: '/globaljobprofile'
  },
  GlobalClientProfile: {
    component: GlobalClientProfile,
    path: '/globalclientprofile'
  },
  EditClientProfile: {
    component: EditClientProfile,
    path: '/editclientprofile'
  },
  EditFreelancerProfile: {
    component: EditFreelancerProfile,
    path: '/editfreelancerprofile'
  },
  About: {
    component: About,
    path: '/About'
  },
  FAQ: {
    component: FAQ,
    path: '/FAQ'
  },
  ContactUs: {
    component: ContactUs,
    path: '/contact-us'
  },
  TermsAndConditonsContentManagement: {
    component: TermsAndConditonsContentManagement,
    path: '/terms-and-conditions'
  },
   PrivacyAndPolicyContentManagement: {
    component:  PrivacyAndPolicyContentManagement,
    path: '/privacy-policy'
  },
  HowToFindWork: {
    component:  HowToFindWork,
    path: '/HowToFindWork'
  },
  JobReviewPage: {
    component:  JobReviewPage,
    path: '/JobReviewPage'
  },
  
  HowToHire: {
    component:  HowToHire,
    path: '/HowToHire'
  },
  InvitationToInterview: {
    component:  InvitationToInterview,
    path: '/InvitationToInterview/:id'
  },
  AcceptInterview:{
    component: AcceptInterview,
    path:'/AcceptInterview'
  },
  AddPaymentDetails:{
    component: AddPaymentDetails,
    path:'/AddPaymentDetails'
  },
  FullWidthTabProposals:{
    component: FullWidthTabProposals,
    path:'/proposals'
  },
  FreelancerArchiveProposals:{
    component:FreelancerArchiveProposals,
    path:'/archiveproposals'
  },
  ExpertiseAndFeesForm:{
    component:ExpertiseAndFeesForm,
    path:'/ExpertiseAndFeesForm'
  },
  CustomFormExperience:{
    component:CustomFormExperience,
    path:'/CustomFormExperience'
  },
  PatentsForm:{
    component:PatentsForm,
    path:'/PatentsForm'
  },
  AchievementsCustomForm:{
    component:AchievementsCustomForm,
    path:'/AchievementsCustomForm'
  },
  FreelancerCompleteProfile:{
    component:FreelancerCompleteProfile,
    path:'/FreelancerCompleteProfile'
  },
  RemindMeDialogbox:{
    component:RemindMeDialogbox,
    path:'/RemindMeDialogbox'
  },
  SuccessDialogbox:{
    component:SuccessDialogbox,
    path:'/SuccessDialogbox'
  },
  OTPVerification:{
    component:OTPVerification,
    path:"/otpVerification"
  },
  ClientBasicDetailsForm:{
    component:ClientBasicDetailsForm,
    path:"/clientBasicDetails"
  },
  OtpValidation:{
    component:OtpValidation,
    path:"/OtpValidation"
  },
  ActiveContracts:{
    component:ActiveContracts,
    path:"/Activecontracts"
  },
  ActiveContractDetailsClient:{
    component:ActiveContractDetailsClient,
    path:"/Activecontractsclient/:id"
  },
  ViewProposals:{
    component:ViewProposals,
    path:"/ViewProposals"
  },
  ViewSubmitProposal:{
    component:ViewSubmitProposal,
    path:"/ViewSubmitProposal/:id"
  },
  EditSubmitProposal:{
    component:EditSubmitProposal,
    path:"/EditSubmitProposal/:id"
  },   
   AllContractClient:{
    component: AllContractClient,
    path: '/AllContractsClient'
  }, 
  Location:{
    component: Location,
    path: '/Location'
  },
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  scrollToTop=() =>{
    window.scrollTo(
      0,0
    );
  }
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (

      <View style={{ height: 'auto', minHeight: '100vh' }}>
        {this.scrollToTop()}
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
    );
  }
}

export default App;
