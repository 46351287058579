import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
// Customizable Area Start
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
import { Message } from "../../../framework/src/Message";
import storage from 'framework/src/StorageProvider.web';
// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    activeButton: string;
    age:  string;
    open: boolean;
    showJobs:boolean;
    date:string;
    activeTab: any
    activeDetailsData: any;
    postDetailsData: any;
    submissionDateErrorMessage: any;
    searchDataList: any;
    authToken:string
    value:any;
    uploadedFileName: any
    isOpenModal: boolean
    searchQuery: string
    milestoneDocumentsDetailsData: any
    succesMessage: string
    milestoneDetailsData: any
    originalMilestoneData: any
    isOpenSubmitWorkModal: boolean
    postId: any
    milestoneupdate: boolean
    isOpenSubmitWorkModalId: any
    isOpenSubmitWorkMilestoneModalId: any
    isOpenSubmitMilestoneWorkModal: boolean
    isOpenUploadDocCom: boolean
    selectedFiles: any
    selectedFilesvalidationMessage: string
    anchorEl: any
    // Customizable Area End
}

interface SS {
    // Customizable Area Start
    // Customizable Area End
}

  // Customizable Area Start
  // Customizable Area End

export default class FreelancerActiveContractController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    contractDetailsId: string = ""
    getPostDocId: string = ""
    submitContractsId: string = ""
    milestoneDocTrackingId: string = ""
    milestoneDocumentsTrackingSearchId: string = ""
    uploadWorkId: string = ""
    milestoneDetailsId: string = ""
    // Customizable Area End
    constructor(props:Props){
        super(props) ;
        this.receive=this.receive.bind(this);
    this.subScribedMessages=[getName(MessageEnum.RestAPIResponceMessage)];
        // Customizable Area Start

        this.state={
            age: "",
            open: false,
            showJobs:false,
            date:"",
            activeButton: "offers",
            activeTab: 0,
            activeDetailsData:[], 
            postDetailsData: [],
            submissionDateErrorMessage: {},
            searchDataList :[],
            authToken:'',
            value: '',
            uploadedFileName: '',
            isOpenModal: false,
            searchQuery:'',
            milestoneDocumentsDetailsData: [],
            succesMessage: '',
            originalMilestoneData: [],
            milestoneDetailsData:[{
          "id": "",
          "type": "",
          "attributes": {
            "id": 0,
            "description": "",
            "date": null,
            "status": "",
            "amount": "",
            "proposal_generation_id": 0,
            "is_project_ended": false,
            "upload_works": [
              {
                "work": "",
                "name": "",
                "type": ""
              },
                ]
          }
          }],
            isOpenSubmitWorkModal: false,
            postId : '',
            isOpenSubmitWorkModalId: null,
            isOpenSubmitWorkMilestoneModalId: null,
            isOpenSubmitMilestoneWorkModal: false,
            isOpenUploadDocCom : false,
            milestoneupdate:false,
            selectedFiles: [],
            selectedFilesvalidationMessage: '',
            anchorEl: null
        };

        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        // Customizable Area Start
        this.setState({
            authToken: await storage.get('authToken'),
        });
        this.getActiveMilestoneDetails()
        this.getActiveContractDetails()
        this.getMilestoneDocumentsTracking()
        this.getDocumentsTrackingSearch()
        // Customizable Area End
    }



    // Customizable Area Start
    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
          
          // return;
        }
    
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
          const webApiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
          );
      
          let webResponseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          
         this.handleActiveContractRes(webResponseJson,webApiRequestCallId)
         this.handleSubmitProjectTrackingRes(webResponseJson,webApiRequestCallId)
         this.handleMilestoneContractRes(webResponseJson,webApiRequestCallId)
         this.handleUploadWorkRes(webResponseJson,webApiRequestCallId)
         this.handlegetPostDocRes(webResponseJson,webApiRequestCallId)
         this.handleMilestoneDocTrackingRes(webResponseJson,webApiRequestCallId)
         this.handleSearchDocTrackRes(webResponseJson,webApiRequestCallId)
        }
        // Customizable Area End
      }
    // Customizable Area End
    
    // Customizable Area Start

 

  convertToFile = async (fileData: { work: string; filename: string; content_type: string }): Promise<File | null> => {
    try {
      const response = await fetch(fileData.work);
      if (!response.ok) throw new Error("Failed to fetch file");
      
      const blob = await response.blob();
      return new File([blob], fileData.filename, { type: fileData.content_type });
    } catch (error) {
      console.error("Error fetching the image:", error);
      return null;
    }
  };
  handleUploadWorkApi = async (id: number) => {
    if (!this.state.selectedFiles || this.state.selectedFiles.length === 0) {
      this.setState({ selectedFilesvalidationMessage: "Please select at least one file" });
      return false;
    }
  
    let selectedFiles: File[] = [];

    for (const file of this.state.selectedFiles) {
      if (file instanceof File) {
        selectedFiles.push(file); 
      } else if (file.work && file.filename && file.content_type) {
        const convertedFile = await this.convertToFile(file);
        if (convertedFile) {
          selectedFiles.push(convertedFile);
        }
      }
    }
  
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append("milestone[upload_works][]", file);
    });
  
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
    };
  
    const submitContracts = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.uploadWorkId = submitContracts.messageId;
  
    submitContracts.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/milestones/${id}/upload_work_attachments`
    );
    submitContracts.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    submitContracts.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formData);
    submitContracts.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PATCH");
  
    runEngine.sendMessage(submitContracts.id, submitContracts);
    return true;
  };
  
                

handleCloseModal = () =>{
  this.setState({isOpenModal : false, 
    isOpenSubmitWorkModal: false,
    isOpenSubmitWorkModalId: null,
    isOpenSubmitMilestoneWorkModal:false,
    isOpenSubmitWorkMilestoneModalId: null
  })
  this.getActiveMilestoneDetails();
}


      handleUploadWorkModal= (modalData:any, id: number) => {
        this.setState({
          isOpenSubmitWorkModal: true,
          selectedFiles: modalData.attributes?.upload_works,
          isOpenSubmitWorkModalId: id
        })
      }
      handleMilestoneDataupload = () => {
          this.setState({
            isOpenUploadDocCom: true
          })
        }

        handleMilestoneDatauploadClose = () => {
          this.setState({
            isOpenUploadDocCom: false
          })
        }
             handleDownload = async (fileUrl: string, fileType: string, fileName: string) => {
          if (!fileUrl) {
            console.error("File URL is not available.");
            return;
          }
        
          try {
            let extension = ""; 
        
            const mimeMap: { [key: string]: string } = {
              "application/pdf": "pdf",
              "application/msword": "doc",
              "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "docx",
              "text/plain": "txt",
              "image/png": "png",
              "image/jpeg": "jpg",
              "image/jpg": "jpg",
              "image/gif": "gif",
            };
        
            // Extract the correct file extension
            extension = mimeMap[fileType] || fileType.split("/")[1] || "";
        
            // Ensure file name has the correct extension
            const nameWithExtension = fileName.includes(".")
              ? fileName
              : `${fileName}.${extension}`;
        
            // Fetch the file
            const response = await fetch(fileUrl);
            if (!response.ok) {
              throw new Error(`Failed to fetch the file. Status: ${response.status}`);
            }
        
            const blob = await response.blob();
            const link = document.createElement("a");
            const objectUrl = URL.createObjectURL(blob);
        
            link.href = objectUrl;
            link.download = nameWithExtension;
            document.body.appendChild(link);
            link.click();
        
            document.body.removeChild(link);
            URL.revokeObjectURL(objectUrl);
          } catch (error) {
            console.error("Error during file download:", error);
          }
        };

    handleMilestoneDocTrackingRes = (webResponseJson:any,webApiRequestCallId: string | undefined) =>{
      if (webResponseJson) {   
        if (webApiRequestCallId === this.milestoneDocTrackingId) {

          const data = webResponseJson.data?.map((item: any) => item.attributes) || []; 
            
          this.setState({
            milestoneDocumentsDetailsData: data
          });
        }
      }
      
    }

        handleSubmitProjectTrackingRes = (webResponseJson: { data: any; error: 'string',message: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.submitContractsId) {
              if(webResponseJson?.data){
                this.setState({
                    isOpenModal: true,
                    succesMessage: 'Milestone has been successfully updated',
                    milestoneupdate: true
                })
              }else{
                this.setState({
                    isOpenModal: true,
                    succesMessage: webResponseJson?.error,
                })
              }
            }
           
          }
      }

      handleChanges = (event: any ) => {
        this.setState({date: event.target.value as string
        })
    };

      handleUploadWorkRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.uploadWorkId) {
              if(webResponseJson?.error){
                this.setState({ 
                  succesMessage: webResponseJson?.error[0] ,
                  isOpenModal: true,
                })
              }
              if(webResponseJson?.data){
                this.setState({ 
                  isOpenSubmitWorkModal: false,
                  isOpenModal: true,
                  succesMessage: 'Documents uploaded sucessfully',
                  milestoneupdate: true
                })
                this.getMilestoneDocumentsTracking()
              }
                         }
          }
      }

    handleSearchChange = (event: { target: { value: any; }; }) => {
      this.setState({ searchQuery: event.target.value }, () => {
        this.getDocumentsTrackingSearch();
      });
    };

    getDocumentsTrackingSearch = () => {
      const { searchQuery, authToken } = this.state;
      const jobId = this.state.activeDetailsData?.attributes?.post_attributes?.data?.id
      
      if (!searchQuery.trim()) return; 
  
      const header = {
        token: authToken,
        "Content-Type": webConfigJSON.validationApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      this.milestoneDocumentsTrackingSearchId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_contract/milestones/freelancer_search_post_document?id=${jobId}&query=${encodeURIComponent(searchQuery)}`
       );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };

      handleActiveContractRes = (
        webResponseJson: { data: any; error: 'string' } | undefined,
        webApiRequestCallId: string | undefined
      ) => {
        if (webResponseJson && webApiRequestCallId === this.contractDetailsId && webResponseJson.data) {
          const { data } = webResponseJson;
          const postId = data.attributes?.post_attributes?.data?.id || null;
      
          this.setState(
            {
              activeDetailsData: data,
              postId,
            },
            () => {
              this.getPostDocApiCall();
            }
          );
        }
      };


      handlegetPostDocRes = (webResponseJson: { data: any; error: 'string' } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
        if (webApiRequestCallId === this.getPostDocId) {
            if(webResponseJson?.data){
              this.setState({ postDetailsData: webResponseJson.data })
            }
          }
        }
      }
      handleSearchDocTrackRes = (webResponseJson: { data: any; error: 'string',message:string } | undefined,webApiRequestCallId: string | undefined) =>{
        if (webResponseJson) {
          if (webApiRequestCallId === this.milestoneDocumentsTrackingSearchId){
            if (webResponseJson?.data) {
              this.setState({ postDetailsData : webResponseJson.data })
            }
            else if (webResponseJson?.message) {
              this.setState({postDetailsData: []})
            }
          }
        }
      }
      
      handleInputChange = (index: number, field: string, value: string | unknown) => {
        if (field === 'date' && typeof value === 'string') {
          const selectedDate = new Date(value);
          const today = new Date();
          today.setHours(0, 0, 0, 0);
          selectedDate.setHours(0, 0, 0, 0);
      
          this.setState((prevState) => {
            const updatedErrors = { ...prevState.submissionDateErrorMessage };
      
            if (selectedDate <= today) {
              updatedErrors[index] = 'Submission date should be in the future';
            } else {
              delete updatedErrors[index]; 
            }
      
            const updatedMilestones = [...prevState.milestoneDetailsData];
            if (updatedMilestones[index]) {
              updatedMilestones[index] = {
                ...updatedMilestones[index],
                attributes: {
                  ...updatedMilestones[index].attributes,
                  [field]: value,
                },
                isModified: true, 
              };
            }
      
            return {
              milestoneDetailsData: updatedMilestones,
              submissionDateErrorMessage: updatedErrors, // Store errors correctly
            };
          });
        }
      };
      

    handleRemoveFile = (name: string) => {
      if (Array.isArray(this.state.selectedFiles)) {
        let itemIndex = this.state.selectedFiles.findIndex((item: any) =>
          item.filename.includes(name)
        );
        this.state.selectedFiles.splice(itemIndex, 1);
        this.setState({ selectedFiles: this.state.selectedFiles });
      } 
    };
    
    
    handleOpen = () => {
        this.setState({ 
            open: true
         });
    };

      handleInputChangeS = (event: any) => {
        this.setState({ value: event.target.value });
      };
    
      handleFileUpload = (event: any, id: number) => {
        const file = event.target.files?.[0];
        if (file) {
          const updatedMilestoneDetails = [...this.state.milestoneDetailsData];
          const milestoneIndex = updatedMilestoneDetails.findIndex(
            (milestone) => String(milestone.id) === String(id)
          );
      
          if (milestoneIndex !== -1) {
            updatedMilestoneDetails[milestoneIndex].attributes.upload_works = [{ name: file.name, file }];
      
            this.setState({ milestoneDetailsData: updatedMilestoneDetails }, () => {
              this.handleUploadWorkApi(id);
            });
          } 
        } 
      };
  
      
 


       handleAttachClick = () => {
        const fileInput = document.getElementById("file-upload-input") as HTMLInputElement;
        if (fileInput) {
          fileInput.click();
        }
  };


  handleFileChange = (event: any) => {
    const files = Array.from(event.target.files);
  
    const validFiles = files.filter((file: any) => file.size <= 5 * 1024 * 1024);
  
    const selectedFiles = Array.isArray(this.state.selectedFiles) ? this.state.selectedFiles : [];
  
    const newFiles = validFiles.filter(
      (file: any) =>
        !selectedFiles.some((existingFile: any) => existingFile.name === file.name)
    );
  
    const updatedFiles = [...selectedFiles, ...newFiles].slice(0, 5);
  
    this.setState({ selectedFiles: updatedFiles });
  };
  
  getMilestoneDocumentsTracking = async () => {
    
    const header = {
        token: this.state.authToken,
        "Content-Type": webConfigJSON.validationApiContentType,
    };

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.milestoneDocTrackingId = requestMessage.messageId;
    const paramId = this.props.navigation.getParam("id")

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_contract/milestones/freelancer_get_milestone_documents?id=${paramId}`
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
};
    
      handleTabChange = (event: any, newValue: number) => {
        this.setState({ activeTab: newValue }, () => {
            this.getActiveMilestoneDetails();
            this.getActiveContractDetails()
        })
    };
    getPostDocApiCall = async () => {

      const header = {
          token: this.state.authToken,
          "Content-Type": webConfigJSON.validationApiContentType,
      };
  
      const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getPostDocId = requestMessage.messageId;
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
      );
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_contract/milestones/freelancer_get_post_documents?id=${this.state.postId}`
      );
  
      requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
      return true;
  };
              
getActiveContractDetails = async () => {
    
  const header = {
      token: this.state.authToken,
      "Content-Type": webConfigJSON.validationApiContentType,
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.contractDetailsId = requestMessage.messageId;
  const paramId = this.props.navigation.getParam("id")

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/contracts/contract_details?id=${paramId}`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

getActiveMilestoneDetails=async()=>{
  const header={
    "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
  }

  const requestMessage=new Message(
       getName(MessageEnum.RestAPIRequestMessage))

  const paramId=this.props.navigation.getParam("id")
  this.milestoneDetailsId=requestMessage.messageId

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/milestones/get_proposal_milestones?proposal_generation_id=${paramId}`)

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET")

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header))

  runEngine.sendMessage(requestMessage.id, requestMessage)
  return true;
};


    handleDeleteAllFiles = () => {
      this.setState({
        selectedFiles:[]
      })
    }

    handleMenuOpen = (event: any, index: any) => {
      const anchorEls = [...this.state.anchorEl || []];
      anchorEls[index] = event.currentTarget; // Set the anchor for the specific file
      this.setState({ anchorEl: anchorEls });
    };
    
    handleMenuClose = (index: any) => {
      const anchorEls = [...this.state.anchorEl || []];
      anchorEls[index] = null; // Close the popover for the specific file
      this.setState({ anchorEl: anchorEls });
    };
  
    handleStatusChange = (event: { target: { value: any; }; }) => {
      this.setState({
        activeDetailsData: {
          ...this.state.activeDetailsData,
          attributes: {
            ...this.state.activeDetailsData.attributes,
            status: event.target.value
          }
        }
      });
    };
    
         
    handleSubmitMilestone = (id: any, milestoneData: any) => {

      const submitContracts = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      const header = {
        "token": this.state.authToken
      };
    
      const formData = new FormData();
      formData.append('milestone[description]', milestoneData.description);
      formData.append('milestone[date]', milestoneData.date);
      formData.append('milestone[status]', milestoneData.status);
      
    
      this.submitContractsId = submitContracts.messageId;
      submitContracts.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_contract/milestones/${id}/update_milestone_by_freelancer`
      );
      submitContracts.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      submitContracts.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData 
      );
      submitContracts.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        'PATCH'
      );
      runEngine.sendMessage(submitContracts.id, submitContracts);
    };  

    getStatusColor = (status: string) => {
      switch (status) {
        case "completed":
          return "#348306";
        case "ongoing":
          return "#364BA0";
        case "pending":
          return "red";
        default:
          return "black";
      }
    };

    handleMilestoneContractRes = (webResponseJson:any, webApiRequestCallId:any) => {
      if (webResponseJson && webApiRequestCallId === this.milestoneDetailsId) {
        if (webResponseJson.data) {
          const milestones = webResponseJson.data.map((milestone: any) => ({
            ...milestone,
            isModified: false, 
          }));
          this.setState({
            milestoneDetailsData: milestones,
            originalMilestoneData: JSON.parse(JSON.stringify(milestones)),
          });
        }
      }
    };
    // Customizable Area End
}

     // Customizable Area Start
    // Customizable Area End  
