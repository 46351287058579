import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openBillingModal: boolean
    openPaymentCardModal:boolean
    selectedValue: string,
    showPayPalSection: boolean
    openPaypalModal: boolean
    paypalEmail:string
    paypalEmailError:string
    showAddPaymentCard: boolean
    isDialogOpen: boolean
    cardDetailsData: any
    userDetails: any
    successMessage: string
    errorMessage: string
    isCardDialogOpen: boolean
    authToken:any;
    paymentSuccessPopup: boolean
    showDetails: boolean
    cardNumber : string
    cardHolderName: string
    validDate: string
    validYear: string
    cvvNumber: string
    deletePaypalDetails: string
    isDeleteModal: boolean
    paypalData: any
    paypalDataVisible: boolean
    errors: any,
    // Customizable Area End
}
interface SS { }

export default class ClientBillingInformationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    paypalApiCallDetailsId: any
    redirectPaypalApiCallDetailsId: any
    cardFetailsApiCallId: any;
    userCallID: any
    paypalDataID: any
    cardPaymentId: any
    paypalDeleteCallId: any
    paymentCardDeleteCallId: any

    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.paypalApiCallDetailsId = "",
        this.redirectPaypalApiCallDetailsId = "",
        this.cardFetailsApiCallId = "",
        this.userCallID = "",
        this.paypalDataID = ""
        this.cardPaymentId = "",
        this.paypalDeleteCallId = "",
        this.paymentCardDeleteCallId = "",
        this.state = {
            openBillingModal: false,
            openPaymentCardModal:false,
            selectedValue: 'PaymentCard',
            showPayPalSection: false,
            openPaypalModal: false,
            paypalEmail: '',
            paypalEmailError: '',
            showAddPaymentCard:false,
            isDialogOpen: false,
            cardDetailsData: [],
            userDetails: [],
            successMessage: '',
            paypalData: [],
            paypalDataVisible: false,
            errorMessage: '',
            isCardDialogOpen: false,
            authToken: '',
            paymentSuccessPopup: false,
            showDetails: false,
            cardNumber : '',
            cardHolderName: '',
            validDate: '',
            validYear: '',
            cvvNumber: '',
            deletePaypalDetails: '',
            isDeleteModal: false,
            errors: {
                cardNumber: "",
                cardHolderName: "",
                validDate: "",
                cvvNumber: ''
              },
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getDashboardData();
        // Customizable Area Start
        this.setState({
            authToken: await storage.get("authToken"),
          })
        this.getCardDetailsCall()
          this.getUserData()
          this.getPaypalData()
        // Customizable Area End
    }

    getDashboardData(): boolean {
        // Customizable Area Start

        // Customizable Area End
        return true;
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
        
            let webResponseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
        
        this.handleCardDetails(webResponseJson,webApiRequestCallId)
        this.handlePaypalApiDetailsRes(webResponseJson,webApiRequestCallId)
        this.handleCardDetailsRes(webResponseJson,webApiRequestCallId)
        if (webResponseJson) {
            if (webApiRequestCallId === this.userCallID) {
                this.setState({
                    userDetails: webResponseJson?.current_user
                })
            } 
        }
        this.handlePaypalDataRes(webResponseJson, webApiRequestCallId)
        this.handleDeleteResponse(webResponseJson, webApiRequestCallId)
        this.handleDeletePaymentCardResponse(webResponseJson, webApiRequestCallId)
          }
        // Customizable Area End
    }
    // Customizable Area Start 
    handleCloseBillingModal =()=>{
        this.setState({ openBillingModal: false });
    }

    handleCardDetails = (webResponseJson: any,webApiRequestCallId: string ) => {
        if (webResponseJson) {
            if (webApiRequestCallId === this.cardFetailsApiCallId) {
              if(webResponseJson)
                this.setState({
                  cardDetailsData: webResponseJson?.data?.attributes
                })
            
          this.cardDetailsData()
            } 
        }
    }

    handlePaypalApiDetailsRes = (webResponseJson: any,webApiRequestCallId: string ) => {
        if (webResponseJson) {
            if (webApiRequestCallId === this.paypalApiCallDetailsId) {
            if (webResponseJson?.error) {
                this.setState({
                    isDialogOpen: true,
                    errorMessage: webResponseJson?.error,
                    openBillingModal: false,
                    successMessage:'',
                    openPaypalModal: false,
                    paypalEmail:''
                })
                }
            if (webResponseJson?.data) {
            this.setState({
                isDialogOpen: true,
                successMessage: 'Payment method added successfully',
                openBillingModal: false,
                errorMessage: '',
                openPaypalModal: false,
                paypalEmail:''
            })
            this.getPaypalData()
            }
            } 
        }
    }

    handleCardDetailsRes = (webResponseJson: any,webApiRequestCallId: string ) => {
        if (webResponseJson) {
            if (webApiRequestCallId === this.cardPaymentId) {
            if (webResponseJson?.error) {
                this.setState({
                    openPaymentCardModal: false,
                    isCardDialogOpen: true,
                    errorMessage: webResponseJson?.error,
                   successMessage: '',
                })
                }
            if (webResponseJson?.data) {
            this.setState({
                openBillingModal: false
            })
            this.getCardDetailsCall()
            }
            } 
        }
    }

    handleDeletePaymentCardResponse=(webResponseJson: any, webApiRequestCallId: string)=>{
      if (webResponseJson) {
          if (webApiRequestCallId === this.paymentCardDeleteCallId) {
              if (webResponseJson?.message) {
              this.setState({
                  deletePaypalDetails: webResponseJson?.message,
                  isDeleteModal: true,

              })
              this.getCardDetailsCall()
          }
          if (webResponseJson?.error) {
              this.setState({
                  deletePaypalDetails: webResponseJson?.error,
                  isDeleteModal: true
              })}
          } 
      }
  }


  handlePaypalDataRes=(webResponseJson: any, webApiRequestCallId: string)=>{
    if (webResponseJson) {
        if (webApiRequestCallId === this.paypalDataID) {
            if (webResponseJson?.data) {
              this.setState({
                paypalDataVisible: true,
                paypalData: webResponseJson?.data
              })
                  }
        } 
    }
}
    handleDeleteResponse=(webResponseJson: any, webApiRequestCallId: string)=>{
        if (webResponseJson) {
            if (webApiRequestCallId === this.paypalDeleteCallId) {
                if (webResponseJson?.message) {
                this.setState({
                    deletePaypalDetails: webResponseJson?.message,
                    isDeleteModal: true,
                    paypalDataVisible: false
                })
                this.getPaypalData()
            }
            if (webResponseJson?.error) {
                this.setState({
                    deletePaypalDetails: webResponseJson?.error,
                    isDeleteModal: true
                })}
            } 
        }
    }
    handleCloseDeleteDialog = () => {
        this.setState({
            isDeleteModal: false
        })
    }

    handleCardToken = (e: { target: { value: string; }; }) => {
      const value = e.target.value.replace(/\s+/g, ""); 
      const { errors } = this.state;
    
      const updatedErrors = { ...errors };
  
      if (!value) {
        updatedErrors.cardNumber = "Card number cannot be empty.";
      } else if (value.length <16 ) {
        updatedErrors.cardNumber = "Please enter atleast 16 digit";
      } else {
        updatedErrors.cardNumber = "";
      }
    
      this.setState({
        cardNumber: value,
        errors: updatedErrors,
      });
    };
    
   
    handleHolderName = (e: any) => {
        const value = e.target.value;
        const { errors } = this.state;
    
        this.setState({ cardHolderName: value });

        if (!value.trim()) {
            this.setState({
                errors: {
                    ...errors,
                    cardHolderName: "Please enter card holder name."
                }
            });
        } else if (!/^[a-zA-Z\s]+$/.test(value)) {
            this.setState({
                errors: {
                    ...errors,
                    cardHolderName: "Card holder name must only contain letters and spaces."
                }
            });
        } else {
            this.setState({
                errors: {
                    ...errors,
                    cardHolderName: ""
                }
            });
        }
    };
    
    handlevalidDate = (e: any) => {
      const value = e.target.value;
      const { errors } = this.state;
  
      // Allow only numbers and "/" for MM/YYYY format
      if (/^\d{0,2}(\/\d{0,4})?$/.test(value)) {
          let formattedValue = value;
  
          // Automatically add "/" after the month if needed
          if (value.length === 2 && !value.includes("/")) {
              formattedValue = `${value}/`;
          }
  
          // Update the validDate state
          this.setState({ validDate: formattedValue });
  
          // Validate if the value matches the MM/YYYY format
          if (!/^\d{2}\/\d{4}$/.test(formattedValue)) {
              this.setState({
                  errors: {
                      ...errors,
                      validDate: "Valid date must be in MM/YYYY format.",
                  },
              });
          } else {
              const [month, year] = formattedValue.split("/").map(Number);
              const currentYear = new Date().getFullYear();
              const currentMonth = new Date().getMonth() + 1;
  
              // Validate if the date is valid and not expired
              if (
                  month < 1 ||
                  month > 12 ||
                  year < currentYear ||
                  (year === currentYear && month < currentMonth)
              ) {
                  this.setState({
                      errors: {
                          ...errors,
                          validDate: "Valid date is invalid or expired.",
                      },
                  });
              } else {
                  this.setState({
                      errors: {
                          ...errors,
                          validDate: "", // Clear the error message
                      },
                  });
              }
          }
      }
  };
  
  
  
    handleCVV = (e: any) => {
      const value = e.target.value;
      const { errors } = this.state;
  
      if (/^\d*$/.test(value)) {
          this.setState({ cvvNumber: value });
  
          // Validation for CVV
          if (!value.trim()) {
              this.setState({
                  errors: {
                      ...errors,
                      cvvNumber: "Please enter CVV."
                  }
              });
          } else if (!/^\d{3,4}$/.test(value)) {
              this.setState({
                  errors: {
                      ...errors,
                      cvvNumber: "CVV must be 3 or 4 digits."
                  }
              });
          } else {
                   this.setState({
                  errors: {
                      ...errors,
                      cvvNumber: ""
                  }
              });
          }
      }
  };
    
          
    redirectPaypalApiCall = () => {
      const currentProposalsDetails = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        token: this.state.authToken,
        "Content-Type": "application/json", 
      };
      const body = {
        payment_type: "paypal",
      };
    
      this.redirectPaypalApiCallDetailsId = currentProposalsDetails.messageId;
    
      currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/paypal_client_to_admin?milestone_id=168`
      );
    
      currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body) 
      );
    
      currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
    };
    
    handlePaymentChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        
        this.setState({
            selectedValue: value,
            showPayPalSection: value === 'PayPal' || this.state.showPayPalSection,
            showAddPaymentCard: value === 'PaymentCard' || this.state.showAddPaymentCard,
        });
    };
    
    handleClosePaymentCardModal = ()=>{
        this.setState({ openPaymentCardModal: false,
          openPaypalModal: false,
          openBillingModal: false,
          paypalEmail:''
         });
    }


    cardDetailsData = () => {
      const { last4, exp_month, exp_year, cardholder_name, card_token } = this.state.cardDetailsData;
      
      const formattedDate = `${exp_month < 10 ? '0' + exp_month : exp_month}/${exp_year}`;
  
      this.setState({
          cvvNumber: last4,
          validDate: formattedDate, // Use the combined date in MM/YYYY format
          validYear: exp_month, // Keep the individual month
          cardHolderName: cardholder_name,
          cardNumber: card_token,
      });
  };
  

  handleDeletePaymentCard = async () => {     
     
    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );

      const body = {
        payment_type: "card",
      };
      
      const header = {
          "Content-Type": configJSON.dashboarContentType,
          token: this.state.authToken
      };
      this.paymentCardDeleteCallId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    `bx_block_stripe_integration/destroy_payment_method`
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body) 
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   "DELETE"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

getPaypalData = async () => {    
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: this.state.authToken
    };
    this.paypalDataID = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  'bx_block_stripe_integration/get_paypal_payment_method?payment_type=paypal'
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
 "GET"
);

runEngine.sendMessage(requestMessage.id, requestMessage);
};



    paypalApiCall = () => {
      const currentProposalsDetails = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
    
      const header = {
        token: this.state.authToken,
        "Content-Type": "application/json", 
      };
    
      const body = {
        payment_type: "paypal",
        email: this.state.paypalEmail
      };
    
      this.paypalApiCallDetailsId = currentProposalsDetails.messageId;
    
      currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/add_paypal_payment_method`
      );
    
      currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
    
      currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body) 
      );
    
      currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
    
      runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
    };


   

      getCardDetailsCall = () => {
        const currentProposalsDetails = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        const header = {
          token: this.state.authToken,
          "Content-Type": "application/json", 
        };
      
      
        this.cardFetailsApiCallId = currentProposalsDetails.messageId;
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_stripe_integration/get_payment_method?payment_type=card`
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );

        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "GET"
        );
      
        runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
      };
      handlePaypalEmail = (event:any)=>{
        this.setState({ paypalEmail: event.target.value, paypalEmailError:""});
      };
      
      validateEmail = () => {
        const { paypalEmail } = this.state;
        if (!paypalEmail) {
          this.setState({ paypalEmailError:
             "Please enter your email id"})
          return false;
        }
      
        const emailRegex = /^(?:[a-zA-Z0-9._%+-]+)@(?:[a-zA-Z0-9.-]+\.[a-zA-Z]{2,})$/;

        if (!emailRegex.test(paypalEmail)) {
          this.setState({paypalEmailError: "Please enter a valid email address" });
          return false;
        }
      
        return true;
      };
      
      handleSavePaymentCardModal = () => {
        if (this.validateEmail()) {
          this.paypalApiCall();
        }
      };
      
      handleNextButton = () => {
        const { selectedValue } = this.state;
    
        if (selectedValue === 'PayPal') {
            this.setState({ showPayPalSection: true,
              openPaypalModal: true,
              openBillingModal: false
             });
        } else if (selectedValue === 'PaymentCard') {
            this.setState({ 
                openPaymentCardModal: true,
                openBillingModal: false,
            }, () => {
                this.handlePaymentCardSaveBtn(); 
                this.getCardDetailsCall()
            });
        }
    };
    
    handleBillingMethod = ()=>{
        this.setState({ openBillingModal: true });
    }

    formatCardToken = (token: string | any[]) => {
      if (!token) return '';
  
      const cardNumber = token.slice(5);
      const formattedToken = `${cardNumber.slice(0, 4)}**** ***** ****${cardNumber.slice(-3)}`;
  
      return formattedToken;
  }
  

    handleOkay = () => {
        this.setState({ 
            isDialogOpen: false,
         });
    }

    handleCloseDialog = () => {
        this.setState({ isDialogOpen: false });
      };

      handleCloseCardDialog = () => {
        this.setState({
            isCardDialogOpen: false,
            openPaymentCardModal: false,
            openBillingModal: false
        })
      }

    handleCloseSuccessPopup = () =>{
        this.setState({ paymentSuccessPopup: false, showDetails: true})
    }

    handleDeletePaypalAccount = async () => {     
     
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );

          const body = {
            payment_type: "paypal",
          };
          
          const header = {
              "Content-Type": configJSON.dashboarContentType,
              token: this.state.authToken
          };
          this.paypalDeleteCallId = requestMessage.messageId;
 
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/delete_paypal_account_from_db?id=${this.state.paypalData?.id}`
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body) 
      );
    
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
       "DELETE"
      );
    
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };


    handleDeleteMethod = (method: string) => {
        if (method === "PayPal") {
          this.setState({ showPayPalSection: false });
          this.handleDeletePaypalAccount()
        } else if (method === "PaymentCard") {
          this.setState({ showAddPaymentCard: false });
        }
      };

          
    handlePaymentCardSaveBtn = async () => {
       
      const updateProfileImage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      const header = {
        token: this.state.authToken,
      };
    
      const formdata = new FormData();
      formdata.append("card_token", "tok_mastercard");
      formdata.append("payment_type", "card");
    
      this.cardPaymentId = updateProfileImage.messageId;
      updateProfileImage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_stripe_integration/add_payment_method`
      );
      updateProfileImage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      updateProfileImage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      updateProfileImage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(updateProfileImage.id, updateProfileImage);
    };




getUserData = async () => {

          
  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    
    const header = {
        "Content-Type": configJSON.dashboarContentType,
        token: this.state.authToken
    };
    this.userCallID = requestMessage.messageId;

requestMessage.addData(
  getName(MessageEnum.RestAPIResponceEndPointMessage),
  'account_block/accounts/get_client_profile_online_status'
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestHeaderMessage),
  JSON.stringify(header)
);

requestMessage.addData(
  getName(MessageEnum.RestAPIRequestMethodMessage),
 "GET"
);

runEngine.sendMessage(requestMessage.id, requestMessage);
};


        // Customizable Area End
}