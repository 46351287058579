import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { countries } from "countries-list";
import storage from "../../../framework/src/StorageProvider";
import React from "react";
interface APIPayloadType {
  contentType?: string;
  method: string;
  endPoint: string;
  body?: object;
  token?: string;
}
 interface UserData 
  {[key:string]: {[key:string]: string | {[key:string]: string | number | {[key:string]: string | number | null | {[key:string]:null}}}}}

interface Resprofile {
  [key:string]: number | string | null | boolean | {[key:string]:null}
}

  interface Responseinterface{
    data: {
        id: string;
        type: string;
        attributes: {
            profile :Resprofile;
        }
    }
}

  // Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  editModalOpen: boolean;
  phoneError: string;
  firstNameError: string;
  lastNameError: string;
  cityError:string;
  aboutMeError: string;
  jobTitleError:string;
  addressError: string;
  aptSuiteError: string;
  imageModalOpen: boolean;
  address:any;
  phone_number:any;
  userDetails: {
    job_description: any;
    additional_address: any;
    apt_suite: any;
    dob: any;
    job_title: any;
    state: any;
    city: any;
    id: number,
    email: string,
    first_name: string;
    last_name: string;
    user_name: string,
    full_phone_number: string,
    zipcode: string;
    country_code: string;
    country: string;
    address: string,
    about_me: string,
    user_status: boolean;
    user_profile: {
      url: null
    },
  };
  authToken: "",
  userData1: Resprofile;
  profileData: any;
  userProfileData: any;
  showUserProfileData: any;
  stateList: any;
  countryList: {
    id: number,
    name: string,
    code: string,
}[],
  responseMessage: boolean;
  popUpMessage: boolean;
  accountHolderName: string;
  profileImage: any;
  selectedFile:any;
  imageUploadError: string;
  zipCodeError: string;
  fileInputRef:any,
  isFormUpdated: boolean

  // Customizable Area End
}
interface SS { }

export default class ProfileController extends BlockComponent<Props, S, SS> {
  // Customizable Area Star
  emailReg = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  addessReg= configJSON.addressRegs;
  phoneReg = configJSON.phoneReg;
  zipReg = /^\d{5,6}$/;
  nameReg= configJSON.nameReg;
  displayProfileDetailsID: string="";
  stateListID: string = "";
  countryListID: string = "";
  updateProfileImageId: string = "";
  updateUserProfileID: string = "";
  userProfileDetailsID: string = "";
  userViewProfileDetailsID: string = "";
  allCountries = Object.values(countries);
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    // this.displayProfileDetailsID = "";
    this.updateUserProfileID = ""
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.RestAPIRequestMessage)
    ];

    this.state = {
      editModalOpen: false,
      imageModalOpen: false,
      phoneError: '',
      firstNameError: '',
      lastNameError: '',
      cityError:'',
      jobTitleError: '',
      aboutMeError:'',
      addressError: '',
      aptSuiteError: '',
      userData1:{},
      authToken: "",
      profileData: [],
      userProfileData: [],
      showUserProfileData: [],
      isFormUpdated: false,
      userDetails: {
        id: 0,
        email: "",
        first_name: "",
        last_name: "",
        user_name: "",
        full_phone_number: "",
        zipcode: "",
        country_code: '',
        country: '',
        address: "",
        about_me: "",
        job_description: '',
        additional_address: '',
        apt_suite: '',
        dob: '',
        job_title: '',
        state: '',
        city: '',
        user_status: false,
        user_profile: {
          url: null
        },
        
      },
      responseMessage: false,
      stateList: [],
      countryList: [],
      address:"",
      phone_number:"",
      popUpMessage: false,
      accountHolderName:'',
      profileImage: '',
      selectedFile:"",
      imageUploadError: '',
      zipCodeError: "",
      fileInputRef:React.createRef(),
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      accountHolderName:await storage.get("accountHolderName"),
      authToken: await storage.get('authToken'),
    })
    this.getUserProfileDetails()
    this.getUserViewProfileDetails()
    this.getUserDetails()
    this.getCountriesList()
    this.getStateList(this.state.userProfileData.current_user?.nation_id)
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      ) 
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
        if (this.isValidResponse(responseJson)) {
          this.apiSucessCall(requestCallId, responseJson);
        }
        if (this.isValidResponse(responseJson)) {
          this.userProfileDetailsSucessRes(requestCallId, responseJson);
        }
       this.handleSuccessResponse(responseJson, requestCallId )
        if (this.updateUserProfileID === requestCallId) {
          const responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
          );
          if (responseJson?.data) {
            this.setState({
              responseMessage: true,
              popUpMessage: true,
              editModalOpen: false,
              isFormUpdated: false
            },()=>this.getUserViewProfileDetails())
          } else {
            this.setState({
              responseMessage: false,
              popUpMessage: true
            })
          }
        }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  isValidResponse = (responseJson:Responseinterface) => {
    return (responseJson );
  };

  componentDidUpdate(prevProps:any, prevState: any) {
    const currentNationId = this.state.userProfileData.current_user?.nation_id;
    if (currentNationId && currentNationId !== prevState.userProfileData.current_user?.nation_id) {
        this.getStateList(currentNationId);
      }
}

  handleSuccessResponse = (responseJson: any, requestCallId: string | any) => {
    if (this.isValidResponse(responseJson)) {
      this.stateListSucessRes(requestCallId, responseJson);
    }
    if (this.isValidResponse(responseJson)) {
      this.countryListSucessRes(requestCallId, responseJson);
    }
    if(this.updateProfileImageId === requestCallId ){
      this.setState({
        profileImage: this.state.userProfileData.current_user.profile_image,
      }, ()=>
        this.getUserViewProfileDetails());
    }
  }

  apiSucessCall = async (apiRequestCallId: string, responseJson:Responseinterface ) => {
    if (apiRequestCallId === this.displayProfileDetailsID) {   
    this.setState({profileData:responseJson?.data})
      this.getSkillApiSuccessCallBack(responseJson);
    }
  };

  userProfileDetailsSucessRes = async (apiRequestCallId: string, responseJson:any ) => {
    if (apiRequestCallId === this.userProfileDetailsID) {   
    this.setState({userProfileData:responseJson})
    }
    if(apiRequestCallId === this.userViewProfileDetailsID) {
    this.setState({showUserProfileData: responseJson })
    }
  };

  stateListSucessRes = async (apiRequestCallId: string, responseJson:any ) => {
    if (apiRequestCallId === this.stateListID) {   
      const states = responseJson.map((state: any) => ({
        id: state.id,
        name: state.name,
      }));
      this.setState({ stateList: states});
    }
  };

  countryListSucessRes = async (apiRequestCallId: string, responseJson:any ) => {
    if (apiRequestCallId === this.countryListID) {   
      if(responseJson){
        const countries = responseJson.map((country: any) => ({
          id: country.id,
          name: country.name,
          code: country.dialing_code,
        }));

        this.setState({ countryList: countries });
      }
    }
  };
  
  getSkillApiSuccessCallBack= (responseJson: Responseinterface)=>{
    this.setState({userData1:responseJson?.data?.attributes?.profile})
  }

  handleModal = () => {
    this.setState({editModalOpen: true});
};
  handleClose = () => {
    this.setState({editModalOpen: false,
      isFormUpdated: false
    });
    this.getUserProfileDetails()
  }

  handleProfileImageModal = () => {
    this.setState((prevState) => ({ imageModalOpen: !prevState.imageModalOpen }));
  };
  

  handleModal2 = () => {
  this.props.navigation.navigate('/')
  }
 
  handleDialog = () => {
    this.setState({
      popUpMessage: !this.state.popUpMessage
    })
  }
  handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    
    const errorKey = `${name}Error`;
    let errorMessage = '';
  
    const trimmedValue = typeof value === 'string' ? value.trim() : '';
  
    if (!trimmedValue) {
      errorMessage = this.validateField(name, trimmedValue);
    } else {
      errorMessage = '';
    }
  
    this.setState((prevState) => ({
      ...prevState,
      userProfileData: {
        ...prevState.userProfileData,
        current_user: {
          ...prevState.userProfileData.current_user,
          [name]: value,
        },
      },
      isFormUpdated: true, 
      [errorKey]: errorMessage,
    }));
  };
  
  handleRedirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };
  

  preventETypeNumber = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const allowedKeys = [
      "Backspace",
      "ArrowLeft",
      "ArrowRight",
      "Tab",
      "Shift"
    ];
    return (
      (allowedKeys.includes(event.key) ? false : !/^[^A-Za-z\W_]+$/.test(event.key)) &&
      event.preventDefault()
    );
  };

  isFutureDate = (dob: string | number | Date) => {
    if (!dob) return false;

    const inputDate = new Date(typeof dob === "string" ? dob + "T00:00:00" : dob);
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return inputDate > today;
};

  
  apiCallSurvey = async (data: APIPayloadType) => {
    const { contentType, method, endPoint, body } = data;
    const header = {
      "Content-Type": contentType,
      token : this.state.authToken
    };
    this.setState({ editModalOpen: false }) 
    const requestMessageapiCall = new Message(getName(MessageEnum.RestAPIRequestMessage));
    requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
    requestMessageapiCall.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    body &&
      requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );
    requestMessageapiCall.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    runEngine.sendMessage(requestMessageapiCall.id, requestMessageapiCall);
    return requestMessageapiCall.messageId;
  };

  validateField = (name: string, value: any) => {
    if (typeof value !== 'string') {
      value = value?.toString() || ''; 
    }
  
    if (!value.trim()) {
      switch (name) {
        case 'phone_number':
          return 'Please enter phone number';
        case 'first_name':
          return 'Please enter first name.';
        case 'last_name':
          return 'Please enter last name.';
        case 'city':
          return 'Please enter city.';
        case 'address':
          return 'Please enter address.';
        case 'apt_suite':
          return 'Please enter  Apt/Suite.';
          case 'job_description':
          return 'Please enter Job description.';
          case 'job_title':
            return 'Please enter Job title.';
        case 'zipcode':
          return 'Please enter zip code.';
        default:
          return '';
      }
    }
  

    switch (name) {
      case 'phone_number':
        return this.phoneReg.test(value) ? '' : 'Please enter a valid phone number';
        case 'zipcode':
          return /^\d{5,6}$/.test(value) ? '' : 'Zip/postal code must be 5 or 6 digits';      
      default:
        return '';
    }
  };
  
  
  
  handleUpdate = async() => {
    const errors:any = {};

    errors.firstNameError = this.validateField('first_name', this.state.userProfileData.current_user?.first_name);
    errors.lastNameError = this.validateField('last_name', this.state.userProfileData.current_user?.last_name);
    errors.phoneError = this.validateField('phone_number', this.state.userProfileData.current_user?.phone_number);
    errors.cityError = this.validateField('city', this.state.userProfileData.current_user?.city);
    errors.aptSuiteError = this.validateField('apt_suite', this.state.userProfileData.current_user?.apt_suite);
    errors.addressError = this.validateField('address', this.state.userProfileData.current_user?.address);
    errors.zipCodeError = this.validateField('zipcode', this.state.userProfileData.current_user?.zipcode);
    errors.jobTitleError = this.validateField('job_title', this.state.userProfileData.current_user?.job_title);
    errors.aboutMeError = this.validateField('job_description', this.state.userProfileData.current_user?.job_description);

    this.setState((prevState) => ({
      ...prevState,
      ...errors,
    }));
  
    if (Object.values(errors).some((error) => error)) return;

    this.state.countryList
      .filter(data => data.name === this.state.userDetails.country)
      .forEach(item => this.setState({userProfileData:{...this.state.userProfileData}}))
      const formData = new FormData();
 formData.append("account[zipcode]", this.state.userProfileData.current_user?.zipcode)
 formData.append("account[email]", this.state.userProfileData.current_user?.email)
 formData.append("account[first_name]", this.state.userProfileData.current_user?.first_name)
 formData.append("account[last_name]", this.state.userProfileData.current_user?.last_name)
 formData.append("account[dob]", this.state.userProfileData.current_user?.dob)
 formData.append("account[address]", this.state.userProfileData.current_user?.address)
 formData.append("account[additional_address]", this.state.userProfileData.current_user?.additional_address)
 formData.append("account[apt_suite]", this.state.userProfileData.current_user?.apt_suite)
 formData.append("account[city]", this.state.userProfileData.current_user?.city)
 formData.append("account[nation_id]", this.state.userProfileData.current_user?.nation_id)
 formData.append("account[state_id]", this.state.userProfileData.current_user?.state_id)
 formData.append("account[phone_number]", this.state.userProfileData.current_user?.phone_number)
 formData.append("account[job_title]", this.state.userProfileData.current_user?.job_title)
 formData.append("account[job_description]", this.state.userProfileData.current_user?.job_description)
  formData.append("account[profile_image]", this.state.selectedFile || this.state.userProfileData.current_user?.profile_image)

  const header = {
    token : this.state.authToken
  };
  const requestMessageapiCall = new Message(getName(MessageEnum.RestAPIRequestMessage));
  this.updateUserProfileID = requestMessageapiCall.messageId
 
  requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));
  requestMessageapiCall.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'PATCH'
  );
    requestMessageapiCall.addData(getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
  requestMessageapiCall.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'account_block/accounts/client_profile_basic_details'
  );
  runEngine.sendMessage(requestMessageapiCall.id, requestMessageapiCall);
  this.getUserViewProfileDetails()
  return requestMessageapiCall.messageId;
  }

  getUserDetails = async() => {
    const accountId = await storage.get("accountId")
      this.displayProfileDetailsID = await this.apiCallSurvey({
        contentType: configJSON.skillApiContentType,
        method: configJSON.httpGetMethod,
         endPoint: `account_block/accounts/${accountId}/show_client_profile`
      }
      ); 
  }

getStateList = async (value: any) => {

  const header = {
      "Content-Type": configJSON.validationApiContentType,
      token : this.state.authToken
  };

  const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
  );

  this.stateListID = requestMessage.messageId;

  requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/nations/get_states?id=${this.state.userProfileData.current_user?.nation_id}`
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
  );

  requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

getCountriesList=async()=>{
  const header = {
    "Content-Type" :configJSON.validationApiContentType,
     token:this.state.authToken
  }
  const requestMessage=new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  )
  this.countryListID=requestMessage.messageId
  
  requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage),`bx_block_posts/nations`)
  requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
  requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
  runEngine.sendMessage(requestMessage.id, requestMessage);
  return true;
};

  getUserProfileDetails = async() => {
      this.userProfileDetailsID = await this.apiCallSurvey({
        contentType: configJSON.skillApiContentType,
        method: configJSON.httpGetMethod,
         endPoint: configJSON.updateOnlineStatus
      }
      ); 
  }

  getUserViewProfileDetails = async() => {
    this.userViewProfileDetailsID = await this.apiCallSurvey({
      contentType: configJSON.skillApiContentType,
      method: configJSON.httpGetMethod,
       endPoint: configJSON.updateOnlineStatus
    }
    ); 
}

  handleImageUpdate = () => {
    this.handleUpdateProfileImage()
    this.handleProfileImageModal()
    this.getUserProfileDetails()
    this.getUserViewProfileDetails()
  }

  handleUpdateProfileImage = async () => {
    const userID = this.state.userProfileData.current_user?.id
    const updateProfileImage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      token : this.state.authToken
    };
    const formdata = new FormData();
    
    formdata.append("[data][attributes][profile_image]", this.state.selectedFile);
    
    this.updateProfileImageId = updateProfileImage.messageId;
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/accounts/${userID}/update_client_profile_image`
    );
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    )
    updateProfileImage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.updateUserMethodeType
    );
    runEngine.sendMessage(updateProfileImage.id, updateProfileImage);
  }

  handleEditClick = () => {
    const fileInput = this.state.fileInputRef.current;
    if (fileInput) {
      fileInput.click()
    }
  this.handleUpdateProfileImage()
  }

  handleFileChange =(event :any)=>{
    const fileI = event.target.files[0]
    if (fileI){
      const allowedTypes=['image/jpeg', 'image/png', 'image/jpg']
      if(allowedTypes.includes(fileI.type)){
        this.setState({ profileImage: URL.createObjectURL(fileI), selectedFile: fileI, imageUploadError: '' });
      }else{
        const imageUploadError = 'Profile image: You are not allowed to upload "' + fileI.type + '" files, allowed types: jpg, jpeg, png';
        this.setState({ imageUploadError })
      }}}
  
  // Customizable Area End
}
