// Customizable Area Start
import { IBlock } from 'framework/src/IBlock';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';
// Customizable Area Start
import { Message } from "../../../framework/src/Message";
import storage from 'framework/src/StorageProvider.web';
// Customizable Area End
export const webConfigJSON = require('./config.js');


export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  activeButton: string;
  selectedTab: number | string;
  services: any;
  authToken: string;
  projectDetailsData: any;
  projectDetailsErr:string
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class ProjectDetailsControllerClient extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  projectDetailsId:string=""
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      activeButton: "offers",
      selectedTab: 0,
      projectDetailsErr:"",
      projectDetailsData:[],
      authToken: "",
      services: [
        "Provide legal advice on intellectual property matters",
        "Assist in filing and managing trademarks, copyrights, and patents",
        "Draft, review, and negotiate IP-related agreements and contracts",
        "Conduct IP audits and provide recommendations for IP protection strategies",
        "Handle IP infringement cases and disputes"
      ],   
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();  
    
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken'),
    })
    const paramId = this.props.navigation.getParam("id");   
    if (paramId) {
      await this.getProjectDetails(paramId); 
    }
    // Customizable Area End
  }

  // Customizable Area Start
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.NavigationPayLoadMessage)) {
      // return;
    }
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.handleProjectDetailsRes(webResponseJson, webApiRequestCallId)     
    }
    // Customizable Area End
  }
  // Customizable Area End

  // Customizable Area Start
 

  handleProjectDetailsRes = (webResponseJson: { data: any; error: string } | undefined, webApiRequestCallId: string | undefined) => {  
   
    if (webResponseJson) {
      if (webApiRequestCallId === this.projectDetailsId) {
        if (webResponseJson?.data) {          
          this.setState({ projectDetailsData: webResponseJson?.data, projectDetailsErr: "" })
        }  
        if (webResponseJson?.error) {
          this.setState({ projectDetailsErr: webResponseJson?.error });
        }
      }
    }
  }
  
  getProjectDetails = async (proposalGenerationId: number) => { 
    
    const paramId = this.props.navigation.getParam("id");
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
    };        
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.projectDetailsId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_contract/contracts/client_side_project_details?proposal_generation_id=${paramId}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getStatusColor = (status: string) => {
    switch (status) {
      case "ongoing":
        return "#364BA0";
      case "completed":
        return "#348306";
      case "pending":
        return "#F8B225"
      case "accept":
        return "#364BA0";
      case "rejected":
        return "red";
      case "approved":
        return "#348306";
      default:
        return "#515354";
    }
  };
  // Customizable Area End
}
// Customizable Area End

