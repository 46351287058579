import { Typography,styled } from '@material-ui/core';

export const TypographyView = styled(Typography)({
  
  '& .projectDetailsBorder': {
    width: '100%',
    // minWidth:"1200px"  
  },


  // Media query for 1024px
  '@media (max-width: 1024px)': {
    '& .container': {
      width: '90%', 
    },
  },

  // Media query for 768px
  '@media (max-width: 768px)': {
    '& .container': {
      width: '85%', 
    },
    '& .customTabs': {
      '& .MuiTabs-root': {
        overflowX: 'auto',
      },
      '& .MuiTab-root': {
        minWidth: 'auto',
      },
    },
    '& .buttonPlace':{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center"
    },
    '& .endContactButton':{
        display:"flex",
        marginTop:"15px",
        justifyContent:"center"
    },
    '& .projectDetailsBorder': {
        width: '50%',  
   },
  },

  // Media query for 450px
  '@media (max-width: 450px)': {
    '& .container': {
      width: '80%', 
    },
    '& .buttonPlace':{
        display:"flex",
        flexDirection:"column",
        justifyContent:"center"
    },
    '& .endContactButton':{
        display:"flex",
        marginTop:"15px",
        justifyContent:"center"
    },
    '& .projectDetailsBorder': {
      width: '50%',  
 },
  },
});

