import React from "react";
// Customizable Area Start
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, Grid, IconButton, MenuItem, Modal, TextField, Typography } from "@material-ui/core";
import { createTheme, styled, ThemeProvider } from "@material-ui/core/styles";
import { pencilIcon, editIcon, profileImageIcon } from './assets';
import LocationOnRoundedIcon from '@material-ui/icons/LocationOnRounded';
import CancelIcon from '@material-ui/icons/Cancel';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

const theme = createTheme({
    palette: {
        primary: {
            main: "#206FC4",
        },
        secondary: {
            main: "rgb(96, 96, 96)",
        }
    },
    typography: {
        fontFamily: 'Inter',
    }
});
// Customizable Area End
import ProfileController, {
    Props,
    configJSON
} from "./ProfileController";

export default class Profile extends ProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderModal = () => {
        return (
            <ThemeProvider theme={theme}>
                 <PaymentCardDialog 
                open={this.state.editModalOpen}
                onClose={this.handleClose}
                 maxWidth="sm" fullWidth className="radius-class" >

                <DialogContent style={{padding:"24px"}}>
                <Grid container>
                            <Grid item sm={6} md={12}>
                                <Box style={webStyles.editContainer}>
                                    <Typography style={{ ...webStyles.editText, fontSize: '16px' }}
                                    >{configJSON.editDetails}</Typography>
                                    <IconButton aria-label="close" onClick={this.handleClose} >
                                        <CloseIcon style={{
                                            color: 'black'
                                        }}/>
                                    </IconButton>

                                </Box>
                                <Box style={{
                                    padding: '0px 4px 0px 15px'
                                }}>
                                <hr style={webStyles.Hr} />
                                </Box>
                                <Box style={webStyles.modalField}>
                                    <Grid container>
                                        <Grid item sm={6} md={12}>
                                        <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.firstName}*</Typography>
                                                <TextField
                                                className="textField"
                                                variant="outlined"
                                                name="first_name"
                                                data-testid="first-name-field"
                                                placeholder="Enter first name"
                                                InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                fullWidth
                                                inputProps={{ maxLength: 36 }}
                                                value={this.state.userProfileData.current_user?.first_name}
                                                onChange={this.handleChange}
                                                error={!!this.state.firstNameError} 
                                                helperText={this.state.firstNameError}
                                                />

                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.lastName}*</Typography>
                                               
                                                <TextField
                                                className="textField"
                                                    variant="outlined"
                                                     name="last_name"
                                                    data-testid="name-field"
                                                    placeholder="Enter last name"
                                                    // size="small"
                                                    InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    fullWidth
                                                    inputProps={{ maxLength: 36 }}
                                                    value={this.state.userProfileData.current_user?.last_name}
                                                    onChange={this.handleChange}
                                                    error={!!this.state.lastNameError}
                                                    helperText={this.state.lastNameError} 
                                                />
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.email}*</Typography>
                                                <TextField
                                                className="textField"
                                                    type="email"
                                                    name="email"
                                                    data-testid="email-field"
                                                    value={this.state.userProfileData.current_user?.email}
                                                    variant="outlined"
                                                    placeholder="enter email address"
                                                    InputProps={{ 
                                                        readOnly: true,
                                                        style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    fullWidth
                                                    onChange={this.handleChange}                               
                                                />
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.address}*</Typography>
                                                <TextField
                                                className="textField"
                                                    name="address"
                                                    value={this.state.userProfileData.current_user?.address}
                                                    data-testid="address-field"
                                                    variant="outlined"
                                                    placeholder="enter address"
                                                    // size="small"
                                                    maxRows={4}
                                                    onChange={this.handleChange}
                                                    inputProps={{ maxLength: 300 }}
                                                    fullWidth
                                                    error={!!this.state.addressError}
                                                    helperText={this.state.addressError} 
                                                    InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C'} }}
                                                />
                                            </Box>
                                            <Box className="countryPhone" style={{ width: '100%'}}>
                                                <Box style={{...webStyles.fieldBox, width: '50%'}}>
                                                    <Typography style={webStyles.label}>{configJSON.country}*</Typography>
                                                    <TextField
                                                className="textField"
                                                        name="nation_id"
                                                        data-testid="country-code"
                                                        value={this.state.userProfileData.current_user?.nation_id}
                                                        variant="outlined"
                                                        select
                                                        // size="small"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        style={webStyles.countryField}
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    >
                                                        {this.state.countryList.map((country:any) =>
                                                            <MenuItem value={country.id} key={country.name}>{country.name}({country.code})</MenuItem>
                                                        )}
                                                    </TextField>
                                                </Box>
                                                <Box style={{...webStyles.fieldBox, width: '50%'}}>
                                                    <Typography style={webStyles.label}>{configJSON.phone}*</Typography>
                                                    <TextField
                                                        name="phone_number"
                                                        onKeyDown={this.preventETypeNumber}
                                                        data-testid="phone-number"
                                                        value={this.state.userProfileData.current_user?.phone_number}
                                                        variant="outlined"
                                                        placeholder="Phone Number"
                                                        fullWidth
                                                        inputProps={{ maxLength: 10 }}
                                                        onChange={this.handleChange}
                                                        error={!!this.state.phoneError}
                                                        helperText={this.state.phoneError} 
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    />
                                                </Box>
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>{configJSON.aboutMe}*</Typography>
                                                <TextField
                                                className="textField"
                                                     name="job_description"
                                                    data-testid="about-me"
                                                    value={this.state.userProfileData.current_user?.job_description}
                                                    variant="outlined"
                                                    fullWidth
                                                    InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    multiline
                                                    minRows={4}
                                                    inputProps={{ maxLength: 150 }}
                                                    onChange={this.handleChange}
                                                    error={!!this.state.aboutMeError}
                                                    helperText={this.state.aboutMeError}
                                                />
                                            </Box>
                                            <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>ZIP/Postal code</Typography>
                                                <TextField
                                                className="textField"
                                                        name="zipcode"
                                                        data-testid="zipcode"
                                                        value={this.state.userProfileData.current_user?.zipcode || ""}
                                                        variant="outlined"
                                                        placeholder="Enter ZIP/Postal code"
                                                        // size="small"
                                                        fullWidth
                                                        inputProps={{ maxLength: 15 }}
                                                        onChange={this.handleChange}
                                                        error={!!this.state.zipCodeError}
                                                        helperText={this.state.zipCodeError}  
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    />
                                                </Box>

                                                <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>Date of birth*</Typography>
                                                <TextField
                                                className="textField"
                                                        name="dob"
                                                        type="date"
                                                        data-testid="dob"
                                                        value={this.state.userProfileData.current_user?.dob}
                                                        variant="outlined"
                                                        placeholder="Enter Date of birth"
                                                        // size="small"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        error={this.isFutureDate(this.state.userProfileData.current_user?.dob)}
                                                        helperText={
                                                            this.isFutureDate(this.state.userProfileData.current_user?.dob)
                                                                ? "Date of birth cannot be in the future"
                                                                : ""
                                                        }
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    />
                                                </Box>
                                                <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>Apt/Suite*</Typography>
                                                <TextField
                                                className="textField"
                                                        name="apt_suite"
                                                        data-testid="apt_suite"
                                                        value={this.state.userProfileData.current_user?.apt_suite}
                                                        variant="outlined"
                                                        placeholder="Enter Apt/Suite"
                                                        // size="small"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        error={!!this.state.aptSuiteError}
                                                        helperText={this.state.aptSuiteError} 
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    />
                                                </Box>

                                                <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>City*</Typography>
                                                <TextField
                                                className="textField"
                                                        name="city"
                                                        data-testid="city"
                                                        value={this.state.userProfileData.current_user?.city}
                                                        variant="outlined"
                                                        placeholder="Enter City"
                                                        // size="small"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        error={!!this.state.cityError}
                                                        helperText={this.state.cityError}
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    />
                                                </Box>

                                                <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>State</Typography>

                                                <TextField
                                                className="textField"
                                                        name="state_id"
                                                        data-testid="state"
                                                        value={this.state.userProfileData.current_user?.state_id}
                                                        variant="outlined"
                                                        select
                                                        // size="small"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    >
                                                        {this.state.stateList.map((state: any) =>
                                                            <MenuItem value={state.id} key={state.id}>{state.name}</MenuItem>
                                                        )}
                                                    </TextField>
                                                </Box>

                                                <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>Additional address 2</Typography>
                                                <TextField
                                                className="textField"
                                                        name="additional_address"
                                                        data-testid="phone-number1"
                                                        value={this.state.userProfileData.current_user?.additional_address}
                                                        variant="outlined"
                                                        placeholder="Enter ZIP/Postal code"
                                                        // size="small"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        error={!this.nameReg.test(this.state.userProfileData.current_user?.additional_address)}
                                                        helperText={!this.nameReg.test(this.state.userProfileData.current_user?.additional_address) && 'Enter Valid Address'}
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    />
                                                </Box>

                                                <Box style={webStyles.fieldBox}>
                                                <Typography style={webStyles.label}>Your Profile*</Typography>
                                                <TextField
                                                className="textField"
                                                        name="job_title"
                                                        data-testid="job_title"
                                                        value={this.state.userProfileData.current_user?.job_title}
                                                        variant="outlined"
                                                        placeholder="Enter Job title"
                                                        // size="small"
                                                        fullWidth
                                                        onChange={this.handleChange}
                                                        error={!!this.state.jobTitleError}
                                                        helperText={this.state.jobTitleError}
                                                        InputProps={{ style: { borderRadius: '11px', borderColor: '#6C6C6C' } }}
                                                    />
                                                </Box>
                                                <Box className="actionBtn">
                                            <Box >
                                                <Button className="btnCancel" onClick={this.handleClose} data-testid="update-btn">CANCEL</Button>
                                            </Box>
                                            <Box >
                                                <Button style={{
                                                      background: this.state.isFormUpdated ? '#364BA0' : 'rgb(180, 180, 180)',
                                                      color: 'white',
                                                      padding: '10px 43px',
                                                      borderRadius: '8px',
                                                      fontSize: '15px',
                                                      fontWeight: 600,
                                                      border: this.state.isFormUpdated ? '1px solid #364BA0' : '1px solid rgb(180, 180, 180)',
                                                }}
                                                  disabled={!this.state.isFormUpdated}
                                                 onClick={this.handleUpdate} data-testid="update-btn">{configJSON.save}</Button>
                                            </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                </DialogContent>

                            </PaymentCardDialog>
            </ThemeProvider>
        )
    }

    renderImageModal = () => {
        return (
       <PhotoCardDialog 
        open={this.state.imageModalOpen}
            onClose={this.handleProfileImageModal}
         maxWidth="sm" fullWidth className="radius-class" >
            <DialogContent>
            <Box style={webStyles.editContainer}>
                                    <Typography style={{ fontSize: "17px",fontWeight:600 ,fontFamily: 'Rubik' }}>Edit Photo</Typography>
                                    <IconButton aria-label="close" onClick={this.handleProfileImageModal}>
                                        <CloseIcon style={{
                                            color: 'black'
                                        }} />
                                    </IconButton>
                                </Box>
                                <hr style={webStyles.Hr} />
                                <Box>
                                    <Box style={webStyles.imageContainer}>
                                        <Box style={webStyles.imageCircle}>
                                        <img src={this.state.profileImage || this.state.userProfileData?.current_user?.profile_image?.url || profileImageIcon} style={webStyles.Avatar_upload}/>
                                        <button
                 className="profileCircleBtn"
                  onClick={this.handleEditClick}
                  data-testid="profile-pic-edit"
                >
                  <AddIcon style={webStyles.addIcon}/>
                </button>
                <input
                  type="file"
                  data-testid="profileImageInput"
                  ref={this.state.fileInputRef}
                  style={{ display: "none" }}
                  onChange={this.handleFileChange}
                  accept="image/*"
                />
                                        </Box>
                                    </Box>
                                    <Typography
                                        variant="body2"
                                        style={{
                                            fontSize: '0.875rem',
                                            fontFamily: 'Rubik',
                                            textAlign: 'center',
                                            fontWeight: 700,
                                            marginTop: 1
                                        }}
                                    >
                                        250x250 Min / 5 MB Max
                                    </Typography>
                                    <Box style={webStyles.btnBox}>
                                        <Button
                                            style={webStyles.btnCancelBox}
                                            onClick={this.handleProfileImageModal}
                                            data-testid="cancel-btn"
                                            fullWidth
                                        >
                                            CANCEL
                                        </Button>
                                        <Button
                                            style={webStyles.saveBtnBox}
                                            onClick={this.handleImageUpdate}
                                            data-testid="update-btn"
                                            fullWidth
                                        >
                                            {configJSON.save}
                                        </Button>
                                    </Box>
                                </Box>
            </DialogContent>
        </PhotoCardDialog>

        )
    }
    

    renderResponseDialog = () => {
        return (
            <Dialog
                open={this.state.popUpMessage}
                onClose={this.handleDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                style={webStyles.dialogOuterContainer}
                data-testid="response-modal"
            >
                <DialogContent>
                    {this.state.responseMessage ?
                        <DialogContentText id="alert-dialog-description" style={webStyles.successMessage}>
                            {configJSON.successMessage}
                        </DialogContentText> :
                        <DialogContentText id="alert-dialog-description2" style={webStyles.failedMessage}>
                            {configJSON.failedMessage}
                        </DialogContentText>
                    }
                    <DialogActions style={webStyles.btnOuterContainer}>
                        <Button onClick={this.handleDialog} data-testid="ok-btn" color="primary" variant="contained">
                            {configJSON.okBtnText}
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Dialog>
        )
    }
    // Customizable Area End

    render() {
        // Customizable Area Start
        {console.log(this.state.userProfileData.current_user,"this.state.userProfileData.current_user?")}
        return (
            //Merge Engine DefaultContainer
            <ThemeProvider theme={theme}>
                <Box>
                    <Grid container>
                        <Grid item sm={12} md={12}>
                           <Box display={"flex"} justifyContent={"space-between"}><Typography variant="h2" style={webStyles.profileText}>{configJSON.clientProfile}</Typography>
                                </Box> 
                            <Box style={webStyles.profileBox}>
                                <Box style={webStyles.nameInfo}>
                                    <Box style={webStyles.profileContainer}>
                                        <FiberManualRecordIcon data-testid="user-status" style={{ ...webStyles.dotIcon, color: this.state.showUserProfileData.current_user?.status === 'online' ? 'green' : 'grey' }} />
                                        <img src={this.state.showUserProfileData.current_user?.profile_image?.url} alt="avtara" style={webStyles.avtar} />
                                        <IconButton onClick={this.handleProfileImageModal} aria-label="edit" data-testid="edit" style={{
                                            marginLeft: '-22px',
                                            background: 'white',
                                            width: 24,
                                            height: 24
                                        }}>
                                        <img src={pencilIcon} alt="edit" />
                                    </IconButton>
                                    </Box>
                                    {this.state.imageUploadError && (
                                        <Typography >
                                            {this.state.imageUploadError}
                                        </Typography>
                                        )}
                                    <Box>
                                        <Typography style={webStyles.userName}>{this.state.showUserProfileData.current_user?.first_name} {this.state.showUserProfileData.current_user?.last_name}</Typography>
                                        <Box style={webStyles.locationBox}>
                                            <LocationOnRoundedIcon fontSize="small" />
                                            <Typography>{this.state.userProfileData?.current_user?.city} {this.state.userProfileData.current_user?.state?.name} {this.state.userProfileData.current_user?.nation?.name}</Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    {this.state.userProfileData.current_user?.status === 'online' ?
                                        <Typography style={webStyles.onlineStatusIcon}>ONLINE</Typography>
                                        :
                                        <Typography style={webStyles.offlineStatusIcon}>OFFLINE</Typography>

                                    }
                                </Box>
                            </Box>
                            
                            <Box display={'flex'} style={{
                                justifyContent: 'space-between',
                                marginTop: '48px'
                            }} justifyContent={"space-bewteen"}>
                            <Box style={webStyles.detalisBox}>
                                <Typography style={webStyles.detailText}>{configJSON.details}</Typography>
                            </Box>
                            <Box style={{...webStyles.editBox,marginTop:"-2%"}}>
                                    <Typography style={webStyles.editText}>{configJSON.edit}</Typography>
                                    <IconButton aria-label="edit" data-testid="edit1">
                                        <img onClick={this.handleModal} src={editIcon} alt="edit" />
                                    </IconButton>
                                </Box>
                            </Box>
                            <Box style={webStyles.aboutBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.aboutMe}</Typography>
                                <Typography style={webStyles.aboutMeDetail} color="secondary">{this.state.showUserProfileData.current_user?.job_description}</Typography>
                            </Box>
                            <Box style={webStyles.aboutBox}>
                                <Typography style={webStyles.aboutMeHead}>{configJSON.contactInfo}</Typography>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.userId}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.id}</Typography>
                                </Box>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.name}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.first_name} {this.state.showUserProfileData.current_user?.last_name}</Typography>
                                </Box>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.email}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.email}</Typography>
                                </Box>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" 
                                    style={webStyles.infoTextHead}
                                    >{configJSON.address}</Typography>
                                    <AddressContainer >{this.state.showUserProfileData.current_user?.address}</AddressContainer>
                                </Box>
                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.phone}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.country_code}{this.state.showUserProfileData.current_user?.phone_number}</Typography>
                                </Box>

                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.zipCodText}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.zipcode}</Typography>
                                </Box>

                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.dateofbirth}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.dob}</Typography>
                                </Box>

                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.suite}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.apt_suite}</Typography>
                                </Box>

                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.city}</Typography>
                                     <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.city}</Typography>
                                </Box>

                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.state}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.state?.name}</Typography>
                                </Box>

                                 <Box style={webStyles.infoBox}>
                                <Typography color="secondary" style={webStyles.infoTextHead}>Country</Typography>
                                {this.state.countryList
                                    .filter((country: any) => country.id === this.state.showUserProfileData?.current_user?.nation_id)
                                    .map((filteredCountry: any) => (
                                        <Typography key={filteredCountry.id} style={webStyles.infoText}>
                                            {filteredCountry.name}
                                        </Typography>
                                    ))}
                                </Box>

                                    <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.additionalAddress}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.additional_address}</Typography>
                                </Box>

                                <Box style={webStyles.infoBox}>
                                    <Typography color="secondary" style={webStyles.infoTextHead}>{configJSON.jobtitle}</Typography>
                                    <Typography style={webStyles.infoText}>{this.state.showUserProfileData.current_user?.job_title}</Typography>
                                </Box>
                             
                            </Box>
                     
                        </Grid>
                    </Grid>
                </Box>
                {this.renderResponseDialog()}
                {this.state.editModalOpen && this.renderModal()}
                {this.state.imageModalOpen && this.renderImageModal()}
            </ThemeProvider>
            //Merge Engine End DefaultContainer
        );
        // Customizable Area End
    }
}

// Customizable Area Start

const SelectManu_style = styled(MenuItem)({

    "&:hover": {
      backgroundColor: "364BA0",
      color:"white",
    },
  });
  
const AddressContainer =styled(Typography)({
    fontSize: '0.9rem',
    wordBreak:"break-all",
    fontWeight: 600
})

const PhotoCardDialog = styled(Dialog)({
    '& .profileCircleBtn':{
        position: 'absolute',
        background: '#364BA0',
        border: 'none',
        borderRadius: '50%',
        top: '50%',
        right: '39%',
        "@media(max-width:450px)": {
            right: '30%',
       },
    },
    '& .textField':{
        width: '100%',
        "@media(max-width:450px)": {
            width: 'auto',
       },
    },
    '&.radius-class > div > div': {
        borderRadius: "18px",
      },
    '& .btnCancel':{
    border: '1px solid #364BA0',
    color: '#364BA0',
    padding: '10px 43px',
    borderRadius: '8px',
    fontSize: '15px',
    fontWeight: 600,
    "@media(max-width:450px)": {
        marginBottom: '15px',
        marginTop: '15px',
   }, 
}},)

const PaymentCardDialog = styled(Dialog)({
    '&.radius-class > div > div': {
      borderRadius: "18px",
    },
    '& .textField':{
        width: '100%',
        "@media(max-width:450px)": {
            width: 'auto',
       },
    },
    '& .btnCancel':{
    border: '1px solid #364BA0',
    color: '#364BA0',
    padding: '10px 43px',
    borderRadius: '8px',
    fontSize: '15px',
    fontWeight: 600,
    "@media(max-width:450px)": {
        marginBottom: '15px',
        marginTop: '15px',
   }, 
},
'& .actionBtn' :{
    display: 'flex',
    justifyContent: 'end',
    gap: '13px',
    padding: '48px 13px 12px',
    "@media(max-width:450px)": {
        display: 'block',
        textAlign: 'center'
   },
   },
    '& .countryPhone':{
         display: 'flex',
        gap: '15px',
        "@media(max-width:450px)": {
            display: 'block',
       },
      }
  })

const webStyles = {
    Avatar_upload: {
        width: "150px", 
        height: "150px",
        borderRadius: '50%',
        "@media(max-width:500px)": {
         width: "100px", 
         height: "100px",
    },
},
    addIcon: {
        color: 'white',
        width:'23px',
        height: '25px'
    },
    profileBox: {
        background: '#F2F2F2',
        borderRadius: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        padding: '15px 25px'
    },
    avtar: {
        width: '56px',
        height: '56px',
        borderRadius: '50%'
    },
    locationBox: {
        display: 'flex'
    },
    detalisBox: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        fontSize: '24px',
        fontWeight: 400,
        fontFamily: 'Regular, Rubik'
    },
    editBox: {
        display: 'flex',
        alignItems: 'center'
    },
    aboutMeHead: {
        fontSize: '1.1rem',
        fontWeight: 600,
        marginBottom: '1%'
    },
    aboutMeDetail: {
        fontSize: '0.8rem'
    },
    infoBox: {
        display: 'flex',
        marginTop: '1%'
    },
    infoText: {
        fontSize: '0.9rem',
        fontWeight: 600,
    },
    infoTextHead: {
        fontSize: '0.9rem',
        width: '25%'
    },

    infoTextHeadAddress:{
      color:"red",
      width:"85%",
      fontSize: '0.9rem',
      wordBreak:"break-all"
    },
    modalContainer: {
        width: '100px',
        height: '100px',
        margin: 'auto',
        background: '#fff'
    },
    modalView: {
        position: "absolute" as "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 500,
        background: "#fff",
        borderRadius: '10px',
        padding: '24px',
        overflowY: 'scroll' as 'scroll'
    },
    editContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 4px 0px 15px'
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '72px auto 24px',
        width: '230px',
        height: '230px',
        border: '1px dotted rgb(132, 126, 126)',
        borderRadius: '50%',
        marginTop: '10px'
    },
    imageCircle: {
        display: 'flex',
        justifyContent: 'center',
    },
    editText: {
        fontWeight: 700
    },
    Hr: {
        height: '2px',
        background: '#DADADA',
        border: 'none',
        margin: '0'
    },
    modalField: {
        padding: '28px 15px 15px'
    },
    label: {
        fontSize: '0.9rem',
        fontWeight: 600,
        marginBottom: '2%'
    },
    fieldBox: {
        marginBottom: '3%'
    },
    btnSaveBox: {
        textAlign: 'end' as 'end',
    },
    saveBtnBox: {
        background: '#364BA0',
        color: 'white',
        padding: '10px 43px',
        borderRadius: '8px',
        fontSize: '15px',
        fontWeight: 600,
        border: '1px solid #364BA0',
    },
    btnCancelBox: {
        border: '1px solid #364BA0',
        color: '#364BA0',
        padding: '10px 43px',
        borderRadius: '8px',
        fontSize: '15px',
        fontWeight: 600
    },
    btnBox:{
        display: 'flex',
        justifyContent: 'end',
        gap: '13px',
        padding: '48px 13px 12px'
    },
    countryField: {
        // width: '208px'
    },
    profileText: {
        fontSize: '1.5rem',
        fontWeight: 600,
        marginBottom: '2%'
    },
    nameInfo: {
        display: 'flex',
        gap: '12px',
        alignItems: 'center'
    },
    userName: {
        fontSize: '1.1rem',
        fontWeight: 600
    },
    onlineStatusIcon: {
        color: '#fff',
        border: '20px',
        borderRadius: '20px',
        padding: '1px 12px',
        fontWeight: 600,
        fontSize: '0.9rem',
        background: '#66AC3D',
    },
    offlineStatusIcon: {
        color: '#fff',
        border: '20px',
        borderRadius: '20px',
        padding: '1px 12px',
        fontWeight: 600,
        fontSize: '0.9rem',
        background: 'grey'
    },
    detailText: {
        fontSize: '1.2rem',
        fontWeight: 600
    },
    aboutBox: {
        background: '#F2F2F2',
        borderRadius: '20px',
        padding: '15px 25px 30px',
        marginTop: '2%'
    },
    dotIcon: {
        position: 'absolute' as 'absolute',
        fontSize: '12px',
        marginLeft: '5px'
    },
    profileContainer: {
        position: 'relative' as 'relative'
    },
    successMessage: {
        color: 'green',
    },
    failedMessage: {
        color: 'red'
    },
    btnOuterContainer: {
        display: 'flex',
        justifyContent: 'center'
    },
    dialogOuterContainer: {
        boxShadow: 'none'
    },
    experienceBox: {
        padding: '15px 25px 0 25px',
        display: 'flex',
        justifyContent: 'space-between'
    },
    month: {
        fontSize: '0.87rem'
    },
};
// Customizable Area End
