//Customizable Area Start
import React from "react";
//Customizable Area Start
import { Box, Grid, Tab, Tabs, Typography, styled } from "@material-ui/core";
import ClientNavBar from "./Components/ClientNavBar.web";
import FooterWeb from "./Components/Footer.web";
import ActiveContractDetailsControllerClient, { Props } from "./ActiveContractDetailsControllerClient.web";
import ProjectDetailsClient from "./ProjectDetailsClient.web";
import ProjectTrackingClient from "./ProjectTrackingClient.web";
export const webConfigJSON = require("./config");



const CustomTabs = styled(Tabs)({
  '& .MuiTab-textColorPrimary-25.Mui-selected': {
    fontWeight: "600",
    fontSize: "22px",
    textTransform: "inherit"
  },
  '& .MuiTab-textColorPrimary-25': {
    fontWeight: "600",
    fontSize: "22px",
    textTransform: "inherit"
  },
  '& .PrivateTabIndicator-root-31': {
    height: "4px"
  }

})
// Customizable Area End

export default class ActiveContractDetailsClient extends ActiveContractDetailsControllerClient {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }

  //Customizable Area Start



  // Customizable Area End

  render() {
    //Customizable Area Start
    const { selectedTab } = this.state;
    return (
      <>
        <ClientNavBar />
        <Grid container spacing={2} lg={12} style={webStyle.gridBox}>

          <Grid item lg={12} xs={12}>
            <Box style={{ marginTop: "30px" }}>
              <TitleHead>Active Contracts</TitleHead>
              <TitleTxt style={{ fontFamily: "Rubik", fontSize: "14px", color: "#353535" }}>
                Stay focused and informed with a detailed view of your active contract. Monitor progress, manage tasks, and keep all important information at your fingertips for seamless project execution.
              </TitleTxt>

            </Box>
          </Grid>
          <Box display={"flex"} flexDirection={"column"} >
            <CustomTabs
              value={selectedTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Project Details" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
              <Tab label="Project Tracking" style={{ textTransform: "none", fontFamily: "Rubik", fontSize: "16px", color: "#3F3F3F", fontWeight: 500 }} />
            </CustomTabs>

            {selectedTab === 0 && (
              <ProjectDetailsClient navigation={this.props.navigation} />
            )}
            {selectedTab === 1 && (
              
            <ProjectTrackingClient navigation={this.props.navigation}/>
            )}
          </Box>

        </Grid>
        <FooterWeb />
      </>
    );
    //Customizable Area End
  }  
}
//Customizable Area Start
const webStyle = {

  gridBox: {
      display: 'flex',
      margin: "44px 35px 45px 35px",
      overflow: "hidden"
  },
}
const TitleTxt = styled(Typography)({
  margin: "25px 0px",
  display: "flex",
  fontSize: "14px",
  fontStyle: "normal",
  lineHeight: "16px",
  width: "92%",
  color: "#353535"
});
const TitleHead = styled(Typography)({
fontFamily:"Rubik",
fontWeight: 500,
fontSize: "36px",
color: "#3F3F3F",
width:"50%"
})

//Customizable Area End
// Customizable Area End