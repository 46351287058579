import React from "react";
import { Box, Grid,Tabs, Button, Typography, styled } from "@material-ui/core";
import ProjectDetailsControllerClient, { Props } from "./ProjectDetailsControllerClient.web";
export const webConfigJSON = require("./config");
import { TypographyView } from "./Style";

const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .PrivateTabIndicator-root-31': {
        height: "4px"
    }
});

export default class ProjectDetailsClient extends ProjectDetailsControllerClient {
    constructor(props: Props) {
        super(props);
    }

    render() {
        return (
            <TypographyView>
                <Box marginTop={"3%"} marginRight={"6%"}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <Box border={"1px solid #ECECEC"} borderRadius={"10px"} padding={"18px 30px"}>
                                <Typography style={{ ...webStyle.header, color: "#303030" }}>Freelance IP Lawyer Needed</Typography>
                                <Box borderBottom="1px solid #ECECEC" paddingBottom="20px" marginBottom="20px" marginTop="25px" >
                                    <Grid container spacing={2} direction="column" >
                                        <Grid item xs={12} md={6}>
                                            <Box display="flex">
                                            <Typography style={webStyle.subHeader}>IP Client :</Typography>
                                            <Typography style={{ ...webStyle.text, fontSize: "16px", color: "#545454",marginLeft:"50px" }}>{this.state.projectDetailsData?.attributes?.freelancer_details?.freelancer_name}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        <Box display="flex"  >
                                            <Typography style={webStyle.subHeader}>Duration :</Typography>
                                            <Typography style={{ ...webStyle.text, fontSize: "16px", color: "#545454",marginLeft:"48px" }}>{this.state.projectDetailsData?.attributes?.post_attributes?.data?.attributes?.project_length}</Typography>
                                        </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        <Box display="flex"  >
                                            <Typography style={webStyle.subHeader}>Charge :</Typography>
                                            <Typography style={{ ...webStyle.text, fontSize: "16px", color: "#545454",marginLeft:"55px" }}>${this.state.projectDetailsData?.attributes?.post_attributes?.data?.attributes?.per_day_charge}</Typography>
                                            </Box>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                        <Box display="flex"  >
                                            <Typography style={webStyle.subHeader}>Payment :</Typography>
                                            <Typography style={{ ...webStyle.text, fontSize: "16px", color: "#545454",marginLeft:"45px" }}>{this.state.projectDetailsData?.attributes?.payment_type}</Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Box>
                                <Box marginTop="20px">
                                    <Typography style={{ ...webStyle.header, marginBottom: "1%" }}>Project Details</Typography>
                                    <Typography style={{ ...webStyle.text, color: "#0A0A0A" }}>
                                        {this.state.projectDetailsData?.attributes?.post_attributes?.data?.attributes?.description}
                                    </Typography>
                                </Box>
                                <Box borderTop="1px solid #ECECEC" marginTop="20px" paddingTop="20px">
                                    <Typography style={{ ...webStyle.header, color: "#303030" }}>Milestone details</Typography>
                                    {this.state.projectDetailsData?.attributes?.milestone_details?.map((milestones: any, index: number) => (
                                        <Grid container spacing={3} key={index} alignItems="center" style={{ marginTop: "20px" }}>
                                            <Grid item xs={6} md={2}>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Milestone Description</Typography>
                                                <Box display="flex" alignItems="center">
                                                    <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#515354" }}>{index + 1}</Typography>
                                                    <Box marginLeft="10px">
                                                        <Box style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "130px"
                                                    }}>
                                                        <Typography style={{ fontWeight: 600, color: "#515354" }}>
                                                            {milestones.milestone_description}
                                                        </Typography>
                                                        </Box>
                                                        
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Budget</Typography>
                                                
                                                        <Box style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "130px"
                                                    }}>
                                                <Typography style={{ fontWeight: 600, color: "#515354" }}>
                                                    {milestones.milestone_amount}
                                                </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Date</Typography>
                                                <Box style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "130px"
                                                    }}>
                                                <Typography style={{ fontWeight: 600, color: "#515354" }}>
                                                    {milestones.milestone_date}
                                                </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Status</Typography>
                                                
                                                <Box style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "130px"
                                                    }}>
                                                <Typography style={{ fontWeight: 600, color: this.getStatusColor(milestones.milestone_status) }}>
                                                    {milestones.milestone_status}
                                                </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Active Disputes</Typography>
                                                <Box style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "130px"
                                                    }}>
                                                <Typography style={{ fontWeight: 600, color: "#515354" }}>
                                                    {milestones.active_disputes}
                                                </Typography>
                                                </Box>
                                            </Grid>
                                            <Grid item xs={6} md={2}>
                                                <Typography style={{ ...webStyle.text, fontWeight: 500, color: "#000000" }}>Total Disputes</Typography>
                                                <Box style={{
                                                        ...webStyle.textField,
                                                        borderRadius: "12px",
                                                        cursor: "not-allowed",
                                                        width: "130px"
                                                    }}>
                                                <Typography style={{ fontWeight: 600, color: "#515354" }}>
                                                    {milestones.total_disputes}
                                                </Typography>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </Box>
                            </Box>
                        </Grid>



                        <Grid item xs={12}>
                            <Box display="flex" className="buttonPlace" justifyContent="space-between" marginTop="3%">
                                <Box display="flex" gridColumnGap="2rem">
                                    <Button variant="contained" style={{ ...webStyle.button, backgroundColor: "#364BA0", color: "#FFF", borderRadius: "10px" }}>
                                        raise dispute
                                    </Button>
                                    <Button variant="contained" style={{ ...webStyle.button, color: "#364BA0", backgroundColor: "#FFF", border: "1px solid #364BA0", borderRadius: "10px" }}>
                                        All Disputes
                                    </Button>
                                </Box>
                                <Box className="endContactButton">
                                <Button variant="contained" style={{ ...webStyle.button, color: "#FFF", backgroundColor: "#D60000", borderRadius: "10px" }}>
                                    end contract
                                </Button>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </TypographyView>
        );
    }
}

const webStyle = {
    textField: {
        border: "1px solid #E6E6E6",
        padding: "10px 16px",
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
        backgroundColor: "#fff",
        cursor: "not-allowed",
        height: "35px"
    },
    borderRadius: {
        borderRadius: "12px"
    },
    header: {
        fontFamily: "Rubik",
        fontSize: "24px",
        color: "#3F3F3F",
        fontWeight: 500
    },
    subHeader: {
        fontFamily: "Rubik",
        fontSize: "18px",
        color: "#3F3F3F",
        fontWeight: 500,
        letterSpacing: "0px",

        
    },
    text: {
        fontFamily: "Rubik",
        fontSize: "16px",
        color: "#3F3F3F",
        fontWeight: 400,
        opacity: "1",
        lineHeight: "25px",
        letterSpacing: "0px",
    },
    button: {
        width: "200px",
        height: "50px",
        fontFamily: "Rubik",
    }
};