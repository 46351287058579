import React from "react";
// Customizable Area Start
import {
    Typography,
    Box,
    Select,
    MenuItem,
    FormControl,
    styled,
    Link,
    Divider,
    Tab,
    OutlinedInput
} from "@material-ui/core";
import { shap } from "./assets";
import FooterWeb from "./Components/Footer.web";
import Tabs from "@material-ui/core/Tabs";
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ClientNavBar from "./Components/ClientNavBar.web";
import { TypographyView } from './Style';

const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorPrimary-25.Mui-selected': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .MuiTab-textColorPrimary-25': {
        fontWeight: "600",
        fontSize: "22px",
        textTransform: "inherit"
    },
    '& .PrivateTabIndicator-root-31': {
        height: "4px"
    }

})

import AllContractClientController, {
    Props,
    webConfigJSON,
} from "./AllContractClientController.web";
import { format } from "date-fns";

// Customizable Area End

export default class AllContractClient extends AllContractClientController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    // Customizable Area End
    renderSelect = () => {
        return (
            <Box style={{ display: "flex" }}>
                <CurUpperText>{webConfigJSON.myJobs}</CurUpperText>
                <ArrowIcon>{">"}</ArrowIcon>
                <CurUpperText>{webConfigJSON.allcontract}</CurUpperText>
                <ArrowIcon>{">"}</ArrowIcon>
                <FontActiveText>{webConfigJSON.ArchivedContract}</FontActiveText>
            </Box>
        );
    };

    getTitleText = (activeTab: number) => {
        switch (activeTab) {
            case 0:
                return "Keep track of all your ongoing freelance contracts in one place. Easily view details, manage deadlines, and stay on top of your work.";
            case 1:
                return "View all your successfully completed contracts in one place. Analyze your past work, review achievements.";
            case 2:
            default:
                return "Access all your completed contracts with ease. Review past projects, gather insights, and maintain an organized record.";
        }
    };
    
    renderContract = () => {
        const { activeTab } = this.state;
        return (
            <TypographyView>
            <Box>
                <HeaderTxt>{webConfigJSON.allcontract}</HeaderTxt>
                <CustomTabs
                    value={activeTab}
                    indicatorColor="primary"
                    onChange={this.handleTabChange}
                    textColor="primary"                   
                    scrollButtons="auto"
                    variant="scrollable"
                >
                    <Tab
                        label="Active Contracts"
                        style={{
                            textTransform: "none",
                            fontFamily: "Rubik",
                            fontSize: "16px",
                            color: this.state.activeTab === 0 ? "#364BA0" : "#3F3F3F",
                            fontWeight: 500,
                        }}
                    />
                    <Tab
                        label="Completed Contracts"
                        style={{
                            textTransform: "none",
                            fontFamily: "Rubik",
                            fontSize: "16px",
                            color: this.state.activeTab === 1 ? "#364BA0" : "#3F3F3F",
                            fontWeight: 500,
                        }}
                    />
                    <Tab
                        label="Archived Contracts"
                        style={{
                            textTransform: "none",
                            fontFamily: "Rubik",
                            fontSize: "16px",
                            color: this.state.activeTab === 2 ? "#364BA0" : "#3F3F3F",
                            fontWeight: 500,
                        }}
                    />
                </CustomTabs>
                <Divider />
                <TitleTxt>{this.getTitleText(activeTab)}</TitleTxt>
            </Box>
            </TypographyView>
        );
    };

    renderdate = () => {
        const { selectedProfessional } = this.state
        return (
            <DateBoxDesign>
                <Box className="timeperiod">
                    <Box style={{
                        marginTop: this.state.dateError && '40px'
                    }}>
                        <Typography style={webStyle.labelStyle}>Start Period</Typography>
                        <InputField>
                            <OutlinedInput
                                type="date"
                                name="startDate"
                                value={this.state.startDate}
                                onChange={this.handleStartDateChange}
                                style={{
                                    borderRadius: "12px",
                                }}
                                inputProps={{
                                    placeholder: "dd-mm-yyyy",
                                }}
                            />
                        </InputField>
                    </Box>
                    <Box style={{
                        marginTop: this.state.dateError && '40px'
                    }}>
                        <Typography style={webStyle.labelStyle}>End Period</Typography>
                        <InputField>
                            <OutlinedInput
                                type="date"
                                name="endDate"
                                value={this.state.endDate}
                                onChange={this.handleEndDateChange}
                                style={{
                                    borderRadius: "12px",
                                }}
                                inputProps={{
                                    placeholder: "dd-mm-yyyy",
                                }}
                            />
                        </InputField>
                        {this.state.dateError && (
                            <Typography style={{ color: "red", marginTop: "8px", marginBottom: '4px' }}>
                                {this.state.dateError}
                            </Typography>
                        )}
                    </Box>

                </Box>
                <Box className="clientDropdown">
                    <Typography style={webStyle.labelStyle}>IP Professional</Typography>
                    <InputField className="textField" variant="outlined">
                        <Select
                            defaultValue=""
                            value={selectedProfessional}
                            style={{ fontWeight: 700 }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                transformOrigin: {
                                    vertical: "bottom",
                                    horizontal: "left"
                                },
                                getContentAnchorEl: null
                            }}
                            onChange={this.handleDropdownChange}
                            data-test-id="counter">
                            <MenuItem
                                value="All Professionals">
                                All Professionals
                            </MenuItem>
                            {this.state.freelancerDetailsData && this.state.freelancerDetailsData.map((itm: any, index: number) => (
                                <SelectManu_style key={index} value={itm.id} >
                                    {itm?.attributes?.full_name}
                                </SelectManu_style>
                            ))}
                        </Select>
                    </InputField>
                </Box>

            </DateBoxDesign>
        )
    }

    renderProject = () => {
        const { activeContractData, activeContractErr } = this.state;
        console.log(activeContractData, "activeContractData")

        if (!activeContractData || activeContractData.length === 0) {
            return (
                <Typography
                    style={{
                        fontSize: '19px',
                        justifyContent: 'center',
                        display: 'flex',
                        color: '#878484',
                        fontFamily: 'Rubik',
                        fontWeight: 700,
                        marginTop: '14pc',
                        marginBottom: '10pc'
                    }}
                >
                    No contracts found
                </Typography>
            );
        }

        if (activeContractErr) {
            return (
                <Typography
                    style={{
                        fontSize: '19px',
                        justifyContent: 'center',
                        display: 'flex',
                        color: '#878484',
                        fontFamily: 'Rubik',
                        fontWeight: 700,
                        marginTop: '14pc',
                        marginBottom: '10pc'
                    }}
                >
                    {activeContractErr}
                </Typography>
            );
        }

        return (
            <ProjectOuterMainBox style={webStyle.boxStyle}>
                {activeContractData.map((itm: any, index: number) => (
                    <ProjectInnerMainBox key={itm?.id || index.toString()}>
                        <Box className="projectHead">
                            <PriceBox className="priceBox">
                                <PriceTxt>
                                    ${itm?.attributes?.post_attributes?.data?.attributes?.est_budget}
                                </PriceTxt>
                                <PriceTxt>per/Hour</PriceTxt>
                            </PriceBox>
                            <DateBox>
                                <img
                                    src={shap}
                                    alt="Calendar Icon"
                                    style={webStyle.calenderStyle}
                                />
                                <DateTxt>
                                    {itm?.attributes?.post_attributes?.data?.attributes?.created_at}
                                </DateTxt>
                            </DateBox>
                        </Box>
                        <Box style={webStyle.fixedfiled}>
                            <ProjectNameTxt>
                                {itm?.attributes?.post_attributes?.data?.attributes?.name}
                            </ProjectNameTxt>
                            <ProjectOwnerNameTxt>
                                <span> By{' '}</span>
                                {itm?.attributes?.client_details?.client_name}
                            </ProjectOwnerNameTxt>
                            
                        </Box>
                        <ButtonBox
                            data-test-id={"goToActiveFiled"}
                            onClick={() => this.props.navigation.navigate('ActiveContractDetailsClient', { id: itm?.id })}
                        >
                            <ProjectTxt>{webConfigJSON.goToProject}</ProjectTxt>
                            <ArrowForwardIcon style={webStyle.arrowIcone} />
                        </ButtonBox>
                    </ProjectInnerMainBox>
                ))}
            </ProjectOuterMainBox>
        );
    };

    formatDate = (rawDate: any) => {
        if (!rawDate) return "";
        const parsedDate = new Date(rawDate);
        return format(parsedDate, "MMM dd, yyyy");
    }

    render() {
        //Customizable Area Start 
        return (
            <>
                <ClientNavBar />
                <BoxMain>
                    {this.renderContract()}
                    {this.renderdate()}
                    {this.renderProject()}
                </BoxMain>
                <FooterWeb />
            </>
        );
        //Customizable Area End
    }

}

// Customizable Area Start
const webStyle = {
    arrowIcone: {
        color: 'white'
    },
    fixedfiled: {
        margin: "22px 0px"
    },
    labelStyle: {
        fontFamily: "Rubik",
        fontSize: "14px",
        color: "#535353",
        fontWeight: 500,
        marginBottom: "8px"
    },
    clientDropdown: {
        paddingRight: '32px',
        fontSize: '14px',
        fontFamily: 'Rubik',
        fontWeight: 700,
        borderRadius: '13px',
        '.MuiOutlinedInput-root': {
            '& fieldset': {
                borderRadius: '12px',
            },
        },
    },
    calenderStyle: {
        width: "14px",
        height: "15px",
        marginRight: "5px"
    },
    boxStyle: {
        padding: '32px',
        marginBottom: '32px',
        border: '1px solid #CDCDCD',
        borderRadius: '16px',
    }

};
const webStyles = {
    mainConatiner: {
        padding: "30px",
    },
    jobBtnBox: {
        display: "flex",
        justifyContent: "space-between",
        flexWrap: "wrap" as "wrap",
    },
    dashboardText: {
        fontFamily: "Rubik",
        fontSize: '32px',
        fontWeight: 400,
    },
    jobText: {
        fontSize: "20px",
        fontFamily: "Inter"
    },
    myJobText: {
        color: "#206FC4",
        fontFamily: "Inter"
    },
    postBtn: {
        borderRadius: "7px",
        padding: "1% 2%",
        fontFamily: "Inter"
    },
    borderBox: {
        background: "#F5F7F9",
        padding: "15px",
        borderRadius: "5px",
        marginTop: "3%",
        overflow: "auto",
    },

};
const BoxMain = styled(Box)({
    margin: "60px"
});
const FontActiveText = styled(Typography)({
    color: "#206FC4",
    cursor: "pointer",
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left"
});

const ArrowIcon = styled(Typography)({
    fontWeight: "bold",
    margin: "0px 8px"
});

const CurUpperText = styled(Typography)({
    fontFamily: "Inter",
    fontSize: "17px",
    fontWeight: 500,
    lineHeight: "24px",
    letterSpacing: "0px",
    textAlign: "left",
    cursor: "pointer"
});

const HeaderTxt = styled(Typography)({
    color: "3F3F3F",
    fontWeight: "bold",
    fontSize: "36px",
    fontFamily: "Rubik, sans-serif",
});

const TitleTxt = styled(Typography)({
    margin: "20px 0px",
    display: "flex",
    fontSize: "14px",
    fontFamily: "Rubik, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "16px",
    width: "91%",
    color: "#595959",
    flexWrap: 'wrap'
});
const ProjectOuterMainBox = styled(Box)({
    flexWrap: "wrap",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: "30px",
    padding: "20px",
});
const LinkText = styled(Link)({
    color: "#353535",
    fontFamily: "Inter",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "17px",
    letterSpacing: "0px",
    textAlign: "left",
    textDecoration: 'underLine'
})
const ProjectInnerMainBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    maxWidth: "356px",
    // maxHeight: "269px",
    // minHeight: "200px",
    position: "relative",
    background: "#F5F8FC",
    borderRadius: "10px",
    padding: "20px",
    justifyContent: "space-between",
    flex: "1 1 calc(33.333% - 16px)",
    boxSizing: "border-box",
    gap: "8px",
    '& .projectHead': {
        display: "flex",
        justifyContent: "space-between",
        "@media(max-width:500px)": {
            display: "block",
        },
    },
    '& .priceBox': {
        "@media(max-width:450px)": {
            marginTop: "12px",
        },
    }

});
const PriceBox = styled(Box)({
    width: "70px",
    background: "#E2ECF6",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    padding: "5px",
});
const PriceTxt = styled(Typography)({
    fontSize: "12px",
    fontWeight: "normal",
    color: "#364BA0",

});
const DateBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    "@media(max-width:450px)": {
        justifyContent: "flex-start",
        marginTop: '12px'

    },
});
const DateBoxDesign = styled(Box)({
    display: "flex",
    alignItems: "center",
    "@media(max-width:900px)": {
        display: "block",
    },
    '& .timeperiod': {
        display: "flex",
        gap: '10px',
        "@media(max-width:550px)": {
            display: "block",
        },
    },
    '& .clientDropdown': {
        display: "flex",
        flexDirection: "column",
        margin: "20px",
        "@media(max-width:900px)": {
            margin: "20px 0px",
        },
    },
    '& .textField': {
        width: "341px",
        "&.MuiFormLabel-root.Mui-focused ": {
            color: "#6F6E6E !important",
            fontWeight: 700,
            fontFamily: 'Rubik !important',
            fontSize: '14px'
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
            borderColor: "#6F6E6E !important",
            borderRadius: "12px !important",
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "12px !important",
            fontWeight: 700,
            fontFamily: 'Rubik !important'
        },
        "@media(max-width:550px)": {
            width: "100%",
        },
    },
})
const DateTxt = styled(Typography)({
    color: "#6F6F6F",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 500
});
const ProjectTxt = styled(Typography)({
    color: "#FFFFFF",
    marginRight: "10px",
    fontSize: "20px",
    fontFamily: "Rubik",
    fontWeight: 600,
    textAlign: 'center'
});

const ActiveDisputeText = styled(Typography)({
    fontFamily: "Rubik",
    marginBottom: "8px",
    color: "#FF1818",
    fontSize: "14px",
    fontWeight: 600
});

const TotalDisputeText = styled(Typography)({
    fontFamily: "Rubik",
    color: "#3C8F0A",
    fontSize: "14px",
    fontWeight: 600,
});

const ProjectNameTxt = styled(Typography)({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontWeight: 600,
    fontSize: "16px",
    fontFamily: "Rubik",
    marginBottom: "16px",
    width: "70%",
    color: "#414141"
});

const InputField = styled(FormControl)({
    width: '341px',
    "& .MuiOutlinedInput-root": {
        borderRadius: "12px !important"
    },
    "@media(max-width:550px)": {
        width: '100%',
    },

    "@media(max-width:500px)": {
        "&  .MuiOutlinedInput-input": {
            padding: "11.5px 12px",
        }
    },

    "& .MuiListItem-button": {
        "& :hover": {
            textDecoration: "none",
            backgroundolor: "red",

        }
    }
});

const SelectManu_style = styled(MenuItem)({

    "&:hover": {
        backgroundColor: "364BA0",
        color: "white",
    },
});

const ProjectOwnerNameTxt = styled(Typography)({
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    fontSize: "14px",
    fontFamily: "Rubik",
    fontWeight: 400,
    color: '#414141',
    marginBottom: '11px'
});
const ButtonBox = styled(Box)({
    background: "#364BA0",
    height: "64px",
    maxWidth: "308",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "7px",
    cursor: "pointer"
});

// Customizable Area End