import React from 'react';
import { Grid, Avatar, Typography, Button, Box, Tabs, Tab, Link, styled, FormControl, TextField, InputAdornment, MenuItem, Select, FormControlLabel, Checkbox, OutlinedInput, FormHelperText, Divider } from '@material-ui/core';
import FreelancerCompleteProfileController,
{ Props }
    from './FreelancerCompleteProfileController.web';
import FreelancerNavBar from '../../dashboard/src/FreelancerNavBar.web';
import FooterWeb from '../../dashboard/src/Components/Footer.web';
import { edit, add, facebook, medium, twitter, projectPic, outlineEdit, online } from './assets';
import {linkedin,instagram,youtube} from '../../customform/src/assets'
import Rating from '@material-ui/lab/Rating';
import StarBorderRoundedIcon from '@material-ui/icons/StarBorderRounded';
import StarRoundedIcon from '@material-ui/icons/StarRounded'
import CloseIcon from '@material-ui/icons/Close';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import AddIcon from '@material-ui/icons/Add';
import {
    Dialog,
    DialogActions,
    IconButton,
  } from '@material-ui/core';
import { uploadDoc } from '../../email-account-registration/src/assets';
const ParentGrid = styled(Grid)({
    padding: '48px 100px 48px 79px',
    '& .rating-date-text': {
        display: 'flex',
        alignItems: 'center',
    },
    '@media (max-width: 600px)': {
        padding: '48px 24px',
        '& .copyright-box': {
            flexDirection: 'column',
            '& div': {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
            }
        },
        '& .rating-date-text': {
            flexDirection: 'column',
            gap: 12,
            alignItems: 'flex-start',
        }
    }
})
const CustomTextField =styled(TextField)({
    '& .MuiOutlinedInput-root':{
        borderRadius: "16px",
    }
})

const CustomSelect =styled(Select)({
  borderRadius: "16px",
    '& .MuiSelect-outlined':{
        borderRadius: "16px",

    }
})
const CustomTabs = styled(Tabs)({
    '& .MuiTab-textColorInherit': {
        color: '#011342',
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    '& .MuiTabs-indicator': {
        display: 'none'
    },
    '& .MuiTab-root': {
        maxWidth: "40%"
    },
    '& .MuiTab-root.Mui-selected': {
        maxWidth: "40%",
        borderBottom: '2px solid #364BA0'
    },
})

const iconMapping: { [key in 'twitter' | 'facebook' | 'medium' | 'linkedin' | 'instagram' | 'youtube']?: string } = {
    twitter,
    facebook,
    medium,
    instagram,
    linkedin,
    youtube,
};

const CustomDialog =styled(Dialog)({
    '& .MuiDialog-paperFullWidth':{
        padding:"24px"
    },
    '& .MuiPaper-rounded':{
        borderRadius: "12px",
    }
})

const MonthSelect = ({ value, handleChange, webStyles }:any) => (
  <InputField style={webStyles.textField} variant="outlined">
    <Select
      value={value || ''}
      onChange={handleChange}
      displayEmpty
      style={webStyles.borderRadius}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      }}
    >
      <SelectMenu_style value="" disabled>Month</SelectMenu_style>
      {[
        'January', 'February', 'March', 'April', 'May', 'June',
        'July', 'August', 'September', 'October', 'November', 'December',
      ].map((month, idx) => (
        <SelectMenu_style key={idx} value={month}>{month}</SelectMenu_style>
      ))}
    </Select>
  </InputField>
);

const YearSelect = ({ value, handleChange, webStyles }:any) => (
  <InputField style={webStyles.textField} variant="outlined">
    <Select
      value={value}
      onChange={handleChange}
      displayEmpty
      style={webStyles.borderRadius}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        transformOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
      }}
    >
      <SelectMenu_style value="" disabled>Year</SelectMenu_style>
      {[...Array(50)].map((_, idx) => {
        const year = new Date().getFullYear() - idx;
        return (
          <SelectMenu_style key={year} value={year}>{year}</SelectMenu_style>
        );
      })}
    </Select>
  </InputField>
);

const DateSelection = ({ label, monthValue, yearValue, handleMonthChange, handleYearChange, webStyles }:any) => (
  <Grid item xs={12} sm={12}>
    <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>{label}*</Typography>
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <MonthSelect value={monthValue} handleChange={handleMonthChange} webStyles={webStyles} />
      </Grid>
      <Grid item xs={6}>
        <YearSelect value={yearValue} handleChange={handleYearChange} webStyles={webStyles} />
      </Grid>
    </Grid>
  </Grid>
);

export const CustomModal = ({
    open,
    onClose,
    title,
    content,
    inputPlaceholder,
    inputValue,
    onInputChange,
    onKeyPress,
    chipsArray,
    onDeleteChip,
    onSave,
    cancelText = 'Cancel',
    saveText = 'Save',
  }:any)=> {
    return (
      <CustomDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <Box style={{...webStyles.flexStyles,marginBottom:"32px"}}>
          <Typography style={webStyles.mainHeadingBold}>{title}</Typography>
            <CloseIcon style={{width:"24px", height:"24px", alignItems:"center",cursor:"pointer"}} onClick={onClose} />
        </Box>
        
          <Typography style={{...webStyles.bold16Text,fontWeight:600}}>
            {content}
          </Typography>
          <FormControl fullWidth variant="outlined" margin="normal">
            <CustomTextField
              placeholder={inputPlaceholder}
              variant="outlined"
              value={inputValue}
              onChange={onInputChange}
              onKeyPress={onKeyPress}
              inputProps={{
                maxLength: 32,
                style: { fontSize: '16px', fontWeight: 500, fontFamily: 'Rubik', color: '#000000' },
              }}
            />
          </FormControl>
          <Box display="flex" flexWrap="wrap" marginTop="16px">
            {chipsArray.map((chip: boolean | React.ReactChild | React.ReactFragment | React.ReactPortal | null | undefined, index: React.Key | undefined) => (
            <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
              <Box key={index} style={webStyles.chipStyle}>
                <Typography style={webStyles.skillStyle}>{chip}</Typography>
                <IconButton size="small" onClick={() => onDeleteChip(chip)}>
                  <CloseIcon fontSize="small" />
                </IconButton>
              </Box>
              </Box>
            ))}
          </Box>
        
        <DialogActions>
          <Button onClick={onClose} style={{...webStyles.btnStyleTans, minWidth: '100px' }}>{cancelText}</Button>
          <Button onClick={onSave} style={{...webStyles.btnStylesBlue, minWidth: '100px'}}>{saveText}</Button>
        </DialogActions>
      </CustomDialog>
    );}
    
export default class FreelancerCompleteProfile extends FreelancerCompleteProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    renderArticles = () => {
        const displayedArticles = this.state.freelancerCompleteData?.data?.attributes.profile_data.achievements.public_relation_meterials || [];

        return displayedArticles.map((article, index) => (
            <Box key={index} style={{ display: "flex", marginTop: "24px", justifyContent:"space-between" }}>
                <Box>
                    <Typography style={webStyles.subBoldHeading}>{article.organization_name}</Typography>
                    <Typography style={{ ...webStyles.subText, fontSize: "16px", marginTop: "16px" }}>{article.description}</Typography>
                </Box>
    
                {article.link && (
                    <Link href={article.link} 
                    target="_blank"
                    style={{ ...webStyles.educationDateStyle, fontSize: "14px", color: "#364BA0" }}>
                        Link<span style={{ marginLeft: "4px", fontSize: "14px" }}>↗</span>
                    </Link>
                )}
            </Box>
        ));
    };

    renderSeminars = () => {
      const displayedArticles = this.state.freelancerCompleteData?.data?.attributes.profile_data.achievements.engagements_and_seminars || [];

      return displayedArticles.map((article, index) => (
          <Box key={index} style={{ display: "flex", marginTop: "24px", justifyContent:"space-between" }}>
              <Box>
                  <Typography style={webStyles.subBoldHeading}>{article.title}</Typography>
                  <Typography style={{ ...webStyles.subText, fontSize: "16px", marginTop: "16px" }}>{article.description}</Typography>
              </Box>
  
              {article.link && (
                  <Link href={article.link} 
                  target="_blank"
                  style={{ ...webStyles.educationDateStyle, fontSize: "14px", color: "#364BA0" }}>
                      Link<span style={{ marginLeft: "4px", fontSize: "14px" }}>↗</span>
                  </Link>
              )}
          </Box>
      ));
  };
    renderEditIndustryFocus = ()=>{
      return (
        <CustomDialog 
        open={this.state.editIndustryFocusModalOpen}
          onClose={this.handleEditModalCloseIndustryFocus}
          maxWidth="md"
          fullWidth
          >
          <Box style={{ ...webStyles.flexStyles, marginBottom: '32px' }}>
            <Typography style={webStyles.mainHeadingBold}>Edit Industry Focus</Typography>
            <CloseIcon
              style={{ width: '24px', height: '24px', alignItems: 'center', cursor: 'pointer' }}
              onClick={this.handleEditModalCloseIndustryFocus}
            />
          </Box>
    
    
          <Typography style={{ ...webStyles.bold16Text, fontWeight: 600 }}>Your Industry Focus</Typography>
          <FormControl fullWidth variant="outlined">
            <CustomSelect
              value={this.state.selectedIndustries}
              defaultValue=""
              displayEmpty
              name="industryFocus"
              onChange={this.handleIndustrySelect}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="" disabled>
                Add your Industries
              </MenuItem>
              {this.state.industryFocusArray.map((industry: any) => (
                <MenuItem key={industry.id} value={industry.name}>
                  {industry.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
    
          <Box display="flex" flexWrap="wrap" marginTop="16px">
            {this.state.selectedIndustries.map((industry: string, index: number) => (
              <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                <Box style={webStyles.chipStyle}>
                  <Typography style={webStyles.skillStyle}>{industry}</Typography>
                  <IconButton size="small" onClick={() => this.handleDeleteIndustry(industry)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
    
          <DialogActions>
            <Button onClick={this.handleEditModalCloseIndustryFocus} style={{ ...webStyles.btnStyleTans, minWidth: '100px' }}>
              CANCEL
            </Button>
            <Button onClick={this.handleSaveIndustry} style={{ ...webStyles.btnStylesBlue, minWidth: '100px' }}>
              SAVE
            </Button>
          </DialogActions>
        </CustomDialog>
      );
    }
    renderEditServices=()=>{
      return (
        <CustomDialog 
        open={this.state.editServicesModalOpen}
          onClose={this.handleEditModalCloseServices}
          maxWidth="md"
          fullWidth
          >
          <Box style={{ ...webStyles.flexStyles, marginBottom: '32px' }}>
            <Typography style={webStyles.mainHeadingBold}>Edit Services</Typography>
            <CloseIcon
              style={{ width: '24px', height: '24px', alignItems: 'center', cursor: 'pointer' }}
              onClick={this.handleEditModalCloseServices}
            />
          </Box>
    
    
          <Typography style={{ ...webStyles.bold16Text, fontWeight: 600 }}>Your Services</Typography>
          <FormControl fullWidth variant="outlined">
            <CustomSelect
              value={this.state.selectedServices}
              defaultValue=""
              displayEmpty
              name="industryFocus"
              onChange={this.handleServiceSelect}
              MenuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            >
              <MenuItem value="" disabled>
              Add your Services
              </MenuItem>
              {this.state.servicesArray.map((service: any) => (
                <MenuItem key={service.id} value={service.name}>
                  {service.name}
                </MenuItem>
              ))}
            </CustomSelect>
          </FormControl>
    
          <Box display="flex" flexWrap="wrap" marginTop="16px">
            {this.state.selectedServices.map((Service: string, index: number) => (
              <Box key={index} style={{ margin: '12px 10px 10px 10px' }}>
                <Box style={webStyles.chipStyle}>
                  <Typography style={webStyles.skillStyle}>{Service}</Typography>
                  <IconButton size="small" onClick={() => this.handleDeleteService(Service)}>
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Box>
              </Box>
            ))}
          </Box>
          <DialogActions>
            <Button onClick={this.handleEditModalCloseServices} style={{ ...webStyles.btnStyleTans, minWidth: '100px' }}>
              CANCEL
            </Button>
            <Button onClick={this.handleSaveService} style={{ ...webStyles.btnStylesBlue, minWidth: '100px' }}>
              SAVE
            </Button>
          </DialogActions>
        </CustomDialog>
      );
    }

    renderEditCertificate = () => {
      return (
        <CustomDialog
          open={this.state.editCertificateModalOpen}
          onClose={this.handleEditModalCloseCertificate}
          maxWidth="md"
          fullWidth
        >
          <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
            <Typography style={{ ...webStyles.mainHeadingBold, fontSize: "24px" }}>Edit Certificate</Typography>
            <CloseIcon
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={this.handleEditModalCloseCertificate}
            />
          </div>
          <Grid container spacing={3}>

             <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Certificate Name *</Typography>
              <FormControl variant="outlined" fullWidth>
                <CustomTextField
                  placeholder="Ex: IPR Certification"
                  name="title"
                  type="text"
                  variant="outlined"
                  value={this.state.certificateName}
                  onChange={this.handleChangeCertificateName}
                  inputProps={{
                    "aria-label": "title",
                    style: {
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Rubik",
                      color: "#000000",
                    },
                    maxLength: 50,
                  }}
                />
              </FormControl>
              </Grid>
              <Grid item xs={6}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Certificate Provider *</Typography>
              <FormControl variant="outlined" fullWidth>
                <CustomTextField
                  placeholder="Ex: ICAI"
                  name="title"
                  type="text"
                  variant="outlined"
                  value={this.state.certificateProvider}
                  onChange={this.handleChangeCertificateProvider }
                  inputProps={{
                    "aria-label": "title",
                    style: {
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Rubik",
                      color: "#000000",
                    },
                    maxLength: 50,
                  }}
                />
              </FormControl>
              </Grid>
              </Grid>
            </Grid>
    
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Certification ID  (Optional)</Typography>
              <FormControl variant="outlined" fullWidth>
                <CustomTextField
                  placeholder="Enter Certification ID here"
                  name="title"
                  type="text"
                  variant="outlined"
                  value={this.state.certificationID}
                  onChange={this.handleChangeCertificationID}
                  inputProps={{
                    "aria-label": "title",
                    style: {
                      fontWeight: 500,
                      fontSize: "16px",
                      fontFamily: "Rubik",
                      color: "#000000",
                    },
                    maxLength: 50,
                  }}
                />
              </FormControl>
              </Grid>
              <Grid item xs={6}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Certification URL (Optional)</Typography>
              <FormControl variant="outlined" fullWidth>
                <CustomTextField
                  placeholder="Enter URL here"
                  name="title"
                  type="text"
                  variant="outlined"
                  value={this.state.CertificationURL}
                  onChange={this.handleChangeCertificationURL}
                  inputProps={{
                    "aria-label": "title",
                    style: {
                      fontWeight: 500,
                      fontSize: "16px",
                      color: "#000000",
                      fontFamily: "Rubik",
                    },
                    maxLength: 50,
                  }}
                />
              </FormControl>
              </Grid>
              </Grid>
            </Grid>
    
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={6}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Issue Date *</Typography>
              <InputField style={webStyles.textField} variant="outlined">
                    <OutlinedInput
                      type="date"
                      data-test-id="Issue_Date_handleLicenseChange"
                      value={this.state.certificateIssueDate}
                      onChange={this.handleChangeCertificateIssueDate}
                      style={webStyles.borderRadius}
                    />
                  </InputField>
                </Grid>
                <Grid item xs={6}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Expiration Date (if any)</Typography>
                <InputField style={webStyles.textField} variant="outlined">
                <OutlinedInput
                      type="date"
                      data-test-id="Issue_Date_handleLicenseChange"
                      value={this.state.certificateExpirationDate}
                      onChange={this.handleCertificateExpirationDateChange}
                      style={webStyles.borderRadius}
                    />
                </InputField>
                </Grid>
              </Grid>
            </Grid>
    
          </Grid>
    
          <DialogActions style={{ marginTop: "32px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <div>
              <Link style={webStyles.deleteBtn}>DELETE</Link>
            </div>
            <div>
              <Button onClick={this.handleEditModalCloseCertificate} style={{ ...webStyles.btnStyleTans, minWidth: "100px", marginRight: "10px" }}>
                Cancel
              </Button>
              <Button 
              data-test-id="handleSaveCertificate"
              onClick={()=>this.handleSaveCertificate(this.state.currentEditingCertificationId)} 
              style={{ ...webStyles.btnStylesBlue, minWidth: "100px" }}>
                Save
              </Button>
            </div>
          </DialogActions>
        </CustomDialog>
      );
    };

    renderAddLanguages = () => {
      const freelancelanguages = this.state.freelancerCompleteData?.data?.attributes.profile_data.language_expertises || []
      return (
        <CustomDialog
          open={this.state.addLanguageModalOpen}
          onClose={this.handleAddModalCloseLanguage}
          maxWidth="sm"
          fullWidth
        >
          <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
            <Typography style={webStyles.mainHeadingBold}>Add Language</Typography>
            <CloseIcon
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={this.handleAddModalCloseLanguage}
            />
          </div>
          <Grid container spacing={2} style={{ marginBottom: "16px", display: "flex", alignItems: "end" }}>
  {/* Language Selection */}
  <Grid
    item xs={12} sm={6}
    style={{ marginBottom: this.state.addProficiencyError ? "25px" : "0px" }}
  >
    <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
      Language*
    </Typography>
    <FormControl fullWidth variant="outlined" error={!!this.state.addLanguagesError}>
      <CustomSelect
        multiline
        value={this.state.addLanguages}
        onChange={this.handleChangeAddLanguages}
        MenuProps={{
          transformOrigin: { vertical: "top", horizontal: "left" },
          anchorOrigin: { horizontal: "left", vertical: "bottom" },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="">Select Language</MenuItem>
        {["English", "Hindi", "Chinese", "German", "French"].map((language) => {
          const isDisabled = freelancelanguages?.some((entry) => entry.name === language);
          return (
            <MenuItem key={language} value={language} disabled={isDisabled}>
              {language}
            </MenuItem>
          );
        })}
      </CustomSelect>
      {this.state.addLanguagesError && (
        <FormHelperText style={{ fontSize: "14px", fontWeight: 600 }}>
          {this.state.addLanguagesError}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>

  {/* Proficiency Selection */}
  <Grid
    item xs={12} sm={6}
    style={{ marginBottom: this.state.addLanguagesError ? "25px" : "0px" }}
  >
    <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
      Proficiency*
    </Typography>
    <FormControl fullWidth variant="outlined" error={!!this.state.addProficiencyError}>
      <CustomSelect
        multiline
        value={this.state.addProficiency}
        onChange={this.handleChangeAddProficiency}
        MenuProps={{
          anchorOrigin: { vertical: "bottom", horizontal: "left" },
          transformOrigin: { vertical: "top", horizontal: "left" },
          getContentAnchorEl: null,
        }}
      >
        <MenuItem value="">Select Proficiency</MenuItem>
        {["Intermediate", "Advanced", "Expert"].map((proficiency) => {
          const isDisabled = freelancelanguages.some(
            (entry) => entry.name === this.state.addLanguages && entry.proficiency === proficiency
          );
          return (
            <MenuItem key={proficiency} value={proficiency} disabled={isDisabled}>
              {proficiency}
            </MenuItem>
          );
        })}
      </CustomSelect>
      {this.state.addProficiencyError && (
        <FormHelperText style={{ fontSize: "14px", fontWeight: 600 }}>
          {this.state.addProficiencyError}
        </FormHelperText>
      )}
    </FormControl>
  </Grid>
</Grid>
    
          <DialogActions style={{ marginTop: "48px" }}>
            <Button onClick={this.handleAddModalCloseLanguage} style={{ ...webStyles.btnStyleTans, minWidth: "100px" }}>
              Cancel
            </Button>
            <Button
              onClick={this.handleSaveAddLanguageApi}
              style={{ ...webStyles.btnStylesBlue, minWidth: "100px" }}
            >
              Save
            </Button>
          </DialogActions>
        </CustomDialog>
      );
    };
  
    renderAddProjects  = () => {
      return (
        <CustomDialog
        fullWidth
        open={this.state.addProjectsModalOpen}
          onClose={this.handleAddModalCloseProjects}
          maxWidth="md">
          <div 
          style={{ ...webStyles.flexStyles, 
          marginBottom: "32px" 
          }}>
            <Typography style={webStyles.mainHeadingBold}>
              Add Projects
            </Typography>
            <CloseIcon
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={this.handleAddModalCloseProjects}/>
              </div>
          <Grid 
          container 
          spacing={3}>
          <Grid 
          item 
          xs={12}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600, 
            marginBottom: "12px" }}>
              Title*
            </Typography>
            <FormControl 
            variant="outlined" 
            error={!!this.state.addProjectTitleError}
            fullWidth>
              <CustomTextField placeholder="Ex: ABC Project" name="degree" type="text"
                onChange={this.handleAddChangeProjectTitle}
                variant="outlined"
                value={this.state.addProjectTitle}
                inputProps={{
                  "aria-label": "degree",
                  style: {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "16px",
                    fontFamily: "Rubik",
                  },
                  maxLength:32}}
              />
                   {this.state.addProjectTitleError
                    && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600
            }}
            >{this.state.addProjectTitleError}</FormHelperText>}
              </FormControl></Grid>
    
            <Grid item
             xs={12} 
             sm={6}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600, 
            marginBottom: "12px" }}>
              Start Date* </Typography>
            <InputField 
            style={webStyles.textField} 
            variant="outlined">
                  <Select
                    value={this.state.addStartProjectDate}
                    style={webStyles.borderRadius} MenuProps={{
                        transformOrigin: {
                          horizontal: "left",
                            vertical: "bottom",
                        },
                        anchorOrigin: {
                          horizontal: "left",
                          vertical: "bottom",
                        },
                      getContentAnchorEl: null
                    }}
                    onChange={this.handleAddStartProjectDate}
                    data-test-id="ExendYearfield" displayEmpty
                  ><SelectMenu_style 
                  value=""
                   disabled>
                      Year </SelectMenu_style>
                    {[...Array(50)].map((_, idx) => {
                      const year = new Date().getFullYear() - idx;
                      return (
                        <SelectMenu_style key={year} value={year}>{year} </SelectMenu_style>
                      );
                    })
                    }</Select></InputField>
         {this.state.addStartProjectDateError && <FormHelperText
            style={{
              fontSize:'14px',
              color:'red',
              marginLeft: '14px'
            }}
            >{this.state.addStartProjectDateError}</FormHelperText>}
                     </Grid>

          <Grid 
          item 
          xs={12}
           sm={6}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600,
             marginBottom: "12px"
              }}>End Date*
              </Typography>
            <InputField style={webStyles.textField} variant="outlined"> <Select
                    onChange={this.handleAddEndProjectDate}
                    displayEmpty
                    MenuProps={{
                      anchorOrigin: {
                          horizontal: "left",
                          vertical: "bottom"
                      },
                      transformOrigin: {
                          horizontal: "left",
                        vertical: "bottom"
                      },
                      getContentAnchorEl: null
                    }}
                    data-test-id="ExendYearfield"
                    style={webStyles.borderRadius}
                    value={this.state.addEndProjectDate}
                  ><SelectMenu_style 
                  value="" 
                  disabled>Year</SelectMenu_style>
                    {[...Array(50)].map((_, idx) => {
                      const year = new Date().getFullYear() - idx;
                      return (
                        <SelectMenu_style key={year} value={year}>
                          {year}
                        </SelectMenu_style>
                      )})}
                  </Select></InputField>
          {this.state.addEndProjectDateError && <FormHelperText
            style={{
              fontSize:'14px',
                 color:'red',
              marginLeft: '14px'
            }}
            >{this.state.addEndProjectDateError}</FormHelperText>}
                  </Grid>

          <Grid 
          item xs={12}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600,
             marginBottom: "12px" 
             }}>
              Description*
            </Typography><FormControl variant="outlined" fullWidth
            >
              <CustomTextField
                name="description"
                type="text"
                rows={4}
                placeholder="Describe your Project"
                value={this.state.description}
                variant="outlined"
                multiline
                onChange={this.handleChangeDescription}
                inputProps={{
                  "aria-label": "description",
                  style: {
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Rubik",
                  },
                  maxLength: 256,
                }} />
                {this.state.addDescriptionError && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600,
              color:'red',
              marginLeft: '14px'
            }}
            >{this.state.addDescriptionError}</FormHelperText>}

            </FormControl>
          </Grid>
          <Grid
             item xs={12}
          >    <Typography 
          style={{ ...webStyles.bold16Text, 
          fontWeight: 600,
           marginBottom: "12px"
            }}>Project Document
            </Typography>
               <Box
              style={{
                border: "2px dashed #979797",
                borderRadius: "8px",
                padding: "24px 20px 34px",
                cursor: "pointer",
                textAlign: "center",
                width: 'auto',
                maxWidth: 'auto',}}
              onClick={() => document.getElementById('file-upload-input')?.click()} >
              <div className="imgCenter"
                style={{ display: "flex", justifyContent: "center",alignItems: "center",}}>
                <img  src={uploadDoc}
                  alt="upload"  style={{ width: "29px",
                    height: "29px",}}
                /> <input
                id="file-upload-input"
                multiple onChange={this.handleFileChange}   />
              </div><Typography
                style={{ marginTop: '24px',
                  fontFamily: 'Rubik',
                  color: '#535353',
                  fontWeight: 600,
                  fontSize: '16px',}}
              >Attach </Typography>
              <Typography
                style={{
                  marginTop: '12px',
                  fontSize: '16px',
                  fontFamily: 'Rubik',
                  color: '#535353',
                }}
              >
                Max 5 files, 5 MB per file
              </Typography>
            </Box>
          </Grid>
        </Grid>
        
        <DialogActions style={{ marginTop: "48px" }}>
         
            <Button 
            onClick={this.handleAddModalCloseEducation} 
            style={{ ...webStyles.btnStyleTans, 
            minWidth: "100px", 
            marginRight: "10px" }}>
              Cancel</Button>
            <Button 
            onClick={this.handleSaveAddProjects}
            data-test-id="handleEducationSave"
            style={{ 
              ...webStyles.btnStylesBlue, 
            minWidth: "100px" }}>
              Save </Button>
          </DialogActions></CustomDialog>
      )};

    renderAddEducations = () => {
      return (
        <CustomDialog
          open={this.state.addEducationModalOpen}
          onClose={this.handleAddModalCloseEducation}
          maxWidth="md"
          fullWidth
        >
          <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
            <Typography style={webStyles.mainHeadingBold}>Add Education</Typography>
            <CloseIcon
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={this.handleAddModalCloseEducation}/></div>
          <Grid container 
          spacing={3}>
          <Grid 
          item 
          xs={12}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600, 
            marginBottom: "12px" }}>
              Degree*
            </Typography>
            <FormControl 
            variant="outlined" 
            error={!!this.state.addDegreeError}
            fullWidth>
              <CustomTextField placeholder="Ex: BA.LLB" name="degree" type="text"
                onChange={this.handleChangeDegree}
                variant="outlined"
                multiline
                value={this.state.degree}
                inputProps={{
                  "aria-label": "degree",
                  style: {
                    color: "#000000",
                    fontWeight: 500,
                    fontSize: "16px",
                    fontFamily: "Rubik",
                  },
                  maxLength:32}}
              />
                   {this.state.addDegreeError && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600
            }}
            >{this.state.addDegreeError}</FormHelperText>}
              </FormControl></Grid>

          <Grid 
          item 
          xs={12} 
          sm={6}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600, 
            marginBottom: "12px" }}>
              University Name*</Typography>
            <FormControl 
            variant="outlined" 
            error={!!this.state.addUniversityNameError}
            fullWidth>
              <CustomTextField placeholder="Ex: Stanford"
                type="text"
                variant="outlined"
                name="universityName"
                multiline
                onChange={this.handleChangeUniversityName}
                value={this.state.universityName}
                inputProps={{
                  "aria-label": "universityName",
                  style: {
                    color: "#000000",
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Rubik",
                  },
                 }}/>
                    {this.state.addUniversityNameError && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600
            }}
            >{this.state.addUniversityNameError}</FormHelperText>}
            </FormControl> 

            </Grid>
          
          <Grid 
          item 
          xs={12} 
          sm={6}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600, 
            marginBottom: "12px" }}>
              Country* </Typography>
            <FormControl variant="outlined" 
           error={!!this.state.addCountryError}
            fullWidth><CustomTextField
            name="country"
            multiline
            type="text"
            onChange={this.handleChangeCountry}
            placeholder="Ex: USA"
            value={this.state.country}
            variant="outlined"
                inputProps={{
                  "aria-label": "country",
                  style: {
                    fontSize: "16px",
                    color: "#000000",
                    fontWeight: 500,
                    fontFamily: "Rubik",
                  },
                  maxLength: 32,
                }}
              />
              {this.state.addCountryError && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600
            }}
            >{this.state.addCountryError}</FormHelperText>}
            </FormControl> 
            </Grid>
            <Grid item
             xs={12} 
             sm={6}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600, 
            marginBottom: "12px" }}>
              Year Attended* </Typography>
            <InputField 
            style={webStyles.textField} 
            variant="outlined">
                  <Select
                    value={this.state.yearAttended}
                    style={webStyles.borderRadius} MenuProps={{
                        transformOrigin: {
                          horizontal: "left",
                            vertical: "bottom",
                        },
                        anchorOrigin: {
                          horizontal: "left",
                          vertical: "bottom",
                        },
                      getContentAnchorEl: null
                    }}
                    onChange={this.handleChangeYearAttended}
                    data-test-id="ExendYearfield" displayEmpty
                  ><SelectMenu_style 
                  value=""
                   disabled>
                      Year </SelectMenu_style>
                    {[...Array(50)].map((_, idx) => {
                      const year = new Date().getFullYear() - idx;
                      return (
                        <SelectMenu_style key={year} value={year}>{year} </SelectMenu_style>
                      );
                    })
                    }</Select></InputField>
         {this.state.addYearAttendedError && <FormHelperText
            style={{
              fontSize:'14px',
              color:'red',
              marginLeft: '14px'
            }}
            >{this.state.addYearAttendedError}</FormHelperText>}
                     </Grid>

          <Grid 
          item 
          xs={12}
           sm={6}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600,
             marginBottom: "12px"
              }}>Year Ended*</Typography>
            <InputField style={webStyles.textField} variant="outlined"> <Select
                    onChange={this.handleChangeYearEnded}
                    displayEmpty
                    MenuProps={{
                      anchorOrigin: {
                          horizontal: "left",
                          vertical: "bottom"
                      },
                      transformOrigin: {
                          horizontal: "left",
                        vertical: "bottom"
                      },
                      getContentAnchorEl: null
                    }}
                    data-test-id="ExendYearfield"
                    style={webStyles.borderRadius}
                    value={this.state.yearEnded}
                  ><SelectMenu_style 
                  value="" 
                  disabled>Year</SelectMenu_style>
                    {[...Array(50)].map((_, idx) => {
                      const year = new Date().getFullYear() - idx;
                      return (
                        <SelectMenu_style key={year} value={year}>
                          {year}
                        </SelectMenu_style>
                      )})}
                  </Select></InputField>
          {this.state.addYearEndedError && <FormHelperText
            style={{
              fontSize:'14px',
                 color:'red',
              marginLeft: '14px'
            }}
            >{this.state.addYearEndedError}</FormHelperText>}
                  </Grid>

          <Grid 
          item xs={12}>
            <Typography 
            style={{ ...webStyles.bold16Text, 
            fontWeight: 600,
             marginBottom: "12px" 
             }}>
              Description*
            </Typography><FormControl variant="outlined" fullWidth
            >
              <CustomTextField
                name="description"
                type="text"
                rows={4}
                placeholder="Describe your studies, awards, etc." value={this.state.description} variant="outlined" multiline
                onChange={this.handleChangeDescription} inputProps={{  "aria-label": "description",
                  style: {
                    fontFamily: "Rubik",
                    fontSize: "16px",
                    fontWeight: 500,
                    color: "#000000"}, maxLength: 256}} 
                    />
                {this.state.addDescriptionError &&
                 <FormHelperText style={{ fontSize:'14px',
                  marginLeft: '14px',
                  color:'red',
                  fontWeight: 600}}
            >
              {this.state.addDescriptionError}
              </FormHelperText>}
             </FormControl> </Grid></Grid>
        <DialogActions
         style={{ marginTop: "48px" }}> <Button 
            onClick={this.handleAddModalCloseEducation} style={{ ...webStyles.btnStyleTans, 
            minWidth: "100px", 
            marginRight: "10px" }}>
              Cancel</Button>
            <Button 
            onClick={this.handleAddEducationApiCall} 
            data-test-id="handleEducationSave"
            style={{ 
              ...webStyles.btnStylesBlue, 
            minWidth: "100px" }}>
              Save </Button>
          </DialogActions></CustomDialog>
      )};

    renderEditEducations = () => {
      return (
        <CustomDialog
          open={this.state.editEducationModalOpen}
          onClose={this.handleEditModalCloseEducation}
          maxWidth="md"
          fullWidth
        >
          <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
            <Typography style={webStyles.mainHeadingBold}>Edit Education</Typography>
            <CloseIcon
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={this.handleEditModalCloseEducation}
            />
          </div>
          
          <Grid container spacing={3}>
          <Grid item xs={12}>
            <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
              Degree*
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <CustomTextField
                placeholder="Ex: BA.LLB"
                name="degree"
                type="text"
                variant="outlined"
                value={this.state.degree}
                onChange={this.handleChangeDegree}
                inputProps={{
                  "aria-label": "degree",
                  style: {
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Rubik",
                    color: "#000000"
                  },
                  maxLength: 32
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
              University Name*
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <CustomTextField
                placeholder="Ex: Stanford"
                name="universityName"
                type="text"
                variant="outlined"
                value={this.state.universityName}
                onChange={this.handleChangeUniversityName}
                inputProps={{
                  "aria-label": "universityName",
                  style: {
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Rubik",
                    color: "#000000"
                  },
                }}
              />
            </FormControl>
          </Grid>
          
          <Grid item xs={12} sm={6}>
            <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
              Country*
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <CustomTextField
                placeholder="Ex: USA"
                name="country"
                type="text"
                variant="outlined"
                value={this.state.country}
                onChange={this.handleChangeCountry}
                inputProps={{
                  "aria-label": "country",
                  style: {
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Rubik",
                    color: "#000000"
                  },
                  maxLength: 32
                }}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
              Year Attended*
            </Typography>
            <InputField style={webStyles.textField} variant="outlined">
                  <Select
                    value={this.state.yearAttended}
                    onChange={this.handleChangeYearAttended}
                    data-test-id="ExendYearfield"
                    displayEmpty
                    style={webStyles.borderRadius}
                    MenuProps={{
                        transformOrigin: {
                            vertical: "bottom",
                            horizontal: "left"
                        },
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left"
                        },
                      getContentAnchorEl: null
                    }}
                  >
                    <SelectMenu_style value="" disabled>
                      Year
                    </SelectMenu_style>
                    {[...Array(50)].map((_, idx) => {
                      const year = new Date().getFullYear() - idx;
                      return (
                        <SelectMenu_style key={year} value={year}>
                          {year}
                        </SelectMenu_style>
                      );
                    })}
                  </Select>
                </InputField>
          </Grid>

          <Grid item xs={12} sm={6}>
            <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
              Year Ended*
            </Typography>
            <InputField style={webStyles.textField} variant="outlined">
                  <Select
                    value={this.state.yearEnded}
                    onChange={this.handleChangeYearEnded}
                    data-test-id="ExendYearfield"
                    displayEmpty
                    MenuProps={{
                      anchorOrigin: {
                          horizontal: "left",
                          vertical: "bottom"
                      },
                      transformOrigin: {
                          horizontal: "left",
                        vertical: "bottom"
                      },
                      getContentAnchorEl: null
                    }}
                    style={webStyles.borderRadius}
                  >
                    <SelectMenu_style value="" disabled>
                      Year
                    </SelectMenu_style>
                    {[...Array(50)].map((_, idx) => {
                      const year = new Date().getFullYear() - idx;
                      return (
                        <SelectMenu_style key={year} value={year}>
                          {year}
                        </SelectMenu_style>
                      );
                    })}
                  </Select>
                </InputField>
          </Grid>

          <Grid item xs={12}>
            <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
              Description*
            </Typography>
            <FormControl variant="outlined" fullWidth>
              <CustomTextField
                placeholder="Describe your studies, awards, etc."
                name="description"
                type="text"
                variant="outlined"
                multiline
                rows={4}
                value={this.state.description}
                onChange={this.handleChangeDescription}
                inputProps={{
                  "aria-label": "description",
                  style: {
                    fontSize: "16px",
                    fontWeight: 500,
                    fontFamily: "Rubik",
                    color: "#000000"
                  },
                  maxLength: 256
                }}
              />
            </FormControl>
          </Grid>
        </Grid>

          <DialogActions style={{ marginTop: "48px" }}>
         
            <Button onClick={this.handleEditModalCloseEducation} style={{ ...webStyles.btnStyleTans, minWidth: "100px", marginRight: "10px" }}>
              Cancel
            </Button>
            <Button 
            data-test-id="handleEducationSave"
            onClick={()=>this.handleEducationSave(this.state.currentEditingEducationId)} 
            style={{ ...webStyles.btnStylesBlue, minWidth: "100px" }}>
              Save
            </Button>
          </DialogActions>
        </CustomDialog>
      );
    };

    renderEditExperience = () => {
      const { 
        editExperienceModalOpen, 
        experienceTitle, 
        experienceCompany, 
        experienceStartMonth, 
        experienceStartYear, 
        experienceEndMonth, 
        experienceEndYear, 
        experienceCurrentlyWorking,
        experienceDescription
      } = this.state;
    
      return (
        <CustomDialog
          open={editExperienceModalOpen}
          onClose={this.handleEditModalCloseExperience}
          maxWidth="md"
          fullWidth
        >
          <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
            <Typography style={{ ...webStyles.mainHeadingBold, fontSize: "24px" }}>
              Edit Experience
            </Typography>
            <CloseIcon
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={this.handleEditModalCloseExperience}
            />
          </div>
    
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>
                Title*
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <CustomTextField
                  placeholder="Ex: IP Lawyer"
                  name="title"
                  type="text"
                  variant="outlined"
                  value={experienceTitle}
                  onChange={this.handleChangeExperienceTitle}
                  inputProps={{
                    "aria-label": "title",
                    style: {
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Rubik",
                      color: "#000000",
                    },
                    maxLength: 50,
                  }}
                />
              </FormControl>
            </Grid>
    
            <Grid item xs={12}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>
                Company*
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <CustomTextField
                  placeholder="Ex: Baker McKenzie LLP"
                  name="company"
                  type="text"
                  variant="outlined"
                  value={experienceCompany}
                  onChange={this.handleChangeExperienceCompany}
                  inputProps={{
                    "aria-label": "company",
                    style: {
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Rubik",
                      color: "#000000",
                    },
                    maxLength: 50,
                  }}
                />
              </FormControl>
            </Grid>
    
            <DateSelection
              label="Start Month"
              monthValue={experienceStartMonth}
              yearValue={experienceStartYear}
              handleMonthChange={this.handleChangeStartMonth}
              handleYearChange={this.handleChangeStartYear}
              webStyles={webStyles}
            />
    
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={experienceCurrentlyWorking}
                    onChange={this.handleAddCurrentlyWorkingToggle}
                    name="currentlyWorking"
                    color="primary"
                  />
                }
                label="I am currently working in this role"
              />
            </Grid>
    
            <DateSelection
              label="End Month"
              monthValue={experienceEndMonth}
              yearValue={experienceEndYear}
              handleMonthChange={this.handleChangeEndMonth}
              handleYearChange={this.handleChangeEndYear}
              webStyles={webStyles}
            />
    
            <Grid item xs={12}>
              <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>
                Description*
              </Typography>
              <FormControl variant="outlined" fullWidth>
                <CustomTextField
                  placeholder="Describe your experience"
                  name="description"
                  type="text"
                  variant="outlined"
                  multiline
                  rows={4}
                  value={experienceDescription}
                  onChange={this.handleChangeExperienceDescription}
                  inputProps={{
                    "aria-label": "description",
                    style: {
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Rubik",
                      color: "#000000",
                    },
                    maxLength: 300,
                  }}
                />
              </FormControl>
            </Grid>
          </Grid>
    
          <DialogActions
            style={{
              marginTop: "32px",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Button
              onClick={this.handleEditModalCloseExperience}
              style={{ ...webStyles.btnStyleTans, minWidth: "100px", marginRight: "10px" }}
            >
              Cancel
            </Button>
            <Button
              data-test-id="handleSaveExperience"
              onClick={()=>this.handleSaveExperience(this.state.currentEditingExperienceId)}
              style={{ ...webStyles.btnStylesBlue, minWidth: "100px" }}
            >
              Save
            </Button>
          </DialogActions>
        </CustomDialog>
      );
    };
    
    
    renderAddCertificate = () => {
      return (
        <CustomDialog
          open={this.state.addCertificateModalOpen}
          onClose={this.handleAddModalCloseCertificate}
          maxWidth="md"
          fullWidth
        >
          <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
            <Typography style={{ ...webStyles.mainHeadingBold, fontSize: "24px" }}>Add Certificate</Typography>
            <CloseIcon
              style={{ width: "24px", height: "24px", cursor: "pointer" }}
              onClick={this.handleAddModalCloseCertificate}
            />
          </div>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Certificate Name *</Typography>
                  <FormControl variant="outlined" fullWidth error={!!this.state.addCertificateNameError}
                  >
                    <CustomTextField
                      placeholder="Ex: IPR Certification"
                      name="title"
                      type="text"
                      variant="outlined"
                      value={this.state.AddCertificateName}
                      onChange={this.handleChangeAddCertificateName}
                      inputProps={{
                        "aria-label": "title",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000",
                        },
                        maxLength: 50,
                      }}
                    />
                        {this.state.addCertificateNameError && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600
            }}
            >{this.state.addCertificateNameError}</FormHelperText>}
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Certificate Provider *</Typography>
                  <FormControl variant="outlined" fullWidth error={!!this.state.addCertificateProviderError}>
                    <CustomTextField
                      placeholder="Ex: ICAI"
                      name="provider"
                      type="text"
                      variant="outlined"
                      value={this.state.AddCertificateProvider}
                      onChange={this.handleChangeAddCertificateProvider}
                      inputProps={{
                        "aria-label": "provider",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000",
                        },
                        maxLength: 50,
                      }}
                    />
            {this.state.addCertificateProviderError && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600
            }}
            >{this.state.addCertificateProviderError}</FormHelperText>}
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
    
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                  <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Certification ID (Optional)</Typography>
                  <FormControl variant="outlined" fullWidth>
                    <CustomTextField
                      placeholder="Enter Certification ID here"
                      name="certificationId"
                      type="text"
                      variant="outlined"
                      value={this.state.AddCertificationID}
                      onChange={this.handleChangeAddCertificationID}
                      inputProps={{
                        "aria-label": "certificationId",
                        style: {
                          fontSize: "16px",
                          fontWeight: 500,
                          fontFamily: "Rubik",
                          color: "#000000",
                        },
                        maxLength: 50,
                      }}
                    />
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Certification URL (Optional)</Typography>
                  <FormControl variant="outlined" fullWidth>
                    <CustomTextField
                      placeholder="Enter URL here"
                      name="certificationUrl"
                      type="text"
                      variant="outlined"
                      value={this.state.AddCertificationURL}
                      onChange={this.handleChangeAddCertificationURL}
                      inputProps={{
                        "aria-label": "certificationUrl",
                        maxLength: 50,
                        style: {
                          color: "#000000",
                          fontSize: "16px",
                          fontFamily: "Rubik",
                          fontWeight: 500,
                        },
                      }}
                    />
                       {this.state.addCertificationURLError
                        && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600,
              color:'red',
            }}
            >{this.state.addCertificationURLError
            }</FormHelperText>}
                    
                  </FormControl>
                </Grid>
              </Grid>
            </Grid>
    
            <Grid item xs={12} sm={12}>
              <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                  <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Issue Date *</Typography>
                  <InputField style={webStyles.textField} variant="outlined">
                    <OutlinedInput
                      type="date"
                      value={this.state.AddCertificateIssueDate}
                      onChange={this.handleChangeAddCertificateIssueDate}
                      style={webStyles.borderRadius}
                    />
                  </InputField>
                  {this.state.addCertificateIssueDateError && (
                    <FormHelperText style={{ fontSize: "14px", color: "red", marginLeft: '14px' }}>
                      {this.state.addCertificateIssueDateError}
                    </FormHelperText>
                  )}
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Expiration Date (if any)</Typography>
                  <InputField style={webStyles.textField} variant="outlined">
                    <OutlinedInput
                      type="date"
                      value={this.state.AddCertificateExpirationDate}
                      onChange={this.handleChangeAddCertificateExpirationDate}
                      style={webStyles.borderRadius}
                    />
                  </InputField>
                  {this.state.addCertificateExpirationDateError && (
                <FormHelperText style={{ fontSize: "14px", marginLeft: '14px',color: "red" }}>
                  {this.state.addCertificateExpirationDateError}
                </FormHelperText>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
    
          <DialogActions style={{ marginTop: "32px", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
            <Button onClick={this.handleAddModalCloseCertificate} style={{ ...webStyles.btnStyleTans, minWidth: "100px", marginRight: "10px" }}>
              Cancel
            </Button>
            <Button onClick={this.handleSaveAddCertificate} style={{ ...webStyles.btnStylesBlue, minWidth: "100px" }}>
              Add
            </Button>
          </DialogActions>
        </CustomDialog>
      );
    };
    
    renderPublications = () => {
        const displayedPublications = this.state.freelancerCompleteData?.data?.attributes.profile_data.achievements.publications || [];
        return displayedPublications.map((publications, index) => (
            <Box key={index} style={{ display: "flex", marginTop: "24px", justifyContent:"space-between" }}>
                <Box>
                    <Typography style={webStyles.subBoldHeading}>{publications.title}</Typography>
                    <Typography style={{ ...webStyles.subText, fontSize: "16px", marginTop: "16px" }}>{publications.description}</Typography>
                </Box>
    
                {publications.link && (
                    <Link href={publications.link}
                    target="_blank"
                    style={{ ...webStyles.educationDateStyle, fontSize: "14px", color: "#364BA0" }}>
                        Link<span style={{ marginLeft: "4px", fontSize: "14px" }}>↗</span>
                    </Link>
                )}
            </Box>
        ));
    };

    renderEngagements = () => {
        const displayedEngagement = this.state.freelancerCompleteData?.data?.attributes.profile_data.achievements.engagements_and_seminars || [];
        
        return displayedEngagement.map((engagements, index) => (
            <Box key={index} style={{ display: "flex", marginTop: "24px", justifyContent:"space-between" }}>
                <Box>
                    <Typography style={webStyles.subBoldHeading}>{engagements.title}</Typography>
                    <Typography style={{ ...webStyles.subText, fontSize: "16px", marginTop: "16px" }}>{engagements.description}</Typography>
                </Box>
    
                {engagements.link && (
                    <Link href={engagements.link} 
                    target="_blank"
                    style={{ ...webStyles.educationDateStyle, fontSize: "14px", color: "#364BA0" }}>
                        Link<span style={{ marginLeft: "4px", fontSize: "14px" }}>↗</span>
                    </Link>
                )}
            </Box>
        ));
    };
    renderEditProfileModal = () => (
        <CustomDialog
          open={this.state.editProfileModalOpen}
          onClose={this.handleEditProfileModalClose}
          maxWidth="sm"
          fullWidth
        >
              <Grid container spacing={2}>
                  <Grid item xs={12}>
                      <Box style={webStyles.editContainer}>
                          <Typography style={{ fontSize: "16px" }}>Edit Photo</Typography>
                          <IconButton aria-label="close" onClick={this.handleEditProfileModalClose}>
                              <CloseIcon style={{
                                  color: 'black'
                              }} />
                          </IconButton>
                      </Box>
                      <hr style={webStyles.Hr} />
                      <Box>
                          <Box style={webStyles.imageContainer}>
                              <Box style={webStyles.imageCircle}>
                              <img 
                              src={this.state.profileImage || this.state.freelancerCompleteData?.data.attributes.basic_details.profile_image} 
                              style={webStyles.Avatar_upload}/>
                              <button
                                style={{position: 'absolute',
                                  background: '#364BA0',
                                  border: 'none',
                                  borderRadius: '50%',
                                  top: '50%',
                                  right: '39%'
                                }}
                                onClick={this.handleEditProfileImage}
                                data-testid="profile-pic-edit"
                              >
                                <AddIcon style={webStyles.addIcon}/>
                              </button>
                              <input
                                type="file"
                                data-testid="profileImageInput"
                                ref={this.state.fileInputRef}
                                style={{ display: "none" }}
                                onChange={this.handleFileChange}
                                accept="image/*"
                              />
                              </Box>
                          </Box>
                          <Typography
                              variant="body2"
                              style={{
                                  fontSize: '0.875rem',
                                  fontFamily: 'Rubik',
                                  textAlign: 'center',
                                  fontWeight: 700,
                                  marginTop: 1
                              }}
                          >
                              250x250 Min / 5 MB Max
                          </Typography>
                          <Box style={webStyles.btnBox}>
                              <Button
                                  style={{ ...webStyles.btnStyleTans, minWidth: "100px" }}
                                  onClick={this.handleEditProfileModalClose}
                                  data-testid="cancel-btn"
                                  fullWidth
                              >
                                  CANCEL
                              </Button>
                              <Button
                                  style={webStyles.btnStylesBlue}
                                  onClick={this.handleUpdateProfileImage}
                                  data-testid="update-btn"
                                  fullWidth
                              >
                                  SAVE
                              </Button>
                          </Box>
                      </Box>
                  </Grid>
              </Grid>
        </CustomDialog>
      );

      renderEditModalTitle = () => (
        <CustomDialog
          open={this.state.editTitleModalOpen}
          onClose={this.handleEditModalCloseTitle}
          maxWidth="sm"
          fullWidth
        >
        <div style={{...webStyles.flexStyles,marginBottom:"32px"}}>
          <Typography style={webStyles.mainHeadingBold}>Edit your Title</Typography>
          <CloseIcon style={{width:"24px", height:"24px", alignItems:"center",cursor:"pointer" }} onClick={this.handleEditModalCloseTitle}/>
        </div>
          <Typography style={{...webStyles.bold16Text,fontWeight:600,marginBottom:"12px"}}>Title*</Typography>
          {/* <FormControl variant="outlined">
            <CustomTextField
            placeholder="Enter your New Title"
            name="newTitle"
            type="text"
            variant="outlined"
            aria-describedby="outlined-weight-helper-text"
            value={this.state.title}
            onChange={this.handleChangeTitle} 
            inputProps={{
                "aria-label": "newTitle",
                style: {
                fontSize: "16px",
                fontWeight: 500,
                fontFamily: "Rubik",
                color: "#000000"
                },
                maxLength: 32
            }}                           
            />
        </FormControl> */}
         <InputField variant="outlined">
            <Select
              name="jobTitle"
              value={this.state.title}
              displayEmpty
              MenuProps={{
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left"
                },
                transformOrigin: {
                  vertical: "top",
                  horizontal: "left"
                },
                getContentAnchorEl: null,
                PaperProps: {
                  style: {
                    maxHeight: 200,
                    overflowY: "auto",
                    scrollbarColor: "#364BA0 #f0f0f0",
                    scrollbarWidth: "thin"
                  }
                }
              }}
              style={webStyles.borderRadius}
              onChange={this.handleChangeTitle}
            >
              <MenuItem value="" disabled>
                <span style={{ color: '#979797' }}>Select Job</span> 
              </MenuItem>
              {this.state.titleArray.map((title) => (
              <MenuItem key={title.id} value={title.job_title}>
                {title.job_title}
              </MenuItem>
            ))}                 
            </Select>
          </InputField>
          <DialogActions style={{marginTop:"32px"}}>
            <Button onClick={this.handleEditModalCloseTitle} style={{...webStyles.btnStyleTans,minWidth: "100px",}}>
              Cancel
            </Button>
            <Button onClick={this.handleSaveTitle} style={{...webStyles.btnStylesBlue,minWidth: "100px",}}>
              Save
            </Button>
          </DialogActions>
        </CustomDialog>
      );

      renderEditModalRate = () => (
        <CustomDialog
          open={this.state.editRateModalOpen}
          onClose={this.handleEditModalCloseRate}
          maxWidth="lg"
          fullWidth
        >
        <div style={{...webStyles.flexStyles,marginBottom:"32px"}}>
          <Typography style={webStyles.mainHeadingBold}>Change your Fees</Typography>
          <CloseIcon style={{width:"24px", height:"24px", alignItems:"center",cursor:"pointer"  }} onClick={this.handleEditModalCloseRate}/>
        </div>
        <Grid item xs={12} sm={12} style={{ marginTop: "32px" }} >
              <Grid container>

                <Grid item xs={12} sm={10} >
                  <Typography style={webStyles.subHeaderStyle}>
                    Clients will see your project rate on your profile and in search results once you publish your profile.
                  </Typography>
                  <Typography style={webStyles.headingRegular}>

                    You can adjust your rate every time you submit a proposal.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={{ ...webStyles.feeItem, marginTop: "0px" }}>
                    <CustomTextField
                      variant="outlined"
                      style={webStyles.feeInput}
                      value={this.state.projectRate}
                      defaultValue="$20"
                      onChange={this.handleProjectRateChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={10} style={{ marginTop: "32px" }}>
                  <Typography  style={webStyles.subHeaderStyle}>
                  INVENTO HUB Services Fee
                </Typography>
                  <Typography style={webStyles.headingRegular}>
                    This helps us run the platform and provide services like payment and customer support.</Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={webStyles.feeItem}>
                    <CustomTextField
                      variant="outlined"
                      value={`${this.state.servicesFee.data[0]?.percentage}%`} 
                      style={webStyles.feeInput}
                      defaultValue="$20"
                      disabled
                      InputProps={{
                        startAdornment: <InputAdornment position="start">%</InputAdornment>,
                      }}
                    />
                  </Box>
                </Grid>

                <Grid item xs={12} sm={10} style={{ marginTop: "32px" }}>
                  <Typography style={webStyles.subHeaderStyle}>
                    You'll get
                  </Typography>
                  <Typography style={webStyles.headingRegular}>
                    The estimated amount you'll receive after service fees.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={2}>
                  <Box style={webStyles.feeItem}>
                    <CustomTextField
                      variant="outlined"
                      style={webStyles.feeInput}
                      defaultValue="$20"
                      value={this.state.estimatedAmount}
                      onChange={this.handleEstimatedAmountChange}
                      InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>,
                      }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          <Typography style={webStyles.flagStyle}>We do not deduct any tax. You need to pay taxes according to your country.</Typography>
          <DialogActions style={{marginTop:"32px"}}>
            <Button onClick={this.handleEditModalCloseRate} style={{...webStyles.btnStyleTans,minWidth: "100px",}}>
              Cancel
            </Button>
            <Button onClick={this.handleSaveRate} style={{...webStyles.btnStylesBlue,minWidth: "100px",}}>
              Save
            </Button>
          </DialogActions>
        </CustomDialog>
      );

      renderEditLanguages = () => {
        return (
          <CustomDialog
            open={this.state.editLanguageModalOpen}
            onClose={this.handleEditModalCloseLanguage}
            maxWidth="sm"
            fullWidth
          >
            <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
              <Typography style={webStyles.mainHeadingBold}>Edit Language</Typography>
              <CloseIcon
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
                onClick={this.handleEditModalCloseLanguage}
              />
            </div>
            {this.state.languageArray.map((language, index) => (
              <Grid container spacing={2} key={language.id} style={{ marginBottom: "16px", display: "flex", alignItems: "end" }}>
                <Grid item xs={6}>
                  <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>Language</Typography>
                  <FormControl fullWidth variant="outlined">
                    <CustomSelect
                      value={language.name}
                      data-test-id="handleChangeLanguages"
                      onChange={(e) => this.handleChangeLanguages(e, index)}
                      MenuProps={{
                        transformOrigin: {
                          horizontal: "left",
                          vertical: "top",
                        },
                        anchorOrigin: {
                          horizontal: "left",
                          vertical: "bottom",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="English">English</MenuItem>
                      <MenuItem value="Hindi">Hindi</MenuItem>
                      <MenuItem value="Chinese">Chinese</MenuItem>
                      <MenuItem value="German">German</MenuItem>
                      <MenuItem value="French">French</MenuItem>

                    </CustomSelect>
                  </FormControl>
                </Grid>
                <Grid item xs={5}>
                  <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>Proficiency</Typography>
                  <FormControl fullWidth variant="outlined">
                    <CustomSelect
                      value={language.proficiency}
                      onChange={(e) => this.handleChangeProficiency(e, index)}
                      MenuProps={{
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        transformOrigin: {
                          vertical: "top",
                          horizontal: "left",
                        },
                        getContentAnchorEl: null,
                      }}
                    >
                      <MenuItem value="Intermediate">Intermediate</MenuItem>
                      <MenuItem value="Advanced">Advanced</MenuItem>
                      <MenuItem value="Expert">Expert</MenuItem>
                    </CustomSelect>
                  </FormControl>
                </Grid>
              </Grid>
            ))}
            <DialogActions style={{ marginTop: "48px" }}>
              <Button onClick={this.handleEditModalCloseLanguage} style={{ ...webStyles.btnStyleTans, minWidth: "100px" }}>
                Cancel
              </Button>
              <Button onClick={this.handleSaveLanguage} style={{ ...webStyles.btnStylesBlue, minWidth: "100px" }}>
                Save
              </Button>
            </DialogActions>
          </CustomDialog>
        );
      };

      renderEditLicense = () => {
        return (
          <CustomDialog
            open={this.state.editLicenceModalOpen}
            onClose={this.handleEditModalCloseLicence}
            maxWidth="md"
            fullWidth
          >
            <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
              <Typography style={{ ...webStyles.mainHeadingBold, fontSize: "24px" }}>Edit License</Typography>
              <CloseIcon
                style={{ width: "24px", height: "24px", cursor: "pointer" }}
                onClick={this.handleEditModalCloseLicence}
              />
            </div>
            <Typography style={{...webStyles.subBoldHeading,marginBottom:"35px"}}>Licenses are documents issued by government authorities or professional entities in certain fields.</Typography>
      
            <Grid container spacing={3}>

               <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Licensed Profession</Typography>
                <FormControl variant="outlined" fullWidth>
                  <CustomTextField
                    placeholder="Ex: Bar License"
                    name="title"
                    type="text"
                    variant="outlined"
                    value={this.state.licensedProfession}
                    onChange={this.handleChangeLicensedProfession}
                    inputProps={{
                      "aria-label": "title",
                      style: {
                        fontSize: "16px",
                        fontWeight: 500,
                        fontFamily: "Rubik",
                        color: "#000000",
                      },
                      maxLength: 50,
                    }}
                  />
                </FormControl>
                </Grid>
                <Grid item xs={6}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Jurisdiction or Licensing body</Typography>
                <FormControl variant="outlined" fullWidth>
                  <CustomTextField
                    placeholder="Ex: State Bar Association"
                    name="title"
                    type="text"
                    variant="outlined"
                    value={this.state.LicensingBody}
                    onChange={this.handleChangeLicensingBody}
                    inputProps={{
                      "aria-label": "title",
                      style: {
                        fontSize: "16px",
                        fontWeight: 500,
                        fontFamily: "Rubik",
                        color: "#000000",
                      },
                      maxLength: 50,
                    }}
                  />
                </FormControl>
                </Grid>
                </Grid>
              </Grid>
      
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>License Number</Typography>
                <FormControl variant="outlined" fullWidth>
                  <CustomTextField
                    placeholder="Ex: Bar License"
                    name="title"
                    type="text"
                    variant="outlined"
                    value={this.state.licenseNumber }
                    onChange={this.handleChangeLicenseNumber}
                    inputProps={{
                      "aria-label": "title",
                      style: {
                        fontSize: "16px",
                        fontWeight: 500,
                        fontFamily: "Rubik",
                        color: "#000000",
                      },
                      maxLength: 50,
                    }}
                  />
                </FormControl>
                </Grid>
                <Grid item xs={6}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Licensing Verification URL</Typography>
                <FormControl variant="outlined" fullWidth>
                  <CustomTextField
                    inputProps={{
                      "aria-label": "title",
                      maxLength: 50,
                      style: {
                        fontSize: "16px",
                        fontFamily: "Rubik",
                        fontWeight: 500,
                        color: "#000000",
                      },
                    }}
                    placeholder="Ex: State Bar Association"
                    name="title"
                    type="text"
                    variant="outlined"
                    value={this.state.licensingVerificationURL}
                    onChange={this.handleChangeLicensingVerificationURL}
                  />
                </FormControl>
                </Grid>
                </Grid>
              </Grid>
      
              <Grid item xs={12} sm={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Issue Date *</Typography>
                <InputField style={webStyles.textField} variant="outlined">
                      <OutlinedInput
                        type="date"
                        data-test-id="Issue_Date_handleLicenseChange"
                        value={this.state.Issue_Date}
                        onChange={this.handleIssueDateChange}
                        style={webStyles.borderRadius}
                      />
                    </InputField>
                  </Grid>
                  <Grid item xs={6}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>Expiration Date (if any)</Typography>
                  <InputField style={webStyles.textField} variant="outlined">
                  <OutlinedInput
                        type="date"
                        data-test-id="Issue_Date_handleLicenseChange"
                        value={this.state.ExpirationDate}
                        onChange={this.handleExpirationDateChange}
                        style={webStyles.borderRadius}
                      />
                  </InputField>
                  </Grid>
                </Grid>
              </Grid>
      
            </Grid>
      
            <DialogActions style={{ marginTop: "32px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <Link style={webStyles.deleteBtn}>DELETE</Link>
              </div>
              <div>
                <Button onClick={this.handleEditModalCloseLicence} style={{ ...webStyles.btnStyleTans, minWidth: "100px", marginRight: "10px" }}>
                  Cancel
                </Button>
                <Button 
                data-test-id="handleSaveLicence"
                onClick={()=>this.handleSaveLicence(this.state.currentEditingLicenseId)} style={{ ...webStyles.btnStylesBlue, minWidth: "100px" }}>
                  Save
                </Button>
              </div>
            </DialogActions>
          </CustomDialog>
        );
      };

      renderAddModalSocialMedia = () => (
        <CustomDialog
          open={this.state.addSocialMediaModalOpen}
          onClose={this.handleAddModalCloseSocialMedia}
          maxWidth="sm"
          fullWidth
        >
        <div style={{...webStyles.flexStyles,marginBottom:"32px"}}>
          <Typography style={webStyles.mainHeadingBold}>Add Social Media</Typography>
          <CloseIcon style={{width:"24px", height:"24px", alignItems:"center",cursor:"pointer" }} onClick={this.handleAddModalCloseSocialMedia}/>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
                Media Name*
                </Typography>
              </div>
              <FormControl variant="outlined" style={{ marginBottom: "32px" }} error={!!this.state.addMediaNameError}>
                <CustomTextField
                  placeholder="Media Name"
                  name="newTitle"
                  type="Link"
                  variant="outlined"
                  aria-describedby="outlined-weight-helper-text"
                  fullWidth
                  value={this.state.addSocialMediaName}
                  onChange={this.handleAddSocialMediaName
                  }                  
                  inputProps={{
                    "aria-label": "newTitle",
                    style: {
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Rubik",
                      color: "#000000"
                    },
                    maxLength: 32
                  }}
                />
              {this.state.addMediaNameError && <FormHelperText
            style={{
              fontSize:'14px',
              fontWeight: 600
            }}
            >{this.state.addMediaNameError}</FormHelperText>}
              </FormControl>
              <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
                Link*
                </Typography>
              </div>
              <FormControl variant="outlined" style={{ marginBottom: "32px" }} error={!!this.state.addMediaLinkError}>
                <CustomTextField
                  placeholder="Link"
                  name="newTitle"
                  type="Link"
                  variant="outlined"
                  value={this.state.addSocialMediaLink}
                  onChange={this.handleAddSocialMediaLink
                  }     
                  aria-describedby="outlined-weight-helper-text"
                  fullWidth
                  inputProps={{
                    "aria-label": "newTitle",
                    style: {
                      fontSize: "16px",
                      fontWeight: 500,
                      fontFamily: "Rubik",
                      color: "#000000"
                    },
                    maxLength: 32
                  }}
                  />
                  {this.state.addMediaLinkError && <FormHelperText
                style={{
                  fontSize:'14px',
                  fontWeight: 600
                }}
                >{this.state.addMediaLinkError}</FormHelperText>}
              </FormControl>
          <DialogActions style={{marginTop:"32px"}}>
            <Button onClick={this.handleAddModalCloseSocialMedia} style={{...webStyles.btnStyleTans,minWidth: "100px",}}>
              Cancel
            </Button>
            <Button onClick={this.handleAddSocialMedia} style={{...webStyles.btnStylesBlue,minWidth: "100px",}}>
              Save
            </Button>
          </DialogActions>
        </CustomDialog>
      );

      renderEditModalSocialMedia = () => (
        <CustomDialog
          open={this.state.editSocialMediaModalOpen}
          onClose={this.handleEditModalCloseSocialMedia}
          maxWidth="sm"
          fullWidth
        >
        <div style={{...webStyles.flexStyles,marginBottom:"32px"}}>
          <Typography style={webStyles.mainHeadingBold}>Edit Social Media</Typography>
          <CloseIcon style={{width:"24px", height:"24px", alignItems:"center",cursor:"pointer" }} onClick={this.handleEditModalCloseSocialMedia}/>
        </div>
        {this.state.socialMediaData.map((socialMedia)=>(
            <><div style={{ 
              display: "flex", 
              justifyContent: "space-between", 
              alignItems: "center"
               }}>
                <Typography 
                style={{ ...webStyles.bold16Text, 
                fontWeight: 600, 
                marginBottom: "12px"
                 }}>
                  {socialMedia.media_name} </Typography>
              </div><FormControl 
              variant="outlined" 
              style={{
                 marginBottom: "32px" }}>
                <CustomTextField placeholder="Link"
                  type="Link"
                  aria-describedby="outlined-weight-helper-text" fullWidth
                  name="newTitle" variant="outlined"
                  value={socialMedia.link} onChange={(e) =>
                    this.handleSocialMediaChange(socialMedia.id, "link", e.target.value)
                  } inputProps={{
                    "aria-label": "newTitle", style: {
                      color: "#000000",
                      fontWeight: 500,
                      fontSize: "16px",
                      fontFamily: "Rubik",
                    }, maxLength: 32}}/>
              </FormControl> </>))}
<DialogActions 
style={{marginTop:"32px"}}>
            <Button 
            onClick={this.handleEditModalCloseSocialMedia}
             style={{...webStyles.btnStyleTans,minWidth: "100px",}}>
              Cancel
            </Button>
            <Button 
            onClick={this.handleSaveSocialMedia} 
            style={{...webStyles.btnStylesBlue,minWidth: "100px",}}>
              Save
            </Button>
          </DialogActions>
        </CustomDialog>
      );
      
      renderEditModalAchivements = () => {
        let title, description, link, editId: string;
      
        if (this.state.categoryAchivements === 'Public Relation Materials') {
          title = this.state.achivementPublicRelationTitle;
          description = this.state.achivementsPublicRelationDescription;
          link = this.state.achivementsPublicRelationLink;
          editId = this.state.editAchivementsPublicRelationId;
        }else if (this.state.categoryAchivements === 'Publications') {
          title = this.state.achivementPublicationTitle;
          description = this.state.achivementsPublicationDescription;
          link = this.state.achivementsPublicationLink;
          editId = this.state.editAchivementsPublicationId;
        } else {
          title = this.state.AchivementTitle;
          description = this.state.achivementsDescription;
          link = this.state.achivementsLink;
          editId = this.state.editAchivementsId;
        }
      
      
        return (
          <CustomDialog
            open={this.state.editAchivementsModalOpen}
            onClose={this.handleEditModalCloseAchivements}
            maxWidth="md"
            fullWidth
          >
            <div style={{ ...webStyles.flexStyles, marginBottom: "32px" }}>
              <Typography style={webStyles.mainHeadingBold}>Edit Achievements</Typography>
              <CloseIcon
                style={{
                  width: "24px",
                  height: "24px",
                  alignItems: "center",
                  cursor: "pointer",
                }}
                onClick={this.handleSaveAchivements}
              />
            </div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "16px" }}>
                  Category
                </Typography>
                <FormControl fullWidth variant="outlined">
                  <CustomSelect
                    value={this.state.categoryAchivements }
                    defaultValue=""
                    displayEmpty
                    name="categoryAchivements"
                    onChange={this.handleCategoryAchivementsSelect}
                    MenuProps={{
                      anchorOrigin: { vertical: "bottom", horizontal: "left" },
                      transformOrigin: { vertical: "top", horizontal: "left" },
                      getContentAnchorEl: null,
                    }}
                  >
                    <MenuItem value="" disabled>Add Category Achievements</MenuItem>
                    {this.state.categoryAchivementsArray.map((achivement: any) => (
                      <MenuItem key={achivement.id} value={achivement.name}>
                        {achivement.name}
                      </MenuItem>
                    ))}
                  </CustomSelect>
                </FormControl>
              </Grid>
      
              <Grid item xs={12}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>
                  Title
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <CustomTextField
                    placeholder="Enter your New Title"
                    name="newTitle"
                    type="text"
                    variant="outlined"
                    value={title}
                    onChange={this.handleChangeAchivementTitle}
                    inputProps={{
                      "aria-label": "newTitle",
                      style: { fontSize: "16px", fontWeight: 500, fontFamily: "Rubik", color: "#000000" },
                      maxLength: 32
                    }}
                  />
                </FormControl>
              </Grid>
      
              <Grid item xs={12}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "12px" }}>
                  Description
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <CustomTextField
                    placeholder="Enter Description here"
                    name="description"
                    type="text"
                    variant="outlined"
                    multiline
                    rows={4}
                    value={description}
                    onChange={this.handleChangeAchivementsDescription}
                    inputProps={{
                      "aria-label": "description",
                      style: { fontSize: "16px", fontWeight: 500, fontFamily: "Rubik", color: "#000000" },
                      maxLength: 256
                    }}
                  />
                </FormControl>
              </Grid>
      
              <Grid item xs={12}>
                <Typography style={{ ...webStyles.bold16Text, fontWeight: 600, marginBottom: "8px" }}>
                  Add Link
                </Typography>
                <FormControl variant="outlined" fullWidth>
                  <CustomTextField
                    placeholder="Enter Link here"
                    name="newTitle"
                    type="text"
                    variant="outlined"
                    value={link}
                    onChange={this.handleChangeAchivementsLink}
                    inputProps={{
                      "aria-label": "newTitle",
                      style: { fontSize: "16px", fontWeight: 500, fontFamily: "Rubik", color: "#000000" },
                      maxLength: 32
                    }}
                  />
                    {this.state.achivementsLinkError && <FormHelperText
                style={{
                  fontSize:'14px',
                  fontWeight: 600,
                  color:'red'
                }}
                >{this.state.achivementsLinkError}</FormHelperText>}
                </FormControl>
              </Grid>
            </Grid>
      
            <DialogActions style={{ marginTop: "32px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
              <div>
                <Link style={webStyles.deleteBtn}>DELETE</Link>
              </div>
              <div>
                <Button
                  onClick={this.handleSaveAchivements}
                  style={{ ...webStyles.btnStyleTans, minWidth: "100px", marginRight: "10px" }}
                >
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    if (this.state.categoryAchivements === 'Public Relation Materials') {
                      this.handleSavePublicRelationAchivementApiCall(editId);
                    } else if (this.state.categoryAchivements === 'Publications') {
                      this.handleSavePublicationsAchivementApiCall(editId);
                    } else {
                      this.handleSaveAchivementApiCall(editId);
                    }
                  }}
                  style={{ ...webStyles.btnStylesBlue, minWidth: "100px" }}
                >
                  Save
                </Button>
              </div>
            </DialogActions>
          </CustomDialog>
        );
      };
      
      
      render() {
        const { isPublicView,showAll,articles,showAllArticles } = this.state;
        const freelancerBasiceDetailsData = this.state.freelancerCompleteData?.data?.attributes.basic_details
        const freelancerProfileData = this.state.freelancerCompleteData?.data?.attributes.profile_data
        const freelancerServicesData = this.state.freelancerCompleteData?.data?.attributes.profile_data.services || []; 
        const freelancerExpertiseData = this.state.freelancerCompleteData?.data?.attributes.profile_data.area_of_expertise || []; 
        const freelancerIndustriesData = this.state.freelancerCompleteData?.data?.attributes.profile_data.industries || []; 
        const freelancerSkills = this.state.freelancerCompleteData?.data?.attributes.profile_data.skills  || []
        const freelancerEducation: {
          id: number;
          degree_name: string;
          college_name: string;
          country: string;
          start_date: string;
          end_date: string;
          description: string;
          degree_img: string | null;
          created_at: string;
          updated_at: string;
        } | null = this.state.freelancerCompleteData?.data?.attributes?.profile_data?.educations[0] || null;
        const freelancerEducationDetails = this.state.freelancerCompleteData?.data?.attributes?.profile_data?.educations || null;
        const freelancerCertificateDetails = this.state.freelancerCompleteData?.data?.attributes.profile_data.certificates || null;
        const freelancerCertificates = this.state.freelancerCompleteData?.data?.attributes.profile_data.certificates[0]
        const freelancerLicenses = this.state.freelancerCompleteData?.data?.attributes.profile_data.licenses[0]
        const freelancerLanguagesData = this.state.freelancerCompleteData?.data?.attributes.profile_data.language_expertises || [];
        const freelancerPatents = this.state.freelancerCompleteData?.data?.attributes.patents.categories[0];
        const freelancerWorkExperience = this.state.freelancerCompleteData?.data?.attributes.profile_data.work_experiences || [];
        const freelancerWorkHistory = this.state.freelancerCompleteData?.data?.attributes.profile_data.work_history || [];
        const socialMediaData = this.state.freelancerCompleteData?.data?.attributes.profile_data.social_media || []
        const displayedExperiences = showAll ? freelancerWorkExperience : freelancerWorkExperience.slice(0, 2);
        return (
            <>
                <FreelancerNavBar navigation={this.props.navigation}  />
                <ParentGrid container spacing={3}>
                    <Grid container spacing={5}>
                        {/* Left section */}
                        <Grid item xs={12} md={4}>
                            <Box style={webStyles.leftSectionStyle}>
                                <Grid container direction="column">
                                    <Grid item>
                                        <div style={{position:"relative"}}>
                                        {!isPublicView && <img src={online} style={{position:"absolute",top: 4,left: 7,zIndex: 30}}/>}
                                        <Avatar alt="Rahul Sharma"  src={freelancerBasiceDetailsData?.profile_image} style={{ width: 72, height: 72, marginBottom: "18px" }} />
                                        {!isPublicView && (
                                            <img
                                            src={outlineEdit}
                                            onClick={this.handleEditProfileModalOpen}
                                            style={{ position: "absolute", left: 42, top: 53, cursor: "pointer" }}
                                            alt="Edit Profile"
                                            />
                                        )}
                                        </div>
                                    {this.state.editProfileModalOpen && this.renderEditProfileModal()}
                                    </Grid>
                                    <Grid item>
                                        <Typography style={{ ...webStyles.mainHeadingBold, marginBottom: "18px" }}>
                                        {freelancerBasiceDetailsData?.first_name}{" "}{freelancerBasiceDetailsData?.last_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item style={{ marginBottom: "18px" }}>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Button variant="contained" style={webStyles.btnStyleTans} onClick={this.toggleView}>
                                                {isPublicView ? "Invite" : "See Public View"} </Button>
                                            </Grid>
                                            <Grid item>
                                                <Button variant="outlined" style={webStyles.btnStylesBlue}
                                                   onClick={isPublicView ? undefined : this.handleProfileSettings}
                                                >
                                                   { isPublicView ? "Message":'Profile Settings'}
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Languages</Typography>
                                        <Box>
                                        {!isPublicView && <img src={add} style={{...webStyles.imageMargin,cursor:"pointer"}} onClick={this.handleAddModalOpenLanguage}/>}
                                        {!isPublicView && <img src={edit} style={{cursor:"pointer"}} onClick={this.handleEditModalOpenLanguage}/>}
                                        {this.renderAddLanguages()}
                                        </Box>
                                        {this.renderEditLanguages()}
                                    </Box>

                                    {freelancerLanguagesData.length > 0 ? (
                                        freelancerLanguagesData.map((language, index) => (
                                        <div key={index} style={{ marginBottom: "12px" }}>
                                            <Typography component="span" style={webStyles.subBoldHeading}>
                                            {language.name}: {" "}
                                            </Typography>
                                            <span style={webStyles.subNormalHeading}>
                                            {language.proficiency}
                                            </span>
                                        </div>
                                        ))
                                    ) : (
                                        <Typography>No languages listed</Typography>
                                    )}
                                </Box>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Social Media</Typography>
                                    <Box>
                                    {!isPublicView && <img src={add} alt="Add" style={webStyles.imageMargin} onClick={this.handleAddModalOpenSocialMedia}/>}
                                    {!isPublicView && <img src={edit} alt="Edit" onClick={this.handleEditModalOpenSocialMedia}/>}
                                    </Box>
                                    {this.renderAddModalSocialMedia()}
                                    {this.renderEditModalSocialMedia()}
                                </Box>

                                <Grid container spacing={1}>
                                    <Grid item style={{ display: "flex", gap: "10px",flexWrap: 'wrap' }}>
                                    {socialMediaData.map((media, index) => (
                                        <a
                                        href={media.link}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        key={index}
                                        style={{ display: "inline-block" }}
                                        >
                                        <img
                                            src={iconMapping[media.media_name.toLowerCase() as keyof typeof iconMapping]} // Use the icon based on the platform name
                                            alt={`${media.media_name} icon`}
                                            style={{ width: "24px", height: "24px" }}
                                            /> 
                                        </a>
                                    ))}
                                    </Grid>
                                </Grid>
                                </Box>


                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Services</Typography>
                                        <Box>
                                        {!isPublicView && <img src={edit} style={{cursor:"pointer"}} onClick={this.handleEditModalOpenServices}/>}
                                        {this.renderEditServices()}
                                        </Box>
                                    </Box>
                                    <Grid container spacing={1}>
                                        {freelancerServicesData.length > 0 ? (
                                            freelancerServicesData.map((service, index) => (
                                                <Grid item key={index}>
                                                    <Box style={webStyles.chipStyle}>
                                                        <Typography style={webStyles.skillStyle}>{service.name}</Typography>
                                                    </Box>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography>No services available</Typography>
                                        )}
                                    </Grid>
                                </Box>

                                <Box style={webStyles.marginBottomStyle} mt={3} >
                                    <Box style={{ marginBottom: "20px", ...webStyles.headingDisplay }}>
                                        <Typography style={webStyles.headingBold}>Area of Expertise</Typography>
                                        <Box>
                                        {!isPublicView && <img src={edit} style={{cursor:"pointer"}} onClick={this.handleEditModalOpenExpertise} />}
                                        <CustomModal
                                            open={this.state.expertiseModalOpen}
                                            onClose={this.handleEditModalCloseExpertise}
                                            title="Edit Area of Expertise"
                                            content="Expertise"
                                            inputPlaceholder="Add your expertise"
                                            inputValue={this.state.expertise}
                                            onInputChange={this.handleExpertiseChange}
                                            onKeyPress={this.handleExpertiseKeyPress}
                                            chipsArray={this.state.expertiseArray}
                                            onDeleteChip={this.handleDeleteExpertise}
                                            onSave={this.handleSaveExpertise}
                                            />
                                        </Box>
                                    </Box>
                                    <Grid spacing={1} container>
                                        {freelancerExpertiseData.length > 0 ? (
                                            freelancerExpertiseData.map((expertise, index) => (
                                                <Grid item key={index}>
                                                    <Box style={webStyles.chipStyle}>
                                                        <Typography style={webStyles.skillStyle}>{expertise}</Typography>
                                                    </Box>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography>No expertise available</Typography>
                                        )}
                                    </Grid>
                                </Box>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Industry Focus</Typography>
                                        <Box>
                                        {!isPublicView && <img src={edit} style={{cursor:"pointer"}} onClick={this.handleEditModalOpenIndustryFocus}/>}
                                        {this.renderEditIndustryFocus()}
                                        </Box>
                                    </Box>
                                    <Grid container spacing={1}>
                                        {freelancerIndustriesData.length > 0 ? (
                                            freelancerIndustriesData.map((industries, index) => (
                                                <Grid item key={index}>
                                                    <Box style={webStyles.chipStyle}>
                                                        <Typography style={webStyles.skillStyle}>{industries.name}</Typography>
                                                    </Box>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography>No industries available</Typography>
                                        )}
                                    </Grid>
                                </Box>

                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "21px" }}>
                                        <Typography style={webStyles.headingBold}>Skills</Typography>
                                        {!isPublicView &&<Box><img src={edit} style={{cursor:"pointer"}} onClick={this.handleEditModalOpenSkills}/></Box>}
                                        <CustomModal
                                          open={this.state.editSkillsModalOpen}
                                          onClose={this.handleEditModalCloseSkills}
                                          title="Edit Skills"
                                          content="Skills"
                                          inputPlaceholder="Enter your New Skills"
                                          inputValue={this.state.skills} 
                                          onInputChange={this.handleSkillsChange} 
                                          onKeyPress={this.handleSkillsKeyPress} 
                                          chipsArray={this.state.skillsArray} 
                                          onDeleteChip={this.handleDeleteSkill} 
                                          onSave={this.handleSaveSkills} 
                                        />
                                    </Box>
                                    <Grid container spacing={1}>
                                        {freelancerSkills.length > 0 ? (
                                            freelancerSkills.map((skill, index) => (
                                                <Grid item key={index}>
                                                    <Box style={webStyles.chipStyle}>
                                                        <Typography style={webStyles.skillStyle}>{skill.name}</Typography>
                                                    </Box>
                                                </Grid>
                                            ))
                                        ) : (
                                            <Typography>No skills available</Typography>
                                        )}
                                    </Grid>
                                </Box>
                            </Box>

                            {/* leftSection2 */}
                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: "24px" }}>
                                <Box mt={3} style={webStyles.marginBottomStyle}>
                                    <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                        <Typography style={webStyles.headingBold}>Education</Typography>
                                        <Box>
                                        {!isPublicView && 
                                        <img src={add} style={webStyles.imageMargin} 
                                        onClick={this.handleAddModalOpenEducation} 
                                        />}
                                         {!isPublicView && <img src={edit} 
                                         data-test-id = "handleEditModalOpenEducation"
                                          onClick={() => freelancerEducation && this.handleEditModalOpenEducation(freelancerEducation.id)} 
                                          style={{cursor:"pointer"}}/>}
                                          {this.renderAddEducations()}
                                         {this.renderEditEducations()}
                                        </Box>
                                    </Box>
                                    {freelancerEducationDetails?.map((itm)=>{
                                    return(
                                      <>
                                      <Typography style={{ ...webStyles.sub16fontStyle, color: "#49454F", marginBottom: "6px" }}>{itm?.college_name}</Typography>
                                      <Typography style={{ ...webStyles.sub14fontStyle, fontWeight: 400 }}>{itm?.degree_name}</Typography>
                                      <Typography style={webStyles.educationDateStyle}>{itm?.start_date} - {itm?.end_date} </Typography>
                                      <Typography style={{...webStyles.sub14fontStyle,
                                        marginBottom:'12px'
                                      }}>{itm?.country}</Typography>
                                       <Divider style={{
                                         marginBottom:'12px',
                                         height:'1.5px'
                                       }}/>
                                        </>
                                      )
                                    })}
                                </Box>

                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Certifications</Typography>
                                    <Box>
                                    {!isPublicView && <img src={add} style={webStyles.imageMargin} onClick={this.handleAddModalOpenCertificate} />}
                                    {!isPublicView && <img src={edit} style={{cursor:"pointer"}} 
                                    data-test-id="handleEditModalOpenCertificate"
                                    onClick={()=> freelancerCertificates && this.handleEditModalOpenCertificate(freelancerCertificates.id)}/>}
                                    {this.renderEditCertificate()}
                                    {this.renderAddCertificate()}
                                    </Box>
                                </Box>
                                {freelancerCertificateDetails?.map((itm)=>{
                                  return(
                                    <>
              <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "6px" }}>{itm?.certificate_provider}</Typography>
              <Typography style={{ ...webStyles.sub16fontStyle, fontWeight: 400, marginBottom: "0px" }}>{itm?.certificate_name}</Typography>
              <Typography style={webStyles.educationDateStyle}>{itm?.certification_id}</Typography>
              <Typography style={webStyles.educationDateStyle}>{itm?.issue_date}{itm?.expiration_date ? ` - ${itm.expiration_date}` : ''}</Typography>
              {itm?.certification_url && (
                  <Typography style={{ ...webStyles.sub16fontStyle, marginTop: "10px",marginBottom: '12px' }}>
                      <Link
                          href={itm?.certification_url}
                          target="_blank"
                          rel="noopener"
                          style={webStyles.linkStyle}
                      >
                          View Certificate
                      </Link>
                  </Typography>
              )}
              <Divider style={{
              marginBottom:'12px',
              height:'1.5px'
              }}/>
                                    </>
                                  )
                                })}
                              </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: "24px" }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>License</Typography>
                                    <Box>
                                    {!isPublicView && <img src={edit} 
                                    data-test-id ="handleEditModalOpenLicence"
                                    onClick={()=>freelancerLicenses && this.handleEditModalOpenLicence(freelancerLicenses?.id)}
                                     style={{cursor:"pointer"}}/>}
                                    {this.renderEditLicense()}
                                    </Box>
                                </Box>
                                <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "6px" }}>{freelancerLicenses?.licensed_profession}</Typography>
                                <Typography style={{ ...webStyles.sub16fontStyle, fontWeight: 400, marginBottom: "0px" }}>{freelancerLicenses?.jurisdiction_or_licensing_body}</Typography>
                                <Typography style={webStyles.educationDateStyle}>{freelancerLicenses?.license_number}</Typography>
                                <Typography style={webStyles.educationDateStyle}>{freelancerLicenses?.issue_date} - {freelancerLicenses?.expiry_date}</Typography>
                                {freelancerLicenses?.licensing_verification_url && (
                                    <Typography style={{ ...webStyles.sub16fontStyle, marginTop: "10px" }}>
                                        <Link
                                            href={freelancerLicenses.licensing_verification_url}
                                            target="_blank"
                                            rel="noopener"
                                            style={webStyles.linkStyle}
                                        >
                                            View License
                                        </Link>
                                    </Typography>
                                )}
                            </Box>
                        </Grid>

                        {/* Right section */}
                        <Grid item xs={12} md={8} >
                            <Box style={webStyles.leftSectionStyle}>
                                <Box className='copyright-box' style={{ display: "flex", justifyContent: "space-between", marginBottom: "24px" }}>
                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography style={webStyles.headingBold}>{freelancerBasiceDetailsData?.job_title}</Typography>
                                        {!isPublicView && <img src={edit} alt="Edit" style={{ marginLeft: "24px",cursor:"pointer" }} onClick={this.handleEditModalOpenTitle}/>}
                                        {this.state.editTitleModalOpen && this.renderEditModalTitle()}
                                   
                                    </Box>

                                    <Box style={{ display: "flex", alignItems: "center" }}>
                                        <Typography style={webStyles.headingBold}>{`$ ${freelancerProfileData?.hour_rate}`}</Typography>
                                        {!isPublicView && <img src={edit} alt="Edit" style={{ marginLeft: "24px",cursor:"pointer" }} onClick={this.handleEditModalOpenRate}/>}
                                    </Box>
                                    {this.state.editRateModalOpen && this.renderEditModalRate()}
                                </Box>

                                <Typography style={webStyles.subText}>
                                   {freelancerBasiceDetailsData?.job_description}
                                </Typography>
                            </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Patents</Typography>
                                    <Box>
                                    {!isPublicView &&<img src={add} style={webStyles.imageMargin} />}
                                    {!isPublicView && <img src={edit} />}
                                    </Box>
                                </Box>

                                <div style={{ marginBottom: "24px" }}>
                                    <Typography style={webStyles.sub16fontStyle} >{freelancerPatents?.name}</Typography>
                                    <Typography style={webStyles.sub14fontStyle}>{freelancerPatents?.sub_categories[0].name}</Typography>
                                </div>

                                <div style={{ marginBottom: "24px" }}>
                                    <Typography style={webStyles.sub16fontStyle} >{freelancerPatents?.sub_categories[0].sub_category_menus[0].title}</Typography>
                                    <Typography style={webStyles.sub14fontStyle}>{freelancerPatents?.sub_categories[0].sub_category_menus[0].sub_category_sub_menus[0].title}</Typography>
                                </div>

                                <div style={{ marginBottom: "24px" }}>
                                    <Typography style={webStyles.sub16fontStyle} >{freelancerPatents?.sub_categories[0].sub_category_menus[0].sub_category_sub_menus[0].description}</Typography>
                                   
                                </div>
                            </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                            <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                <Typography style={webStyles.headingBold}>Work History</Typography>
                            </Box>

                            {freelancerWorkHistory.length === 0 ? (
                                <Typography style={{ ...webStyles.sub16fontStyle, color: "gray", textAlign: "center", marginTop: "20px" }}>
                                No Work History
                                </Typography>
                            ) : (
                                <>
                                {freelancerWorkHistory.slice(0, 2).map((experience, index) => (
                                    <Box key={experience.id} style={{ ...webStyles.lightBlueBox, marginTop: index > 0 ? "16px" : "0" }}>
                                    <div style={webStyles.headingDisplay}>
                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>
                                        {experience.company}
                                        </Typography>
                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>
                                        ${experience.salary || "N/A"}
                                        </Typography>
                                    </div>
                                    <div className='rating-date-text'>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                        <Rating
                                            name="read-only"
                                            value={this.state.ratingNumber}
                                            readOnly
                                            icon={<StarRoundedIcon fontSize="inherit" />}
                                            emptyIcon={<StarBorderRoundedIcon fontSize="inherit" style={webStyles.starIcon} />}
                                        />
                                        <span style={webStyles.rating}>{this.state.rating} {"|"}</span>
                                        </div>
                                        <div style={{ ...webStyles.sub14fontStyle, marginLeft: "8px" }}>
                                        {experience.start_date} - {experience.currently_working ? "Present" : experience.end_date}
                                        </div>
                                    </div>
                                    <Typography style={{ ...webStyles.subText, marginTop: "24px" }}>
                                        {experience.description}
                                    </Typography>
                                    </Box>
                                ))}

                                {freelancerWorkHistory.length > 2 && (
                                    <Button style={webStyles.seeMoreBtn}>See More</Button>
                                )}
                                </>
                            )}
                               
                            </Box>


                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Experience</Typography>
                                    <Box>
                                    {!isPublicView &&<img src={add} style={webStyles.imageMargin} onClick={this.handleAddModalOpenExperience}/>}
                                    {!isPublicView &&<img src={edit} style={{cursor:"pointer"}} 
                                    data-test-id="handleEditModalOpenExperience"
                                    onClick={()=>freelancerWorkExperience.length>0 && this.handleEditModalOpenExperience(freelancerWorkExperience[0].id)}
                                    />}
                                    {this.renderEditExperience()}
                                    </Box>
                                </Box>
                                {displayedExperiences.map((experience, index) => (
                                    <Box key={index} style={{ marginTop: index > 0 ? "16px" : "0" }}>
                                        <div style={webStyles.headingDisplay}>
                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>
                                            {experience.job_title}
                                        </Typography>
                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>
                                            {`${experience.start_date} - ${experience.end_date || "Present"}`}
                                        </Typography>
                                        </div>
                                        <Typography style={{ ...webStyles.sub14fontStyle, fontWeight: 400 }}>
                                        {experience.company}
                                        </Typography>
                                        <Typography style={{ ...webStyles.subText, marginTop: "24px" }}>
                                        {experience.description}
                                        </Typography>
                                    </Box>
                                    ))}

                                     {freelancerWorkExperience.length > 2 && (
                                    <Button style={webStyles.seeMoreBtn} onClick={this.toggleShowAll}>
                                        {showAll? "See Less" : "See More"}
                                    </Button>
                                    )}
                            </Box>

                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                            <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                <Typography style={webStyles.headingBold}>Projects</Typography>
                                <Box>
                                {!isPublicView && <img src={add}
                                onClick={this.handleAddProjectModalOpen}
                                 alt="Add Project" />}
                                </Box>
                            </Box>
                            {this.renderAddProjects()}
                            {freelancerWorkHistory.length === 0 ? (
                                <Typography style={{ ...webStyles.sub16fontStyle, color: "gray", textAlign: "center", marginTop: "20px" }}>
                                    No Projects Available
                                </Typography>
                            ) : (
                                <Grid container xs={12} spacing={2}>
                                    <Grid item xs={12} sm={6}>
                                        <Box style={webStyles.projectBoxes}>
                                            <Box>
                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                    <div>
                                                        <Typography style={{ ...webStyles.sub16fontStyle, marginBottom: "8px" }}>XYZ Project</Typography>
                                                        <Typography style={webStyles.sub14fontStyle}>Duration - Mar 6, 2020 - Oct 12, 2020</Typography>
                                                    </div>
                                                    <div>
                                                        {!isPublicView && <img src={edit} alt="Edit" />}
                                                    </div>
                                                </div>
                                                <img src={projectPic} style={{ width: "100%", marginTop: "24px" }} alt="Project" />
                                                <Typography style={{ ...webStyles.subText, marginTop: "24px" }}>
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s....
                                                </Typography>
                                            </Box>
                                        </Box>
                                    </Grid>
                                </Grid>
                            )}

                            {freelancerWorkHistory.length > 2 && (
                                <Button style={webStyles.seeMoreBtn}>See More</Button>
                            )}
                            </Box>
                       




                            <Box style={{ ...webStyles.leftSectionStyle, marginTop: 24 }}>
                                <Box style={{ ...webStyles.headingDisplay, marginBottom: "20px" }}>
                                    <Typography style={webStyles.headingBold}>Achievements</Typography>
                                    <Box>
                                    {!isPublicView && <img src={add} style={webStyles.imageMargin} />}
                                    {!isPublicView &&  <img src={edit} 
                                      onClick={this.handleEditModalOpenAchivements}
                                    />}
                                    {this.state.editAchivementsModalOpen && this.renderEditModalAchivements()}
                                    </Box>
                                </Box>
                                <CustomTabs
                                    value={this.state.selectedTab}
                                    onChange={this.handleTabChange}
                                    style={webStyles.sub16fontStyle}
                                >
                                    <Tab label={`Public Relation Materials (${this.state.freelancerCompleteData?.data?.attributes.profile_data.achievements.public_relation_meterials?.length})`} />
                                    <Tab label={`Publications (${this.state.freelancerCompleteData?.data?.attributes.profile_data.achievements.publications?.length})`}/>
                                    <Tab label={`Seminars (${this.state.freelancerCompleteData?.data?.attributes.profile_data.achievements.engagements_and_seminars?.length})`} />
                                </CustomTabs>

                                {this.state.selectedTab === 0 && this.renderArticles()}
                                {this.state.selectedTab === 1 && this.renderPublications()}
                                {this.state.selectedTab === 2 && this.renderSeminars()}
                                
                                {articles.length > 2 && (
                                    <Button style={webStyles.seeMoreBtn} onClick={this.toggleShowAllArticles}>
                                        {showAllArticles ? "See Less" : "See More"}
                                    </Button>
                                )}
                            </Box>


                        </Grid>
                    </Grid>
                </ParentGrid>
                <FooterWeb />
            </>
        );
    }
}

const InputField = styled(FormControl)({
  "@media(max-width:500px)": {
    "&  .MuiOutlinedInput-input" :{
        padding: "11.5px 12px",
      }
    },
});

const SelectMenu_style = styled(MenuItem)({
  "&:hover": {
    backgroundColor: "364BA0",
    color:"white",
  },
});

const webStyles = {
  borderRadius:{
    borderRadius:"12px"
  },
    leftSectionStyle: {
        background: "#F7F9FA",
        borderRadius: "16px",
        padding: "23px 24px",
    },
    mainHeadingBold: {
        fontSize: "24px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    headingBold: {
        fontSize: "20px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    headingRegular: {
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 400,
    },
    headingDisplay: {
        display: "flex",
        justifyContent: "space-between"
    },
    subBoldHeading: {
        fontSize: "18px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    subText: {
        fontSize: "18px",
        fontFamily: "Rubik",
    },
    subNormalHeading: {
        fontSize: "14px",
        fontFamily: "Rubik",
        fontWeight: 500,
        color: "#535353"
    },
    imageMargin: {
        marginRight: "18px",
        cursor:"pointer"
    },
    marginBottomStyle: {
        marginBottom: "38px",
    },
    btnStylesBlue: {
        fontFamily: "Rubik",
        fontSize: "16px",
        background: "#364BA0",
        color: "white",
        padding: "10px",
        borderRadius: "8px",
        cursor: "pointer"
    },
    btnStyleTans: {
        fontFamily: "Rubik",
        fontSize: "16px",
        background: "#F7F9FA",
        color: "#364BA0",
        padding: "10px",
        borderRadius: "8px",
        border: "1px solid #364BA0",
        cursor: "pointer"
    },
    chipStyle: {
        background: "#E4F5FF",
        borderRadius: "26px",
        display: "flex",
        padding: "8px",
        gap: "6px",
        justifyContent: "center",
    },
    skillStyle: {
        color: '#0A0A0A',
        padding: "2px 8px",
        fontSize: "16px"
    },
    sub14fontStyle: {
        fontSize: "14px",
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    sub16fontStyle: {
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 500,
        marginBottom: "16px"
    },
    lightBlueBox: {
        background: "#E4F5FF",
        padding: "24px",
        borderRadius: "12px"
    },
    starIcon: {
        color: '#FFB81F'
    },
    rating: {
        marginLeft: "2px",
        marginRight: "8px"
        , fontFamily: "Rubik",
        fontWeight: 500,
        fontSize: "14px",
        color: "#575757"
    },
    seeMoreBtn: {
        border: "1px solid #364BA0",
        fontFamily: "Rubik",
        fontSize: "16px",
        color: "#364BA0",
        width: "100%",
        marginTop: "24px",
        textTransform: "inherit" as "inherit",
    },
    projectBoxes: {
        background: "#EDF9FF",
        padding: "24px",
        borderRadius: "12px"
    },
    educationDateStyle: {
        fontSize: "12px",
        fontFamily: "Inter",
        fontWeight: 550,
        marginTop: "8px",
        marginBottom: "8px",
        fontStyle: "italic",
    },
    root: {
        textAlign: 'center' as 'center',
        marginBottom:"72px"
      },
      profileImageContainer: {
        position: 'relative' as 'relative' ,
        width: '150px',
        height: '150px',
        borderRadius: '50%',
        margin: '20px auto',
        border: '2px dashed #ccc',
        display: 'flex',
        alignItems: 'center' as 'center',
        justifyContent: 'center' as 'center',
      },
      addPhotoIcon: {
        position: 'absolute' as 'absolute',
        bottom: '10px',
        right: '10px',
        backgroundColor: '#fff',
        borderRadius: '50%',
      },
      bold16Text: {
       fontSize:"16px",
       fontFamily:"Rubik",
       fontWeight:500
      },
      saveButton: {
        backgroundColor: '#6c63ff',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#5b52e6',
        },
      },
      flexStyles:{
        display:"flex",
        alignItems: 'center',
        justifyContent:'space-between' as'space-between',
        borderBottom: "1px solid #979797",
        paddingBottom:"16px",
        marginBottom:"72px"
      },
      subHeaderStyle: {
        fontFamily: "Rubik",
        fontSize: "20px",
        fontWeight: 600,
        color: "#0A0A0A"
      },
      feeContainer: {
        marginTop: '30px',
        backgroundColor: '#FFFFFF',
        padding: '20px',
        borderRadius: '8px',
      },
      feeItem: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '32px',
      },
    
      feeInput: {
        width: '250px',
      },
      noteText: {
        backgroundColor: '#FFEB3B',
        padding: '8px',
        borderRadius: '4px',
        marginTop: '20px',
        fontSize: '14px',
        color: '#666666',
      },
      flagStyle:{
        marginTop: "24px",
        background: "#FEF3C7",
        color: "#D97706",
        padding: "10px",
        width: "443px",
        borderLeft: "4px solid #D97706",
        borderRadius: "4px",
        fontSize: "12px",
        fontFamily: "Rubik",
        fontWeight: 500,
      },
      iconStyle: {
        color: '#0A0A0A',
        fontSize: '20px',
      },
      deleteBtn:{
        fontSize: "16px",
        fontFamily: "Rubik",
        fontWeight: 500,
        color:"#DF0004",
        textDecoration:"underline"
      },
      editContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0px 4px 0px 15px'
    },
    imageContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        margin: '72px auto 24px',
        width: '230px',
        height: '230px',
        border: '1px dotted rgb(132, 126, 126)',
        borderRadius: '50%',
        marginTop: '10px'
    },
    Hr: {
      height: '2px',
      background: '#DADADA',
      border: 'none',
      margin: '0'
  },
  imageCircle: {
    display: 'flex',
    justifyContent: 'center',
  },
  addIcon: {
    color: 'white',
    width:'23px',
    height: '25px'
},
profileBox: {
    background: '#F2F2F2',
    borderRadius: '20px',
    display: 'flex',
    justifyContent: 'space-between',
    padding: '15px 25px'
},
btnBox:{
  display: 'flex',
  justifyContent: 'end',
  gap: '13px',
  padding: '48px 13px 12px'
},
Avatar_upload: {
  width: "150px", 
  height: "150px",
  borderRadius: '50%',
  "@media(max-width:500px)": {
   width: "100px", 
   height: "100px",
},
},
removeText:{
  color: '#364BA0',  
  fontFamily:"Rubik",
  fontSize:"18px",
  fontWeight:500,
  textTransform: 'initial' as 'initial',
  marginBottom:"12px"
},
linkStyle: {
  textDecoration: "none" as "none",
  color: "#1a73e8", 
  fontWeight: 500,
  cursor: "pointer",
},
      textField: {
        borderRadius:"12px",
        width: "100%",
        "& .MuiFormLabel-root.Mui-focused": {
          color: "#6F6E6E !important",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "12px !important", 
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px !important", 
            borderColor: "#6F6E6E !important",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderRadius: "12px !important", 
          },
        },
      },
}