import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
// Customizable Area Start
import storage from 'framework/src/StorageProvider';

// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  onStepChange: (stepChange: number) => void
  getLoction: (value: any) => void;
  getCities: (value: any) => void;


  // Customizable Area End
}
interface S {
  // Customizable Area Start
  countryList: {
    id: number,
    name: string,
    code: string,
  }[],
  selectedCountryList: {
    id: number,
    name: string,
    code: string,
  }[] | undefined
  country: string[];
  state: number[]
  citiesArray: string[]
  stateList: {
    id: number,
    name: string
  }[]
  isHovered: boolean
  countryId: any
  dropdownOpen: boolean
  dropdownOpenMenu: boolean
  countryError: string
  stateError: string
  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class LocationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  countryListID: string = "";
  stateListID: string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.state = {
      countryList: [],
      selectedCountryList: [],
      country: [],
      state: [],
      citiesArray: [],
      stateList: [],
      isHovered: false,
      countryId: "",
      dropdownOpen: false,
      dropdownOpenMenu: false,
      countryError: '',
      stateError: ""
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getCountriesList()

    // Customizable Area End
  }
  // Customizable Area Start
  handleCountryChange = (event: { target: { value: any } }) => {
    const selectedCountryName = event.target.value as string[];
    const isAllSelected = selectedCountryName.includes("All");
  
    let selectedCountryList = isAllSelected ? this.state.countryList : 
      this.state.countryList.filter((country) => selectedCountryName.includes(country.name));
  
    let selectedCountryIDs: "all" | number[] = isAllSelected 
      ? "all" 
      : selectedCountryList.map((country) => country.id);
  
    this.setState(
      {
        country: isAllSelected ? ["All"] : selectedCountryName,
        selectedCountryList: selectedCountryList,
        dropdownOpenMenu: false,
        countryError: "",
      },
      () => {
        if (selectedCountryIDs === "all" || selectedCountryIDs.length > 0) {
          this.getStateList(selectedCountryIDs);
          this.props.getLoction(selectedCountryIDs);
        }
      }
    );
  }; 
  
  getStateList = async (countryIds: number[] | "all") => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: await storage.get("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.stateListID = requestMessage.messageId;

    // If stateValue is an array, join the values with commas to pass them as query params
    const countryIdsParam = countryIds === "all" ? "all" : countryIds.join(",");

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/nations/get_states?ids=${countryIdsParam}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };


  handleHover = () => {
    this.setState({ isHovered: true });
  };

  handleLeave = () => {
    this.setState({ isHovered: false });
  };




  handleStateKeyPress = (event: React.KeyboardEvent) => {
    const { state, citiesArray, stateList } = this.state;
    if (event.key === 'Enter' && state.length > 0) {
      const newCities = state.map((id: number) => stateList.find((s) => s.id === id)?.name).filter(Boolean) as string[];
      this.setState({
        citiesArray: [...citiesArray, ...newCities],
        state: [],
      });
    }
  };

  handleDeleteState = (cityToDelete: string) => {
    this.setState((prevState) => ({
      citiesArray: prevState.citiesArray.filter((city) => city !== cityToDelete),
      state: prevState.state.filter(
        (id: number) => prevState.stateList.find((state) => state.id === id)?.name !== cityToDelete
      ),
    }));
  };
  handleDropdownOpenMenu = () => {
    this.setState({ dropdownOpenMenu: true });
  };

  handleDropdownCloseMenu = () => {
    this.setState({ dropdownOpenMenu: false });
  };

  handleDeleteCountry = (countryToDelete: string) => {
    this.setState((prevState) => {
      const updatedCountryList = prevState.country.filter((country) => country !== countryToDelete);
      const updatedSelectedCountryList = prevState.selectedCountryList?.filter(
        (country) => country.name !== countryToDelete
      ) || [];

      const updatedCountryIDs = updatedSelectedCountryList.map((country) => country.id);

      if (updatedCountryIDs.length > 0) {
        this.getStateList(updatedCountryIDs);
        this.props.getLoction(updatedCountryIDs);
      }

      return {
        country: updatedCountryList,
        selectedCountryList: updatedSelectedCountryList,
      };
    });
  };

  handleStateBlur = () => {
    const { state, citiesArray, stateList } = this.state;
    if (state.length > 0) {
      const newCities = state.map((id: number) => stateList.find((s) => s.id === id)?.name).filter(Boolean) as string[];
      this.setState({
        citiesArray: [...citiesArray, ...newCities],
        state: [],
      });
    }
  };

  handleBack = () => {
    this.props.onStepChange(-1)
  };
  handleContinue = () => {
    const { country, state } = this.state;
    let hasError = false;

    this.setState({ countryError: '', stateError: '' });

    if (!country) {
      this.setState({ countryError: 'Country is required.' });
      hasError = true;
    }

    if (Array.isArray(state) && state.length === 0) {
      this.setState({ stateError: 'At least one state is required.' });
      hasError = true;
    }

    if (!hasError) {
      this.props.onStepChange(+1);
    }
  };

  handleStateChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedStates = (event.target.value as string[]).map(Number);
    this.props.getCities(selectedStates)
    this.setState({
      stateError: "",
      state: selectedStates,
      citiesArray: selectedStates
        .map((id) => this.state.stateList.find((state) => state.id === id)?.name)
        .filter((name): name is string => name !== undefined),
      dropdownOpen: false,
    });


  };

  getCountriesList = async () => {
    const header = {
      "Content-Type": webConfigJSON.dashboarContentType,
      token: await storage.get("authToken")
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.countryListID = requestMessage.messageId

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/nations`
    );


    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  handleDropdownOpen = () => {
    this.setState({ dropdownOpen: true });
  };

  handleDropdownClose = () => {
    this.setState({ dropdownOpen: false });
  };
  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.countryListID === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson) {
          const countries = responseJson.map((country: any) => ({
            id: country.id,
            name: country.name,
            code: country.code,
          }));
          this.setState({ countryList: countries });

        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
      if (this.stateListID === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson) {
          const states = responseJson.map((state: any) => ({
            id: state.id,
            name: state.name,
          }));
          let state = this.state.state;
          this.setState({ stateList: states, state });
        } else {
          const errorMessage = _message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
        }
      }
    }
    // Customizable Area End
  }
}