import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { setStorageData } from "../../../framework/src/Utilities";
import { getStorageData } from "framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
export const baseURLconfig = require("../../../framework/src/config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  email:string
  isDialogOpen: boolean,
  loading:boolean;
  verfication: boolean;
  successPage: boolean;
  VerificationError: boolean
  otpError:string;
  otpValue: any;
  seconds:number;
  disabledResendOtp: boolean,
  timerExpired: boolean,
  authToken: any;
  clientEmail:string
  timerActive: boolean
  isTimerExpired:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class OTPVerificationController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiOtpVerifactionCallId: any
  myInterval: any;
  apiEmailOtpCallId:any;
  timer: NodeJS.Timeout | undefined;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceDataMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.SessionResponseData)
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      email:"",
      isDialogOpen: false,
      loading:false,
      verfication: false,
      successPage: false,
      VerificationError: false,
      otpError:"",
      otpValue: "",
      seconds:60,
      disabledResendOtp: true,
      timerExpired: true,
      authToken: "",
      clientEmail:"",
      timerActive: true, 
      isTimerExpired:false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    super.componentDidMount();
    const clientEmail= await getStorageData("clientEmail")
    this.setState({ clientEmail: clientEmail});
    this.startTimer();

  }
  async componentWillUnmount() {
    if (this.timer) {
      clearInterval(this.timer);
    }
  }

  async receive(from: string, message: Message) {
    
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      this.setState({ loading: false });
     
      if (apiRequestCallId === this.apiOtpVerifactionCallId) {
        this.handleOtpVerifyApi(responseJson);
      }
      if (apiRequestCallId === this.apiEmailOtpCallId) {
        this.handleEmailOtpApi(responseJson);
        const newToken = responseJson.meta.token;
        await setStorageData("clientToken",newToken)
      }
      
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleEmailOtpApi = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ otpError: responseJson.errors[0].email, loading: false });
    } 
  }
  startTimer = () => {
    if (this.timer) {
        clearInterval(this.timer);
    }

    const initialSeconds = 60;
    this.setState({ seconds: initialSeconds, disabledResendOtp: true,isTimerExpired: false });

    this.timer = setInterval(() => {
        const { seconds } = this.state;
        if (seconds > 0) {
            this.setState({ seconds: seconds - 1 });
        } else {
            clearInterval(this.timer);
            this.setState({ disabledResendOtp: false,isTimerExpired: true }); 
        }
    }, 1000);
}

  handleResendOtp = async() => {
    const clientEmail= await getStorageData("clientEmail")
  
    if (!clientEmail) {
      this.setState({ otpError: "* Email is required to resend OTP" });
      return;
    }
  
    this.setState({
      otpValue: "",
      otpError: "",
      VerificationError: false,
      seconds: 60,
      disabledResendOtp: true,
    }, () => {
      this.startTimer();
    });
  
    this.emailResendOtp(clientEmail);
  }
  
  
  displayTime = () => {
    const { seconds } = this.state;
    return seconds > 0 ? `0:${seconds < 10 ? '0' : ''}${seconds}` : 'Time Out. Please request new code';
  }

  handleOtpValue = (otpPin: any) => {
    if (!otpPin) {
      this.setState({ 
        VerificationError: true, 
        otpError: "Please enter your OTP" 
      });
    } else if (otpPin.length === 6) {
      this.setState({ 
        otpValue: otpPin, 
        VerificationError: false, 
        otpError: "" 
      });
    } else {
      this.setState({ 
        VerificationError: true, 
        otpError: "* OTP must be 6 digits" 
      });
    }
  };  

  
  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
    this.props.navigation.navigate("EmailAccountLoginBlock")
  };
    handleHome = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }
 
  handleVerifybox =async () => {
    if (this.state.isTimerExpired) {
      this.setState({ 
          VerificationError: true, 
          otpError: "Time Out. Please request new code" 
      });
      return;
  }

  if (this.state.otpValue === "") {
      this.setState({ 
          VerificationError: true, 
          otpError: "Please enter your OTP" 
      });
  } else {
      this.otpVerification();
  }  };
  

  otpVerification = async() => {
    const { otpValue } = this.state;
    const token = await getStorageData("clientToken")
   
    if (!otpValue || otpValue.length !== 6) {
      this.setState({ VerificationError: true, otpError: "* Please enter the complete 6-digit OTP code" });
      return;
    }
    const header = {
      "Content-Type": "application/json"
    };
    const OtpData = {
      "data": {
        "token": token,    
        "otp_code": otpValue   
      }
    };
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiOtpVerifactionCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.otpVerifyEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(OtpData)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };
  

  handleOtpVerifyApi = (responseJson: any) => {
    if (responseJson.errors) {
      this.setState({ otpError: responseJson.errors[0].otp });
    } else {
      this.setState({ isDialogOpen: true });
    }
  }
  
  
  handleLogoClick = ()=>{
    this.props.navigation.navigate("LandingPage");
  }
  handleSignIn = () => {
    setStorageData('role', 'user')
    this.props.navigation.navigate("EmailAccountLoginBlock")
  }

  emailResendOtp = (email: string) => {  
    this.setState({ loading: true });
  
    const header = {
      "Content-Type": "application/json"
    };
  
    let emailData1 = {
      "data": {
        "attributes": {
          "email": email  
        }
      }
    };
  
    const request_Message = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiEmailOtpCallId = request_Message.messageId;
    request_Message.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      'bx_block_forgot_password/otps/email_otp'
    );
    request_Message.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    request_Message.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(emailData1)
    );
    request_Message.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.post
    );
  
    runEngine.sendMessage(request_Message.id, request_Message);
    return true;
  }
  
  // Customizable Area End
}
