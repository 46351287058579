import React from "react";

// Customizable Area Start
import ClientBillingInformationController ,{Props} from './ClientBillingInformationController.web';
import { Box, Button, Dialog, DialogActions, DialogContent, FormControl, FormControlLabel, Grid, Radio, RadioGroup, TextField, Typography, styled } from "@material-ui/core";
import { cancel, connectedBtn, paypal,deleteBtn ,PaymentCard} from "./assets";
import { image_Success } from "../../email-account-registration/src/assets";
import PaymentSuccessful from "./PaymentSuccessful.web";

const CustomDialog = styled(Dialog)({
    '& .MuiPaper-rounded': {
        borderRadius: "18px",
    }
})
const PaymentCardDialog = styled(Dialog)({
    '&.radius-class > div > div': {
      borderRadius: "18px",
    }
  })

  const CustomTextField = styled(TextField)({
    '& .MuiOutlinedInput-root': {
      borderRadius:"10px",
      borderColor:"#E6E6E6",
    }
  })
  const HeaderGrid = styled(Grid)({
  
});


const CustomDialogBox = styled(Dialog)({
    '& .MuiPaper-rounded':{
      width:"480px",
      borderRadius: "18px",
    }
  })

// Customizable Area End

export default class ClientBillingInformation extends ClientBillingInformationController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    renderCardModal = () =>{
        return(<>
         <CustomDialogBox
          aria-describedby="alert-dialog-description"
          open={this.state.isCardDialogOpen}
          aria-labelledby="alert-dialog-title"
          onClose={this.handleCloseCardDialog}>
          <DialogContent 
          style={{
             textAlign: 'center',
             padding:"24px" 
             }}>{this.state.successMessage
                 && ( <><img src={image_Success}
                     alt="Success" style={{ width: "35%",
                    borderRadius: "50%",
                    marginTop:"20px" ,
                    marginBottom:"10px"
                    }} />
                   <Typography 
                   gutterBottom 
                   style={styles.congratsText}>
                       Congratulations!!
                        </Typography> </>)
                } 
                <Typography 
                style={styles.profileCreatedText}>{this.state.successMessage ? this.state.successMessage : this.state.errorMessage} </Typography>
              <Box style={{ display: "flex", flexDirection: "column" }}>
                  <Button variant="outlined"
                      onClick={this.handleCloseCardDialog}
                      style={styles.blueButton} >
                    OK</Button>
              </Box> </DialogContent>
      </CustomDialogBox>
          </>
        )
      }

    renderModal = () =>{
        return(<>
         <CustomDialogBox
          onClose={this.handleCloseDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.isDialogOpen}>
           
          <DialogContent
           style={{
             textAlign: 'center',padding:"24px" 
             }}>
                {this.state.successMessage && (
                    <>
                <img src={image_Success} alt="Success" style={{ width: "35%",
                    borderRadius: "50%",marginTop:"20px" ,marginBottom:"10px"}} />
                   <Typography gutterBottom style={styles.congratsText}>
                       Congratulations! 
                        </Typography>
                        </>
                        )
                }
              <Typography style={styles.profileCreatedText}>
             {this.state.successMessage ? 'PayPal payment method added successfully' : this.state.errorMessage}
             </Typography>
              <Box style={{ display: "flex",
               flexDirection: "column" }}>
                  <Button
                      variant="outlined"
                      style={styles.blueButton}
                      onClick={this.handleOkay}
                  >
                    OK
                  </Button>
              </Box>
          </DialogContent>
      </CustomDialogBox>
          </>
        )
      }

      renderPaypalModal = () => {
        return(
            <>
                 <PaymentCardDialog open={this.state.openPaypalModal} onClose={this.handleClosePaymentCardModal}
                 maxWidth="sm" fullWidth className="radius-class" >

                <DialogContent style={{padding:"24px"}}>
                <div style={{...styles.headingDisplay,alignItems:"center",borderBottom:"1px solid #979797"}}>
                <Typography style={{...styles.text24Bold,marginBottom:"16px"}}>Add Paypal</Typography>
                <img src={cancel} width={14} height={14} style={{marginBottom:"16px",cursor:"pointer"}} onClick={this.handleClosePaymentCardModal}/>
                </div>
                <div style={{marginTop:"32px"}}>
                    <div style={{marginBottom:"24px"}}>
                    <Typography style={styles.text16bold}>Email</Typography>
                    <FormControl style={styles.textField} variant="outlined">
                          <CustomTextField
                            placeholder="Enter your email address"
                            type="email"
                            name="email"
                            multiline
                            error={!!this.state.paypalEmailError}
                            onChange={this.handlePaypalEmail}
                            value={this.state.paypalEmail}
                            variant="outlined"
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "cardNumber",
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "'Inter', sans-serif",
                                color: "#000000",
                                borderRadius: '12px'
                              },
                            }}                           
                          />
                          {this.state.paypalEmailError && <Typography
                          style={{
                            marginTop:'6px',
                           color:'red'
                          }}
                          >
                            
                            {this.state.paypalEmailError}
                                                  </Typography>}
                    </FormControl>
                    </div>

                </div>
                </DialogContent>

                <DialogActions style={{display:"flex",gap:"12px",padding:"24px"}}>
                    <button onClick={this.handleClosePaymentCardModal} style={styles.blueBorderTransBtn}>
                    CANCEL
                    </button>
                    <button style={styles.blueFilledBtn} onClick={this.handleSavePaymentCardModal}>
                    SAVE
                    </button>
                </DialogActions>
                </PaymentCardDialog>
            </>
        )
      }

      renderDeleteModal = () =>{
        return(<>
         <CustomDialogBox
          onClose={this.handleCloseDeleteDialog}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          open={this.state.isDeleteModal}>
           
          <DialogContent
           style={{
             textAlign: 'center',padding:"24px" 
             }}>
              <Typography style={styles.profileCreatedText}>
             {this.state.deletePaypalDetails}
             </Typography>
              <Box style={{ display: "flex",
               flexDirection: "column" }}>
                  <Button
                      variant="outlined"
                      style={styles.blueButton}
                      onClick={this.handleCloseDeleteDialog}
                  >
                    OK
                  </Button>
              </Box>
          </DialogContent>
      </CustomDialogBox>
          </>
        )
      }
    // Customizable Area End
    render() {
        
        return (
            // Customizable Area Start
            // Required for all blocks
            <HeaderGrid container>
               {this.renderModal()}
               {this.renderCardModal()}
               {this.renderDeleteModal()}
                <CustomBillingBox className="inner-header-box">
                    <Box style={styles.passwordSettingBorderBox }>
                        <Typography style={styles.text24Bold}>Billing Information</Typography>

                        <Box style={{ marginTop: "32px" }}>
                            <Typography style={styles.text20bold}>Billing methods</Typography>
                            <Typography style={{ ...styles.text18bold, fontWeight: 400, marginTop: "12px" }}>
                                You haven’t set up any billing methods yet. Your billing method will be charged only when
                                your available balance from InventoHub earnings is not sufficient
                                to pay for your monthly membership and/or Connects.
                            </Typography>

                         
                                <button style={{ ...styles.blueBorderTransBtn, marginTop: "24px" }} onClick={this.handleBillingMethod}>
                                    Add a Billing Method
                                </button>
                                {this.state.paypalDataVisible && 
                                <>
                                <Box style={{ marginTop: "34px", display: "flex", justifyContent: "space-between" }}>
                                            <Box style={{ display: "flex", gap: 12, alignItems: "center" }}>
                                                <img src={paypal} width={32} height={32} />
                                                <Typography style={{ ...styles.text24Bold, color: "#2A2F40" }}>Paypal</Typography>
                                            </Box>
                                            <img src={connectedBtn} />
                                        </Box>
                       
                                <div style={{ marginTop: "24px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                                <Box>   
                                    <Typography style={{ ...styles.text20bold, fontWeight: 600, marginBottom: "12px" }}>Paypal ID</Typography>
                                    <Typography style={{ ...styles.text16bold, fontWeight: 400 }}>
                                    {this.state.paypalData?.attributes?.paypal_email}
                                    </Typography>
                                </Box>
                                <img src={deleteBtn} width={32} height={32}
                                data-test-id="PayPal" 
                                onClick={() => this.handleDeleteMethod("PayPal")}
                                style={{ cursor: "pointer" }} />
                            </div>
                            </>
                                }
                            {this.state.cardDetailsData &&
                            <>
                            <Box style={{ marginTop: "34px", display: "flex", justifyContent: "space-between" , flexWrap: 'wrap'}}>
                                            <Box style={{ display: "flex", gap: 12, alignItems: "center" }}>
                                                <img src={PaymentCard} width={48} height={32} />
                                                <Typography style={{ ...styles.text24Bold, color: "#2A2F40" }}>Payment Card</Typography>
                                            </Box>
                                            <img src={connectedBtn} />
                                        </Box>
                            <div style={{ marginTop: "24px", display: "flex", justifyContent: "space-between", alignItems: "center" ,flexWrap: 'wrap'}}>
                                <Box>   
                                    <Typography style={{ ...styles.text20bold, fontWeight: 600, marginBottom: "12px" }}>Payment Card</Typography>
                                    <Typography style={{ ...styles.text16bold, fontWeight: 400 , fontSize: '20px'}}>
                                    {this.formatCardToken(this.state.cardDetailsData?.card_token)}
                                        </Typography>
                                    <Typography style={{ ...styles.text16bold, fontWeight: 400 , fontSize: '16px', marginTop:'5px'}}>{this.state.cardDetailsData?.exp_month}/{this.state.cardDetailsData?.exp_year}</Typography>
                                </Box>
                                <img src={deleteBtn} width={32} height={32}
                                data-test-id="PayPal" 
                                onClick={this.handleDeletePaymentCard}
                                style={{ cursor: "pointer" }} />
                            </div>
                            </>}                        
                        </Box>
                    </Box>
                </CustomBillingBox>


                <CustomDialog
                    open={this.state.openBillingModal}
                    onClose={this.handleCloseBillingModal}
                    maxWidth="sm"
                    fullWidth
                    style={{ borderRadius: '16px' }}
                >
                    <Box>
                        <DialogContent style={{ padding: "24px" }}>
                            <div style={{ ...styles.headingDisplay, borderBottom: "1px solid #979797" }}>
                                <Typography style={{ ...styles.text24Bold, marginBottom: "16px" }}>
                                    Add a Billing Method
                                </Typography>
                                <img
                                    src={cancel}
                                    width={14}
                                    height={14}
                                    style={{ marginBottom: "16px", cursor: "pointer" }}
                                    onClick={this.handleCloseBillingModal}
                                />
                            </div>

                            <div style={{ marginTop: "32px" }}>

                            <RadioGroup
                                aria-labelledby="demo-radio-buttons-group-label"
                                name="radio-buttons-group"
                                onChange={this.handlePaymentChange}
                                data-test-id="handlePaymentChange"
                            >
                                <FormControlLabel
                                value="PayPal"
                                control={
                                    <Radio
                                    style={{ color: "#364BA0" }}
                                    checked={this.state.selectedValue === "PayPal"}
                                    />
                                }
                                label={
                                    <span style={styles.text20bold}>
                                    PayPal
                                    </span>
                                }
                                />

                                <div style={{ marginTop: "32px" }}>
                                <FormControlLabel
                                    value="PaymentCard"
                                    control={
                                    <Radio
                                        style={{ color: "#364BA0" }}
                                        checked={this.state.selectedValue === "PaymentCard"}
                                    />
                                    }
                                    label={
                                    <span style={{ ...styles.text20bold, fontWeight: 400 }}>
                                        Payment Card{' '}
                                        <span style={{ ...styles.text14Bold, fontWeight: 400 }}>
                                        (Visa, Mastercard, American Express, Discover, Diners)
                                        </span>
                                    </span>
                                    }
                                />
                                </div>
                            </RadioGroup>
                            </div>
                        </DialogContent>

                        <DialogActions style={{ display: "flex", gap: "12px", padding: "24px" }}>
                            <button
                                onClick={this.handleCloseBillingModal}
                                style={styles.blueBorderTransBtn}
                            >
                                BACK
                            </button>
                            <button
                                style={styles.blueFilledBtn}
                                onClick={this.handleNextButton}
                            >
                                NEXT
                            </button>
                        </DialogActions>
                    </Box>
                </CustomDialog>

                <PaymentCardDialog open={this.state.openPaymentCardModal} onClose={this.handleClosePaymentCardModal}
                 maxWidth="sm" fullWidth className="radius-class" >

                <DialogContent style={{padding:"24px"}}>
                <div style={{...styles.headingDisplay,alignItems:"center",borderBottom:"1px solid #979797"}}>
                <Typography style={{...styles.text24Bold,marginBottom:"16px"}}>Add Payment Card</Typography>
                <img src={cancel} width={14} height={14} style={{marginBottom:"16px",cursor:"pointer"}} onClick={this.handleClosePaymentCardModal}/>
                </div>
                <div style={{marginTop:"32px"}}>
                    <div style={{marginBottom:"24px"}}>
                    <Typography style={styles.text16bold}>Card Number</Typography>
                    <FormControl style={styles.textField} variant="outlined">
                          <CustomTextField
                            placeholder="0000 1111 2222 3333"
                            type="text"
                            name="card_token"
                            onChange={this.handleCardToken}
                            value={this.state.cardNumber}
                            variant="outlined"
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "cardNumber",
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "'Inter', sans-serif",
                                color: "#000000"
                              },
                            }}                           
                          />
                    </FormControl>
                    {this.state.errors.cardNumber && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.cardNumber}
                    </Typography>
                )}
                    </div>

                   <div style={{marginBottom:"24px"}}>
                    <Typography style={styles.text16bold}>Card Holder Name</Typography>
                    <FormControl style={styles.textField} variant="outlined">
                          <CustomTextField
                            placeholder="Ex: John Doe"
                            name="cardholderName"
                            type="text"
                            onChange={this.handleHolderName}
                            variant="outlined"
                            value={this.state.cardHolderName}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "cardholderName",
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "'Inter', sans-serif",
                                color: "#000000"
                              },
                            }}                           
                          />
                    </FormControl>
                    {this.state.errors.cardHolderName && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.cardHolderName}
                    </Typography>
                )}
                    </div>

                    <Grid container spacing={4}>
                    <Grid item  xs={12} sm={6} >
                    <Typography style={styles.text16bold}>Valid Date</Typography>
                    <FormControl style={styles.textField} variant="outlined">
                    <CustomTextField
    name="exp_month"
    type="text"
    variant="outlined"
    placeholder="MM/YYYY"
    onChange={this.handlevalidDate}
    value={this.state.validDate} // This shows the combined MM/YYYY format
    aria-describedby="outlined-weight-helper-text"
    inputProps={{
        "aria-label": "validDate",
        style: {
            fontSize: "16px",
            fontWeight: 500,
            fontFamily: "'Inter', sans-serif",
            color: "#000000",
        },
        maxLength: 7, // MM/YYYY format requires a maximum of 7 characters
    }}
/>

                    </FormControl>
                    {this.state.errors.validDate && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.validDate}
                    </Typography>
                )}
                    </Grid>

                    <Grid item  xs={12} sm={6} >    
                    <Typography style={styles.text16bold}>CVV</Typography>
                    <FormControl style={styles.textField} variant="outlined">
                          <CustomTextField
                            name="last4"
                            type="text"
                            variant="outlined"
                            placeholder="Ex: 123"
                            value={this.state.cvvNumber}
                            onChange={this.handleCVV}
                            aria-describedby="outlined-weight-helper-text"
                            inputProps={{
                              "aria-label": "cvvName",
                              style: {
                                fontSize: "16px",
                                fontWeight: 500,
                                fontFamily: "'Inter', sans-serif",
                                color: "#000000"
                              },
                              maxLength: 4
                            }}                           
                          />
                          {this.state.errors.cvvNumber && (
                    <Typography style={{ color: "red", fontSize: "12px" }}>
                    {this.state.errors.cvvNumber}
                    </Typography>
                )}
                    </FormControl>
                    </Grid>
                    </Grid>

                </div>
                </DialogContent>

                <DialogActions style={{display:"flex",gap:"12px",padding:"24px"}}>
                    <button onClick={this.handleClosePaymentCardModal} style={styles.blueBorderTransBtn}>
                    CANCEL
                    </button>
                    <button style={styles.blueFilledBtn} onClick={this.handleSavePaymentCardModal}>
                    SAVE
                    </button>
                </DialogActions>
                </PaymentCardDialog>
                {this.state.paymentSuccessPopup && 
                    <PaymentSuccessful
                    paymentSuccessPopup={this.state.paymentSuccessPopup}
                    handleCloseSuccessPopup={this.handleCloseSuccessPopup}
                     navigation={''} onStepChange={()=>{}}/>
                }
                {this.renderPaypalModal()}
            </HeaderGrid>

            // Customizable Area End
        );
    }
}

// Customizable Area Start
const CustomBillingBox = styled(Box)({
    marginTop: '10px',
    '@media(max-width: 1170px)': {
        width: '80%',
    },
    '@media(max-width: 950px)': {
        width: '50%'
    },
    '@media(max-width: 800px)': {  
        maxWidth: '100%',
        marginTop: "14px",
        width: '60%',
    },
    '@media(min-width: 501px) and (max-width: 600px)': {  
        maxWidth: '100%',
        marginTop: "14px",
        width: '60%',
    },
    '@media(max-width: 500px)': {
        width: '50%',
    },
    '@media(max-width: 400px)': {
        width: '40%',
    },
    '@media(max-width: 350px)': {
        width: '30%',
        maxWidth: '100%',
        marginTop: "14px",
    },
});


const styles = {
    passwordSettingBorderBox: {
        borderRadius: "16px",
        padding: "24px",
        border: "1px solid #CECECE",
    },
    headingDisplay: {
        justifyContent: "space-between",
        alignItems: "center",
        display: "flex",
    },
    accountText: {
        fontFamily: "Rubik",
        fontWeight: 700,
        color: "#2A2F40",
        fontSize: '24px',
    },
    text16bold: {
        fontFamily: "Rubik",
        fontSize: '16px',
        textTransform: "inherit" as "inherit",
        fontWeight: 500,
    },
    text18bold: {
        fontSize: '18px',
        fontWeight: 500,
        fontFamily: "Rubik",
    },
    text20bold: {
        fontSize: '20px',
        fontFamily: "Rubik",
        fontWeight: 500,
    },
    text24Bold: {
        fontSize: '24px',
        fontWeight: 500,
        fontFamily: "Rubik",
    },
    blueBorderTransBtn: {
        fontFamily: "Rubik",
        color: "#364BA0",
        display: "flex",
        border: "1px solid #364BA0",
        cursor: "pointer",
        justifyContent: "center",
        padding: "10px 22px",
        fontSize: "18px",
        borderRadius: "8px",
        fontWeight: 500,
        background: "#fff",
    },
    blueFilledBtn: {
        fontFamily: "Rubik",
        color: "#fff",
        borderRadius: "8px",
        fontWeight: 500,
        padding: "10px 22px",
        fontSize: "16px",
        border: "1px solid #364BA0",
        display: "flex",
        justifyContent: "center",
        background: "#364BA0",
        cursor: "pointer"
    },
    text14Bold: {
        fontSize: '14px',
        fontWeight: 500,
        fontFamily: "Rubik",
        marginTop: "24px"
    },
    congratsText: {
        marginTop: "24px",
        fontSize: "24px",
        color: "#059669",
        fontWeight: 600,
        fontFamily: "'Inter', sans-serif"
    },
    profileCreatedText:{
        marginBottom: '20px',
        marginTop: "18px",
        fontFamily: "Rubik",
     fontSize: "16px",
     fontWeight: 500,
     },
    interStyleText: {
        marginTop: "24px",
        fontSize: "16px",
        color: "#011342",
        fontFamily: "'Inter', sans-serif"
    },
       blueButton:{
        backgroundColor: '#364BA0',
        height:"40px",
        borderRadius:"10px",
        borderColor: '#364BA0',
        padding:"22px",
        color: '#fff',
        fontSize:"18px",
        fontFamily:"Rubik",
        marginBottom: '10px',
        fontWeight:500,
        width: '50%',
        margin: 'auto'
      },
    textField: {
        marginTop:"12px",
        width: "100%",
        "&.MuiFormLabel-root.Mui-focused ": {
          color: "#6F6E6E !important"
        },
        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
          borderColor: "#6F6E6E !important"
        }
      },
}
// Customizable Area End
